import React, { useState, useEffect, useRef } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from "axios";

const Gestione_file_mp3 = (props) => {
//    const [somma, calcola_somma] = useState('');
const [playing, setPlaying] = useState(false);
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});

const[currentTime1, setCurrentTime1] = useState('00:00');
const[currentTime2_s, setCurrentTime2_s] = useState('');

const[currentTime2, setCurrentTime2] = useState('00:00')
const [url, SET_url] = useState('');
   
const reactPlayerRef = useRef();


useEffect(() => {
    console.log(playing)
// idroslab    
    const bookmark_estratto = props.item.elenco_bookmark.filter(row => row._id === props.id_bookmark).pop();
//    console.log(bookmark_estratto.tempo)

    if (bookmark_estratto != undefined) {
        SET_bookmark_estratto_stato(bookmark_estratto)
    
        console.log(reactPlayerRef)
        reactPlayerRef.current.audio.current.currentTime = bookmark_estratto.tempo;
        
    }
//    reactPlayerRef.current.audio.current.play();
 
//    reactPlayerRef.current.(bookmark_estratto.tempo, "seconds");
   
     // This is cleanup of the effect
}, []);



const onPlay = () => {
    console.log(reactPlayerRef.current)

    const intervallo = bookmark_estratto_stato.tempo_finale - bookmark_estratto_stato.tempo 
 
    setTimeout(() => {
        console.log("Delayed for 1 second.");
        if (reactPlayerRef.current != null) reactPlayerRef.current.audio.current.pause();
    }, 1000 * intervallo);    


    
}


function carica_url_per_aws(e) {
//        console.log('yyyyyyyyyyyyyyyyyyXXXXXXXX preso ' + props.item)
//        console.log(props.item)
        
if (props.item.aws_key != undefined ) {
        if (!url.includes('walkmind')) {
//                console.log('XXXXXXXX preso ' + props.item._id)
//                console.log(url)

            axios.get('/transfer_s3_mp3/file_multimedia_ottieni_url/' + props.item.aws_key)
//            axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + 'ca32b2e50d177b9ce41c0cd148407f5')
            .then((res) => {
//                    console.log(res)
                SET_url(res.data)
            }).catch((error) => {
                console.log('error')
                console.log(error)
            });
        }
    }
}




    return (
        <div>
              <h3>  {bookmark_estratto_stato.nota} </h3>
              <AudioPlayer
                ref={reactPlayerRef} 
                autoPlay = {false}
                src = {url}
                autoPlayAfterSrcChange={false}
                onPlay={onPlay}
                onLoadStart = {carica_url_per_aws}
                // other props here
            />
            Durata Frame {bookmark_estratto_stato.tempo_finale-bookmark_estratto_stato.tempo}

        </div>
    )
}
export default Gestione_file_mp3;