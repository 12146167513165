import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from "axios";
import { Button, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import Nuovo_Alunno_Edit from "./Alunno_x0_Edit";
//import DatePicker from "react-datepicker";
import Crea_nuovo_alunno from "./Alunno_x0_1_crea_alunno";
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';


function make_codice_alfanumerico(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}

const Gestione_Alunno = (props) => {
    const [input, setInput] = useState({
        nome: '',
        cognome: '',
        email: '',
        email_genitore: '',
        parametro_accesso_pagina_studio: '', 
        pin_4_numeri_accesso_pagina_studio: "0000"
    });
    const [lista_alunni, SET_lista_alunni] = useState([]);
    const [variab_aggiorna, SET_variab_aggiorna] = useState(false);
    const [end_Date, set_End_Date] = useState(new Date());
    const id_utente = useSelector(state => state.variabile.value)
    const { user, isAuthenticated } = useAuth0();
    

    useEffect(() => {
        Carica_alunni_x_account()
    }, [props.classe_selezionata, variab_aggiorna,id_utente]);



    const Carica_alunni_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_alunni_account')
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}
        axios.get('/alunni/alunni_carica_per_classe/' + id_utente + '/' + props.classe_selezionata)
//        axios.get('/alunni/alunni_carica_tutti/' + id_utente)
            .then((res) => {
                console.log('ALUNNI appena fecciate da mongo')
                console.log(res.data)
                SET_lista_alunni(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }


    function handleChange(event) {
        const { name, value } = event.target;
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }





    function handleClick(event) {
        event.preventDefault();
        let var1 = make_codice_alfanumerico(10)
        let var2 = make_codice_alfanumerico(5)
        console.log(var1)
        console.log(var2)
        console.log(props.classe_selezionata)
        console.log(id_utente)
        

        const nuovo_alunno = {
            nome: input.nome,
            cognome: input.cognome,

            email: input.email,
            utente: id_utente,
            parametro_accesso_pagina_studio: var1, 
            pin_4_numeri_accesso_pagina_studio: 1234,
            classe_per_studiare: props.classe_selezionata
        }
        console.log(input);
        axios.post('/alunni/salva_nuovo', nuovo_alunno)
        .then((res) => {
            console.log(res.data)
            
            SET_variab_aggiorna(!variab_aggiorna)
    
        }).catch((error) => {
            console.log(error)
        });
    }



    function Inserisci_classe_di_appartenenza(item) {
        let btn = "XXXX non definito"

        if (item.classe_per_studiare === null) {return btn}
       
        btn = item.classe_per_studiare.nome 
        return btn
    }



    return (
        <div>
            <div className='container'>
                xxxx
                {props.classe_selezionata}
       
                <form>
                    <div className='form-group'>
                        <input onChange={handleChange} name="nome" placeholder="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                    </div>
                    <div className='form-group'>
                        <input onChange={handleChange} name="cognome" placeholder="cognome" value={input.cognome} autoComplete="off" className="form-control"></input>
                    </div>
                    <div className='form-group'>
                        <input onChange={handleChange} name="email" placeholder="email" value={input.email} autoComplete="off" className="form-control"></input>
                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button onClick={handleClick} disabled={props.classe_selezionata === '' ? true : false} className="btn btn-lg btn-info">Salva</button>
                    </div>
                </form>
            </div>



            <div className='container'>

                <ListGroup>
                    {lista_alunni.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <div>
                                <Row>
                                    <Nuovo_Alunno_Edit alunno={item} variab_aggiorna={variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna} />
                                </Row>
                                <Row>
                                    <Col>
                                        <Row><p>Cognome: <strong>{item.cognome}</strong></p></Row>
                                        <Row><p>Nome: <strong>{item.nome}</strong></p></Row>

                                        <Row><p>parametro_accesso_pagina_studio: <strong>{item.parametro_accesso_pagina_studio}</strong></p></Row>
                                        <Row><p>pin_4_numeri_accesso_pagina_studio: <strong>{item.pin_4_numeri_accesso_pagina_studio}</strong></p></Row>
                                        <Row><p>Inserisci_classe_di_appartenenza: <strong>{Inserisci_classe_di_appartenenza(item)}</strong></p></Row>

                                    </Col>

                                    <Col>
                                        <p>Email: <strong>{item.email}</strong></p>
                                    </Col>

                                    <Col>
                                        <Row>
                                            <p>Mail: <strong>{item.inviata_mail_per_richesta_dati}</strong></p>
                                        </Row>
                                        <Row>
                                            <p>Data_Mail: <strong>{item.inviata_mail_data} </strong></p>
                                        </Row>
                                        <Row>
                                            <p>_id: <strong>{item.utente} </strong></p>
                                        </Row>
                                        <Row>
                                            <p> <a href={'#/url_bacheca_alunno?id=' + item._id} target='_blank'> {'Link Bacheca'}</a>
</p>
                                        </Row>

                                    </Col>

                                </Row>
                                <hr class="rounded"></hr>

                            </div>
                        }
                    })
                    }
                </ListGroup>

            </div>

        </div >

    )
}

export default Gestione_Alunno;
