import React, { useState } from 'react';
import { Dropdown, Button, Modal, Form, Row, Col, Container } from 'react-bootstrap';
import { AiOutlineUpload } from 'react-icons/ai';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'






export default function Video_Crea_Nuovo(props) {
  const [show_spinner, SET_show_spinner] = useState(false);
  const id_utente = useSelector(state => state.variabile.value)
  const [file, setFile] = useState()
     
  const [input, SET_input] = useState({
    nome: '',
    link: '',
    number_max: 5  

  });

  const callback = function (result) {
    // console.log(result);
    if (result.success === true) {

    console.log('11111111111111111');
    SET_show_spinner (false)     
     
    } else console.log(result.message);
  };


  async function handleClick(event) {
    event.preventDefault();

    console.log(file)
  
    let result = null
    let aws_key
    let aws_filename = ''
    let stringa_per_chiamata_server = ''
       

    if (file != undefined) {
      SET_show_spinner (true) 
      const formData1 = new FormData();
      formData1.append("file_mp3", file)
      formData1.append("nome", file.name)

      const result1 = await axios.post('/transfer_s3_mp3/file_multimedia', formData1, { headers: { 'Content-Type': 'multipart/form-data' } })
      console.log('tornato nel front end')
      console.log(result1.data.key)
      aws_key = result1.data.key
      aws_filename = file.name
      SET_show_spinner (false)  

    }


    let tipo = ''
    let after_ = ''   
    console.log('entra');
    if (input.link.includes('youtube') && input.link.includes('=')) {
         
        after_ = input.link.substring(input.link.indexOf('=') + 1);
        console.log(after_);
        tipo = 'youtube'
    }
    if (input.link.includes('youtu.be')) {
        console.log('entra2');
         
        after_ = input.link.substring(input.link.indexOf('be/') + 3);
        console.log(after_);
        tipo = 'youtube'
    }
    if (input.link.includes('mp3')) {
        after_ = input.link;
        tipo = 'mp3'
    }
    if (input.link.includes('mp4')) {
        after_ = input.link;
        tipo = 'video'
    }

    if (aws_filename.includes('mp3')) {
      tipo = 'mp3'
    }

    if (input.link.includes('real_time') || 
        input.link.includes('real time') || 
        input.link.includes('realtime')  || 
        input.link.includes('Real time')  || 
        input.link.includes('Realtime')) 
    {
        after_ = input.link;
        tipo = 'real_time'
    }


    const nuovo_video = {
        nome: input.nome,
        link: after_,
        tipo: tipo,
        utente: id_utente,
        aws_key: aws_key,
        aws_filename: aws_filename,   
        link_scritto: input.link
    }
    
    if (input.nome == "" && file != undefined ) {nuovo_video.nome = file.name}

    console.log(nuovo_video);
    axios.post('/video/salva_nuovo', nuovo_video)
    .then((res) => {
        props.SET_variab_aggiorna(current => !current)

    }).catch((error) => {
        console.log(error)
    });
    props.SET_Video_selezionato(null)
}


  async function uploadFiles(e) {
    e.preventDefault()
   
    let input_file = e.target;
    console.log(e);
    setFile(e.target.files[0])
//    SET_show_spinner (false) 

  }

  const parsedJson = (data) => {
    JSON.parse(data, function (key, value) {
      if (value) return value.toString();
    });
  };


  const submit = async event => {
  }

// idroslab 
  const handleClick1 = async event => {
   
  }



  function handleChange(event) {
    const { name, value } = event.target;
    SET_input(prevInput => {
        return {
            ...prevInput,
            [name]: value
        }
    })
  } 


  return (
    <Container fluid="xxl">
        {/* idroslab  caricamento di un file*/}
        <Form onSubmit={submit}>
                {false && <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Seleziona un file multimediale da caricare</Form.Label>
                            <Form.Control type="file" onChange={(e) => uploadFiles(e)} accept="audio/*;video/*"/>
                  </Form.Group>
                }
                
                <h1>Inserisci nuovo video </h1>
                <form>
                    <div className='form-group'>
                        <input onChange={handleChange} name="nome" placeholder="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                    </div>

                    <div className='form-group'>
                        <input onChange={handleChange} name="link" placeholder="link" value={input.link} autoComplete="off" className="form-control"></input>
                    </div>
                

                    <div class="d-flex flex-row-reverse">
                        <button onClick={handleClick} className="btn btn-lg btn-info">Salva</button>
                    </div>
                </form>
                {show_spinner  && <Spinner animation="border" role="status">
                       <span className="visually-hidden">Loading...</span>
                </Spinner>}
                

    <div className="d-flex flex-row-reverse bd-highlight">
      {false && <Button variant="primary" type="submit" disabled={false} >
                          Carica
                        </Button>}   
      
      </div>
    </Form>

    </Container>
  );
}