import React, { useState,useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useScrollInfo } from '@faceless-ui/scroll-info';
import bbc_chirp from "../compon_mp3/bbc_chirp.mp3";

const synth = window.speechSynthesis;

const Link_memo_bell = () => {
    const [counter_sleep_telefonino, SET_counter_sleep_telefonino] = useState(0);
//    const [somma, calcola_somma] = useState('');
    const scrollInfo = useScrollInfo();
    const audio_bbc_chirp = new Audio(bbc_chirp);
    const [dateBegin, setDateBegin] = useState(new Date())


    useEffect(() => {
    
        const interval = setInterval(() => {
        SET_counter_sleep_telefonino((prevCounter) => prevCounter + 1);
            
        }, 1 * 60 * 1000);

        return () => clearInterval(interval);
    }, []); 



    useEffect(() => {
        if (counter_sleep_telefonino % 10 == 0) {
            Sintesivocale ('testo da leggere' + counter_sleep_telefonino) 
  
           
//             audio_bbc_chirp.play()
        }
        if (counter_sleep_telefonino > 10) {
           
//            SET_counter_sleep_telefonino(0);
        }
       
        if (counter_sleep_telefonino == 3) {
//            audio_crash.play()
        }
    }, [counter_sleep_telefonino]); 


    useEffect(() => {
        //        console.log(scrollInfo.yPercentage)
        let adesso = new Date()

        let seconds = (adesso.getTime() - dateBegin.getTime()) / 1000;
        if (counter_sleep_telefonino > 6) {
            SET_counter_sleep_telefonino(0)
            
        }
        if (counter_sleep_telefonino > 0) {SET_counter_sleep_telefonino(0)}
//        console.log(seconds)
//        console.log('XXXXXXXXXXXXXXXXX  cambiaedrfg')
        if (seconds > 2 && scrollInfo.yPercentage>95) {
            setDateBegin(new Date())
        
        }

    }, [scrollInfo]);



    const Sintesivocale = (testo_da_leggere) => {
        var utterance = new SpeechSynthesisUtterance();
        var voices = window.speechSynthesis.getVoices();
        utterance.voice = voices[9]; // Note: some voices don't support altering params
        utterance.voiceURI = 'native';
        utterance.volume = 1; // 0 to 1
        utterance.rate = 1; // 0.1 to 10
        utterance.pitch = 0; //0 to 2
        utterance.text = testo_da_leggere
        utterance.lang = 'it-IT';

        window.speechSynthesis.speak(utterance);
    }

    const function1 = (event) => {
        alert("Touch Start")
    }

    return (
    <div onTouchStart={function1}>ciao 
        {counter_sleep_telefonino} remainder  {counter_sleep_telefonino % 2} 
    </div>
    )
}
export default Link_memo_bell;