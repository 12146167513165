import React, { useState, useEffect } from 'react';
import { Button, DropdownButton, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


export function regola_la_difficolta_della_domanda (props,e) {
    console.log("sssss")
    var date = new Date();
    var timestamp = Math.round(date.getTime() / 1000 / 60 | 0);
    //        var lista_file = props.lista_file_mp3
    //    console.log(e);
    let indice_end = props.item.indice_end
    indice_end += parseInt(e)
    if (indice_end < 1) {
        indice_end = 1
    }
    //        lista_file[props.index2].microtime_last = timestamp
    //        props.SET_lista_file_mp3(lista_file)
    //        console.log(lista_file[props.index2].indice_end);
    //        props.SET_aggiorna_lista(2)
    //        props.trova_mp3_per_play()
    const nuovi_indici = {
        indice_end: indice_end,
        indice_aux: -indice_end - 1,
      
    }
    console.log(nuovi_indici)

    let newArr = [...props.lista_domande_render]; // copying the old datas array
    //        newArr[props.index].materia_id = item_id; // replace e.target.value with whatever you want to change it to
    newArr[props.index].indice_end = indice_end; // replace e.target.value with whatever you want to change it to
    newArr[props.index].indice_aux = -indice_end -1; // replace e.target.value with whatever you want to change it to
    newArr[props.index].indice_now = -indice_end; // replace e.target.value with whatever you want to change it to
    props.SET_lista_domande_render(newArr);

    axios.patch('/domande/aumenta_indici/' + props.item.utente)
        .then((res) => {
            console.log(res.data)

        }).catch((error) => {
            console.log(error)
        });

    axios.patch('/domande/modifica_indici/' + props.item._id, nuovi_indici)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
}


const Dropdown_jump = (props) => {
    const [value1, setValue1] = useState('');

    const indice = (e) => {
        console.log("aaaaaaa")
        regola_la_difficolta_della_domanda(props,e)
    }

    return (
        <div>
            <DropdownButton
                title="jump"
                onSelect={indice} >
                <Dropdown.Item eventKey="+50">+50</Dropdown.Item>
                <Dropdown.Item eventKey="+10">+10</Dropdown.Item>
                <Dropdown.Item eventKey="+5">+5</Dropdown.Item>
                <Dropdown.Item eventKey="0"> 0</Dropdown.Item>
                <Dropdown.Item eventKey="-5">-5</Dropdown.Item>

                <Dropdown.Item eventKey="-20">-20</Dropdown.Item>

                <Dropdown.Item eventKey="-50">-50</Dropdown.Item>

            </DropdownButton>
        </div>
    )
}
export default Dropdown_jump;