import React, { useState, useRef, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import ReactPlayer from 'react-player'
import axios from "axios";

const Gestione_video_mp4 = (props) => {
//    const [somma, calcola_somma] = useState('');
const[currentTime1, setCurrentTime1] = useState('00:00');
const[currentTime2_s, setCurrentTime2_s] = useState('');

const[currentTime2, setCurrentTime2] = useState('00:00')
const reactPlayerRef = useRef();
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});
const [url, SET_url] = useState('');
const[play_stato, SET_play_stato] = useState(false)


useEffect(() => {
// idroslab 
    if (props.item.aws_filename != undefined  && props.item.aws_filename.includes('.mp4'))  carica_url_per_aws()  
}, [props.item.aws_filename]);



function carica_url_per_aws(e) {
    //        console.log('yyyyyyyyyyyyyyyyyyXXXXXXXX preso ' + props.item)
    //        console.log(props.item)
    const bookmark_estratto = props.item.elenco_bookmark.filter(row => row._id === props.id_bookmark).pop();
    SET_bookmark_estratto_stato(bookmark_estratto)

    if (props.item.aws_key != undefined) {
            if (!url.includes('walkmind')) {
//                console.log('XXXXXXXX preso ' + props.item._id)
//                console.log(url)

                axios.get('/transfer_s3_mp3/file_multimedia_ottieni_url/' + props.item.aws_key)
    //            axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + 'ca32b2e50d177b9ce41c0cd148407f5')
                .then((res) => {
//                    console.log(res)
                    SET_url(res.data)
                }).catch((error) => {
                    console.log('error')
                    console.log(error)
                });
            }
        }
    }


function onProgress(e) {
    setCurrentTime2_s(e.playedSeconds)
    
//      player.currentTime = tempo_salto
}

function onStart(e) {
//    setCurrentTime2_s(e.playedSeconds)

    console.log(bookmark_estratto_stato.tempo)


    reactPlayerRef.current.seekTo(bookmark_estratto_stato.tempo, "seconds");
    SET_play_stato(true)
    const intervallo = bookmark_estratto_stato.tempo_finale - bookmark_estratto_stato.tempo 
    setTimeout(() => {
        console.log("Delayed for 1 second.");
        SET_play_stato(false)
    }, 1000 * intervallo); 
//      player.currentTime = tempo_salto
}


return (
    <>
     <h3>  {bookmark_estratto_stato != undefined && bookmark_estratto_stato.nota} </h3>
     <p>⏱ {currentTime2}  ⏱ {currentTime2_s} </p>
      
       
    <div className="d-flex justify-content-center">
        <div className={'currentTime'}>
            
          </div>
        <ReactPlayer ref={reactPlayerRef} 
                        url={url} 
                        light={true}
                        controls={true} onProgress={onProgress}
                        onStart = {onStart}
                        playing={play_stato}
                        />
    </div>
    </>
)
}
export default Gestione_video_mp4;