import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ListGroup, Row, Col} from 'react-bootstrap';
import JSONPretty from 'react-json-pretty';


const Bacheca_alunno = (props) => {

    const search = useLocation().search;
    const attore = new URLSearchParams(search).get('attore');
    const id = new URLSearchParams(search).get('id');
    const indice = new URLSearchParams(search).get('indice');
    const valore = new URLSearchParams(search).get('valore');

    const [variab_aggiorna, SET_variab_aggiorna] = useState('');
    const [lista_materiale, SET_lista_materiale] = useState(['']);


    useEffect(() => {
        console.log(id)
        console.log(indice)
        console.log(valore)
      
        console.log('Carica_domande_x_account')
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}

        axios.get('/materiali/materiale_carica_tutti_alunno/' + id)
            .then((res) => {
                console.log('MATERIALI appena fecciate da mongo')
                console.log(res.data)
                SET_lista_materiale(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }, []);

    function Mostra_progress_bar(d1) {
        var d = new Date(d1);
        var datestring = d.getDate()  + "/" + (d.getMonth()+1) + "/" + d.getFullYear() + " ora " +
        d.getHours() + ":" + d.getMinutes();

            return <>
                {datestring}
            </>
    }


    return (
        <>
            <h1>BACHECA1</h1>
            <div className='container'>

            <ListGroup>
                    {lista_materiale.map(function (item, index2) {
                        {
                            //                        console.log(item)
    return <div>

        <Row>
            <Col>
                <Row><span>materiale: <strong>{item.nome}</strong></span></Row>
                <Row><span>messaggio <strong>
                    
                {item.alunno !== undefined &&        
                    <>                                        
                        {item.domanda.domanda}         
                    </>
                }                                              
                    
                </strong></span></Row>
                <Row>
                    <span>tipo materiale: <strong>{item.key_tipo}</strong></span>         
                </Row>
                <Row>
                    <span>Data: <strong>{Mostra_progress_bar(item.date)}</strong></span>         
                </Row>
                {(props.account_dati.utente_debug) &&
            
                    <JSONPretty data={item} />
                }
            </Col>
        </Row>
        <hr class="rounded"></hr>
    </div>
                        }
                    })
                    }
            </ListGroup>
            </div>
        </>
    );
}
export default Bacheca_alunno;