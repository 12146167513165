import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../REDUX_feauture/counterSlice'
import assegna_variabile from '../REDUX_feauture/assegna_variabile'

// TODO: 


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    variabile: assegna_variabile
  },
})