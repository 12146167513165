import React, { useState, useEffect, useRef} from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import YouTube from "react-youtube"; 
import { ListGroup, Button, Row, Col} from 'react-bootstrap';
import axios from "axios";
import JSONPretty from 'react-json-pretty';
import Lista_componenti_bookmark from "./Lista_componenti_bookmark.jsx";
import ringer_quite from "../compon_mp3/quite.mp3";

const { colorazione } = require('../funzioni_comuni_colorazione_video.jsx')

 
const Gestione_youtube = (props) => {
//    const [somma, calcola_somma] = useState('');
const [elapsedTime, setElapsedTime] = useState(0);
//const [player, setPlayer] = useState(null);
const [stato_del_player, SET_stato_del_player] = useState(-1);
const [currentTime2_s, SET_timer_attuale] = useState(0);
const [colore_background, SET_colore_background] = useState('primary');

const [time, setTime] = useState(0);
const [edit_testo, SET_edit_testo] = useState(false);
const reactPlayerRef = useRef(null);
const audio_quite = new Audio(ringer_quite);
   


useEffect(() => {
//    console.log('vvvvvvvvvvvvvvvvvvvvvvvv')
//    console.log(player)
//    console.log(stato_del_player)
    if (props.Video_id_selezionato != null && reactPlayerRef !== null && props.item.tipo == "youtube") {  

        if (props.item._id != props.Video_id_selezionato) {
            stato_del_player && reactPlayerRef.current != null &&  reactPlayerRef.current.pauseVideo()
        }
    }
}, [props.Video_id_selezionato]);


useEffect(() => {
//    console.log('XXXXXXXXXXXXtimer ')
    if (reactPlayerRef !== null) {  

        if (props.item._id == props.Video_id_selezionato && stato_del_player) {
        
            const interval = setInterval(() => {
//                console.log('YYYYYYYYYYYYYY  timer')

                SET_timer_attuale(Math.round(reactPlayerRef.current.getCurrentTime()));
            }, 5000);
            return () => clearInterval(interval);
        }    
    }
}, [reactPlayerRef]);

    useEffect(() => {
    //        console.log('YYYYYYYY3 ' + props.trigger_per_bookmark) 
       
        if (props.trigger_per_bookmark == 1 && 
            props.item._id == props.Video_id_selezionato && 
            !edit_testo && !props.modifica_dati_file_multimediale) {
            imposta_bookmark(1)
        } 
        if (props.trigger_per_bookmark == 3 && 
            props.item._id == props.Video_id_selezionato && 
            !edit_testo && !props.modifica_dati_file_multimediale) {
            imposta_bookmark(2)
        } 
        if (props.trigger_per_bookmark == 2 && 
            props.item._id == props.Video_id_selezionato && 
            !edit_testo && !props.modifica_dati_file_multimediale) {
            reactPlayerRef.current.seekTo(currentTime2_s - 25) 
            audio_quite.play()
        } 
        props.SET_trigger_per_bookmark(0) 
    }, [props.trigger_per_bookmark]);


    const opts = {
        height: "390",
        width: "640",
        playerVars: {
            start: 100,
            end: 1000,
        // https://developers.google.com/youtube/player_parameters
            autoplay: 0
        }
    };

  
    const onPlayerStateChange = (event) => {
    };


    function Play_su_inizio_bookmark(item_array) {
        reactPlayerRef.current.seekTo(item_array.tempo)
        props.SET_Video_selezionato(props.item._id)
        props.SET_Bookmark_id_selezionato(item_array._id)
    }


    const onReady = (e) => {
//        setPlayer(e.target);
        reactPlayerRef.current = e.target;
    };


    function imposta_bookmark(tipo_chiamata) {
        console.log('gggggggggg_11111111111111111111');
        props.SET_counter_sleep_telefonino(0)
        console.log(reactPlayerRef.current.getCurrentTime())
       
       
   
        const segnalibro = {
            nota: "",
            tempo: reactPlayerRef.current.getCurrentTime(),
            tempo_finale: reactPlayerRef.current.getCurrentTime() + 30
        }
        if (currentTime2_s > 20) {segnalibro.tempo = reactPlayerRef.current.getCurrentTime() - 20} 
        if (tipo_chiamata == 2) {segnalibro.solo_ascolto = true}
        console.log(segnalibro);

//        axios.post('http://localhost:3001/create', newNote)
//        variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
        axios.put('/video/aggiungi_bookmark/' + props.item._id, segnalibro)
            .then((res) => {
                console.log('Aggiunto un bookmark')
                console.log('bookmark _id assigned is: %s', res.data.elenco_bookmark[res.data.elenco_bookmark.length-1]._id);
                props.SET_Bookmark_id_selezionato(res.data.elenco_bookmark[res.data.elenco_bookmark.length-1]._id)

            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });
//        var aux = props.cambio_stato
//        props.SET_cambio_stato(!aux)
        props.SET_variab_aggiorna(current => !current)
    }



    const seleziona_questo_video = () => {
        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAA errore')
        console.log(props.item._id)
        props.SET_Video_selezionato(props.item._id)
    }



    const funzione_onStateChange = (e) => {
        console.log('FFFFFFFFFFFFFFFFFFAAA ')
        if (e.target.playerInfo != null) {
            console.log(e.target.playerInfo.playerState)
            SET_stato_del_player(e.target.playerInfo.playerState)
//            setPlayer(e.target);
        }
        if (e.data === window.YT.PlayerState.PLAYING) {
            const interval = setInterval(() => {
              if (reactPlayerRef.current && reactPlayerRef.current.getPlayerState() === window.YT.PlayerState.PLAYING) {
                setElapsedTime(reactPlayerRef.current.getCurrentTime());
              }
            }, 1000);
      
            // Clear interval when the video stops playing
            const clearTimeInterval = () => {
              clearInterval(interval);
            };
      
            reactPlayerRef.current.addEventListener('onStateChange', clearTimeInterval);
        }
    };

    return (
    <div>
        <div className='d-flex justify-content-center'> 
            <YouTube
                videoId={props.item.link}
                opts={opts}
                onReady={onReady}
                onPlay={seleziona_questo_video}
                onStateChange={funzione_onStateChange} 
            />
        </div>
        <div className="d-grid gap-2"> 
            {props.item._id == props.Video_id_selezionato && 
            <Button variant="primary" onClick={imposta_bookmark}>
                Nuovo bookmark
            </Button>   

            }  
              <div>Elapsed Time: {elapsedTime.toFixed(2)} seconds</div>
            {/*                             
            <Button variant="primary" onClick={handleShow1}>
                Player seek
            </Button>
            */}    
        </div>                              
        <ListGroup variant="flush">
            {reactPlayerRef != null && <> {' ⏱ '}  {currentTime2_s} </>} 
            
            {props.item.elenco_bookmark.map(function (item_array, index2) {
                //                                        var aux = ottieni_url(item2.indirizzo_S3)
                //                       console.log(item2)
                {
                    return <div className={colorazione(item_array, colore_background, elapsedTime)} >
  
  
                                <Lista_componenti_bookmark item_array={item_array} index_lista_bookmark = {index2}
                                                       Video_id_selezionato={props.Video_id_selezionato}
                                                       Bookmark_id_selezionato = {props.Bookmark_id_selezionato} 
                                                       video_id={props.item._id} 
                                                       currentTime2_s = {currentTime2_s}
                                                       SET_colore_background = {SET_colore_background}
                                                       colore_background = {colore_background}
                                                       trigger_per_bookmark = {props.trigger_per_bookmark} 
                                                       SET_trigger_per_bookmark = {props.SET_trigger_per_bookmark}
                                                       reactPlayerRef={reactPlayerRef} 
                                                       SET_variab_aggiorna={props.SET_variab_aggiorna} edit_testo={edit_testo} SET_edit_testo={SET_edit_testo} />
                        
                        
                        {/*<Button variant="outline-primary" onClick={() => Correggi_punto_inizio(item_array._id)}>correggi</Button>{' '}*/} 
                        <Button variant="outline-primary" onClick={() => Play_su_inizio_bookmark(item_array)}>Play</Button>{' '}

                        
                </div>
                }
            }).reverse()
            }
        </ListGroup>
   

    </div>
    )
}
export default Gestione_youtube;