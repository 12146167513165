import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Dropdown } from 'react-bootstrap';
import axios from 'axios'


const Menu_per_materie = (props) => {
//    const [somma, calcola_somma] = useState('');


    const handleSelect=(e)=>{
        console.log(e);
        if (e === "Deseleziona_tutto") {deseleziona_tutte(false)}    
        if (e === "Seleziona_tutto") {deseleziona_tutte(true)}    
        if (e === "Deseleziona") {alertClicked()}    

    }

    const alertClicked = () => {
        var lista_materie_aux = props.lista_materie
        lista_materie_aux[props.index_passato].accesa = !lista_materie_aux[props.index_passato].accesa
        props.SET_lista_materie(lista_materie_aux)
        console.log(lista_materie_aux)
        const materia = {
            accesa: lista_materie_aux[props.index_passato].accesa
        }
        axios.patch('/materie/modifica_attivo/' + props.item._id, materia)
            .then((res) => {
                console.log(res.data)
                props.SET_cambio_stato(!props.cambio_stato)
            }).catch((error) => {
                console.log(error)
            });
    }

    const deseleziona_tutte = (acceso_passato) => {
        var lista_materie_aux = props.lista_materie
        lista_materie_aux.map((item, index) => {
            console.log(item.materia)
      
            item.accesa = acceso_passato
            if (props.index_passato === index) {
                item.accesa = !acceso_passato
            } 
            const materia = {
                accesa: item.accesa
            }
            if (item.materia != undefined) {
                if (!item.materia.includes('BUG')) { 
                    axios.patch('/materie/modifica_attivo/' + item._id, materia)
                    .then((res) => {
                        console.log(res.data)
        //                props.SET_cambio_stato(!props.cambio_stato)
                    }).catch((error) => {
                        console.log(error)
                    });
                }
            }
        })
        var aux = props.cambio_stato
        props.SET_cambio_stato(!aux)
//        props.SET_lista_materie(lista_materie_aux)
    }   

    return (
        <div>
			

            <Dropdown  onSelect={handleSelect}>
                <Dropdown.Toggle id="dropdown-basic">

                </Dropdown.Toggle>

                <Dropdown.Menu   >
                    <div className="verticale">
    
                    </div>
                    {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
                    <Dropdown.Item eventKey="Deseleziona_tutto">Deselect All</Dropdown.Item>
                    <Dropdown.Item eventKey="Seleziona_tutto">Select All</Dropdown.Item>

                                 
                    <Dropdown.Item eventKey="Deseleziona">{props.item.accesa ? 'Deseleziona' : 'Seleziona'}</Dropdown.Item>
                   

                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
export default Menu_per_materie;