import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Dropdown, DropdownButton, Label, Input, Button, Modal, Form } from 'react-bootstrap';
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import Lista_file_da_caricare from "./X1_lista_file_upload";

import axios from 'axios';

const Upload_files_mp3 = (props) => {
//    const [somma, calcola_somma] = useState('');
    const [show, setShow] = useState(false);
    const fileInput = React.useRef();
    const [description, setDescription] = useState("")

    const Chiusura_della_finestra_modale = () => {
        setShow(false);
        props.SET_cambio_stato(!props.cambio_stato) 

    }

    const apertura_finestra_modale = () => {
        setShow(true)
    }


    return (
        <>
       
       <Modal
            show={show}
            onHide={Chiusura_della_finestra_modale}
            backdrop="static"
            keyboard={false}
        >
        <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group controlId="ciao1">
                <Form.Label>Email address
    
                    </Form.Label>
                    <Lista_file_da_caricare  cambio_stato={props.cambio_stato}                                      
                                             SET_cambio_stato={props.SET_cambio_stato} />

                </Form.Group>
            </Form>

       

        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => Chiusura_della_finestra_modale()}>Chiudi</Button>
        </Modal.Footer>
        </Modal> 
        <div className="d-flex flex-row-reverse">

        <Button onClick={() => apertura_finestra_modale()}>
            Upload mp3  <BsFillFileEarmarkPlusFill />
        </Button>
      
        </div>
    </>
    )
}
export default Upload_files_mp3;