import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


const Link_impostazioni_personali = () => {
    const [somma, calcola_somma] = useState('');
    const { user } = useAuth0();

    useEffect(() => {
        console.log('USEEFFECT')
      
    }, []);
 


    return (
        <div>
            
ciao1

        </div>
    )
}
export default Link_impostazioni_personali;
