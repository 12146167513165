import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from "axios";
import { ListGroup, Row, Col} from 'react-bootstrap';
//import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import Nuovo_Materiale_Edit from "./023_Materiale_x0_Edit";
import JSONPretty from 'react-json-pretty';

import Gestione_pulsanti_assegnazione from "./021_Gestione_pulsanti_assegnazione.jsx";

import Gestione_assegnazione_classi from "./022_M_gestione_assegnazione_classi.jsx";
const { controlla_se_aggiungere_classi_ad_array } = require('../funzioni_comuni_array_classi')


                            

function make_codice_alfanumerico(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}



const Gestione_Materiale = (props) => {
    const [input, setInput] = useState({
        nome: ''
    });

    const [lista_materiale, SET_lista_materiale] = useState([]);
    const [variab_aggiorna, SET_variab_aggiorna] = useState(false);
    const [end_Date, set_End_Date] = useState(new Date());
    const id_utente = useSelector(state => state.variabile.value)
    const { user, isAuthenticated } = useAuth0();
    

    useEffect(() => {
        Carica_materiale_x_account()
        console.log('useeffect  oooooooooooooooooooooooo')
    }, [props.aggiornamento, props.programma_selezionato]);


    const Carica_materiale_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_domande_x_account')
        let end_date = 5
   
        axios.get('/materiali/materiale_carica_tutti/' + id_utente + '/' + props.programma_selezionato)
            .then((res) => {
                console.log('MATERIALI appena fecciate da mongo')
                console.log(res.data)
                SET_lista_materiale(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }


    function handleChange(event) {
        const { name, value } = event.target;
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    function handleClick(event) {
        event.preventDefault();
        const nuova_domanda = {
            domanda: input.nome,
            utente: id_utente,
            cestino: 'messaggi'
        }
        axios.post('/domande/salva',nuova_domanda)
        .then((res) => {
            console.log(res.data)
            const nuovo_materiale = {
                utente: id_utente,
                programma: props.programma_selezionato,
                domanda: res.data._id,
                key_password: make_codice_alfanumerico(10),
                key_tipo: 'messaggio',
                key_matrice: 'programma' 
            }
    
            console.log(input);
            axios.post('/materiali/salva_nuovo', nuovo_materiale)
                .then((res) => {
                    console.log(res.data)
                    let array_vuoto_classi =[] 
                    controlla_se_aggiungere_classi_ad_array(array_vuoto_classi, 
                                                            props.lista_classi_per_programma, 
                                                            res.data._id)
        
                }).catch((error) => {
                    console.log(error)
                });
        }).catch((error) => {
            console.log(error)
        });
        props.SET_aggiornamento(current => !current)
    }


    return (
        <div className='container'>
            {props.lista_classi_per_programma.map(function (item, index2) {
                {
                    //                        console.log(item)
                    return <>
                                <Col>
                            {item.nome}
                        </Col>
                        <hr class="rounded"></hr>
                    </>
                }
            })
            }


            <div className='container'>
                <h1>Inserisci nuovo Materiale </h1>
                <form>
                    <div className='form-group'>
                        <input onChange={handleChange} name="nome" placeholder="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                    </div>
                

                    <div class="d-flex flex-row-reverse">
                        <button onClick={handleClick} disabled={props.programma_selezionato === ''} className="btn btn-lg btn-info">Salva Materiale</button>
                    </div>
                </form>
            </div>

            <div className='container'>

                <ListGroup>
                    {lista_materiale.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <div>
                                <Row>
                                    <Nuovo_Materiale_Edit item={item} SET_aggiornamento={props.SET_aggiornamento}/>
                                    <Gestione_assegnazione_classi item={item}
                                                                  lista_classi_per_programma = {props.lista_classi_per_programma}  
                                                                  variab_aggiorna={variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna} /> 
                                </Row>
                     
                                <Row>
                                    <Col>
                                        materiale: <strong>
                                            {(item.domanda !== undefined && item.domanda !== null) &&        
                                            <>          
                                                <b></b> {item.domanda.domanda}         
                                            </> 
                                            }
                                            </strong>
                                    </Col>
                                    <Col>   
                                        <Gestione_pulsanti_assegnazione array_classi= {item.array_classi}
                                                                        array_classi_semplice= {item.array_classi_semplice} 
                                                                        materiale = {item}/> 
                                    </Col>
                                    {(props.account_dati.utente_debug) &&
                                  
                                        <JSONPretty data={item} />
                                    }
                                    
                                </Row>
                                <hr class="rounded"></hr>
                            </div>
                        }
                    })
                    }
                </ListGroup>
            </div>
        </div >
    )
}

export default Gestione_Materiale;
