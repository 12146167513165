import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { ListGroup, Button, Row, Col, Modal, Form } from 'react-bootstrap';


const Lista_file_da_caricare = (props) => {
    const [somma, calcola_somma] = useState('');
    const [lista_coppie, SET_lista_coppie] = useState([]);
    const [lista_file, SET_lista_file] = useState([]);

    const lista_results = [];
    const id_utente = useSelector(state => state.variabile.value)


    
    const handleFileInput = (e) => {
      let lista_file_aux = e.target.files;
      SET_lista_file(e.target.files)
      let lista_coppie_mp3 = []
      console.log('lista_file')    
      console.log(lista_file_aux)    

      Array.from(lista_file_aux).forEach((item1, index1) => {
        let text = item1.name;
     
        let indice = text.indexOf("q.");     
        let sottostringa = ''
        let indice_risposta = -1
        let indice_domanda = -1
        if (indice > -1) {
          indice_domanda = index1 
          sottostringa = text.substring(0, indice);
          Array.from(lista_file_aux).forEach((item2, index2) => {
              let text = item2.name;
              let indice2 = text.indexOf("r.");  
              let sottostringa2 = text.substring(0, indice2)
              if (indice2 > -1 && sottostringa === sottostringa2) {
                indice_risposta = index2
                lista_coppie_mp3.push({ nome_domanda: item1.name, domanda: index1, nome_risposta: item2.name, risposta: index2, stato_progress: 0, colore_bordo: 'solid red' })
              }
          });
        }
      });
      console.log(lista_coppie_mp3)
      SET_lista_coppie(lista_coppie_mp3)
    }



    async function handleUpload (index1,index2, indice_elenco) {
      const formData1 = new FormData();
          formData1.append("file_mp3", lista_file[index1])
          formData1.append("nome", lista_file[index1].name)

      const result1 = await axios.post('/transfer_s3_mp3/file_mp3', formData1, { headers: { 'Content-Type': 'multipart/form-data' } })
      console.log('tornato nel front end')
      console.log(result1)
//        set_key_aws (result.data.key)
      
      let aux = lista_coppie
      aux[indice_elenco].stato_progress = 1
      SET_lista_coppie([...aux]) 

      const formData2 = new FormData();
      formData2.append("file_mp3", lista_file[index2])
      formData2.append("nome", lista_file[index2].name)

      const result2 = await axios.post('/transfer_s3_mp3/file_mp3', formData2, { headers: { 'Content-Type': 'multipart/form-data' } })
      console.log('tornato nel front end')
      console.log(result2)

      Salva_nuova_domanda (result1, index1, result2, index2)
//        set_key_aws (result.data.key)
  };


  const Salva_nuova_domanda = (result1, index1, result2, index2) => {
    //    funzione_set_aggiornamento(true)

    const domanda = {
        domanda: lista_file[index1].name,
        risposta: lista_file[index2].name,
        materia: "...",
        utente: id_utente,
        indirizzo_S3_mp3_domanda: {key: result1.data.key , nome: lista_file[index1].name},
        indirizzo_S3_mp3_risposta: {key: result2.data.key , nome: lista_file[index2].name}
    }

    console.log(domanda)
    axios.post('/domande/salva', domanda)
    .then((res) => {
        console.log(res.data)

//                var lista_aux = [... props.lista_domande_render]
//                console.log(lista_aux)
//                lista_aux.unshift(domanda)
//                console.log(lista_aux)
//                props.SET_lista_domande_render(lista_aux)
//          props.SET_cambio_stato(!props.cambio_stato)

    }).catch((error) => {
        console.log(error)
    });
  }

  const Click_inizio_upload = (result1, index1, result2, index2) => {
  
    lista_coppie.map((item, index) => {
        handleUpload (item.domanda,item.risposta, index)
  
    })
  }

// ---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------
    return (
        <div>
            Upload file:
            <input type="file" multiple onChange={handleFileInput}/>
            {lista_coppie.map(function (item, index2) {
                {
                    return <div>
                        <Row>
                          <Col>
                            {item.nome_domanda}
                          </Col>
                          <Col>
                            {item.domanda}
                          </Col>

                          <Col>
                            {item.nome_risposta}
                          </Col>
                          <Col>
                            {item.risposta}
                          </Col>
                          <Col>
                            {item.stato_progress}
                          </Col>

                        </Row>
                        <hr class="rounded"></hr>
                    </div>
                }
            })
            }
            {console.log('ciao')}
            <Button variant="secondary" onClick={() => Click_inizio_upload()}> Upload  </Button>
        </div>
    )
}
export default Lista_file_da_caricare;