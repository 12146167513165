import React, { useState, useEffect, useRef } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Input, Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import axios from "axios";

const Bookmark_edit_durata = (props) => {
//    const [somma, calcola_somma] = useState('');
const [input, setInput] = useState(null);

useEffect(() => {
    setInput(props.item_array.tempo_finale - props.item_array.tempo)
}, [props.item_array]);

useEffect(() => {

}, [input]);


useEffect(() => {
   
    if (props.trigger_per_bookmark == 137) {
        salva_nuova_durata_bookmark (props.item_array.tempo_finale - 5)
        // va bene per i video
        if (props.reactPlayerRef.current != undefined &&  props.reactPlayerRef.current.hasOwnProperty('seekTo')) {    

            props.reactPlayerRef.current.seekTo(props.item_array.tempo_finale - 10, "seconds")
        }
        if (props.reactPlayerRef.current != undefined &&  props.reactPlayerRef.current.audio != undefined) {    

            props.reactPlayerRef.current.audio.current.currentTime = props.item_array.tempo_finale - 10
            props.reactPlayerRef.current.audio.current.play()
        }
    

    }
    if (props.trigger_per_bookmark == 139) {
        salva_nuova_durata_bookmark (props.item_array.tempo_finale + 5)
        // va bene per i video
        if (props.reactPlayerRef.current != undefined &&  props.reactPlayerRef.current.hasOwnProperty('seekTo')) {    
            props.reactPlayerRef.current.seekTo(props.item_array.tempo_finale, "seconds")
        }

        if (props.reactPlayerRef.current != undefined &&  props.reactPlayerRef.current.audio != undefined) {    
            props.reactPlayerRef.current.audio.current.currentTime = props.item_array.tempo_finale
            props.reactPlayerRef.current.audio.current.play()
        }
    }
    props.SET_trigger_per_bookmark(0)
}, [props.trigger_per_bookmark]);




function onclick () {
    let nuovo_stato_indice = 0
    //  audio
    if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.audio != undefined) {  
        nuovo_stato_indice = props.reactPlayerRef.current.audio.current.currentTime 
    }
    // video 
    if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.hasOwnProperty('getCurrentTime')) {    
        nuovo_stato_indice = props.reactPlayerRef.current.getCurrentTime()  
    }
    // youtube
    
 
    salva_nuova_durata_bookmark (nuovo_stato_indice)
   
}


function salva_nuova_durata_bookmark (nuovo_stato_indice) {
 
    axios.patch('/video/correggi_tempo_finale_bookmark/' + props.item_array._id + '/' + nuovo_stato_indice)
    .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });
    setInput ( nuovo_stato_indice - props.item_array.tempo)
    console.log('gggggggggggggggggggg')
    props.SET_variab_aggiorna(current => !current)

}


    return (
    <>
       <Button variant="outline-primary" onClick={() => onclick()}>fine</Button>
       {/* 
       <Button variant="outline-primary" onClick={() => onclick_durata(15)}>+15</Button>
           {input}
       <Button variant="outline-primary" disabled={input<=15} onClick={() => onclick_durata(-15)}>-15</Button>
       
       */}
          { Math.ceil(input)} s
                    
    </>
    )
}
export default Bookmark_edit_durata;