import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { assegna_account_google } from '../REDUX_feauture/assegna_variabile'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  
  const assegnazione_valore = () => {
    loginWithRedirect()

  }


  return (
    !isAuthenticated && (
      <Button onClick={() => assegnazione_valore()}>
        Log In
      </Button>
    )
  )
}

export default LoginButton
