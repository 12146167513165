import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
}

export const assegna_variabile = createSlice({
  name: 'variabile',
  initialState,
  reducers: {
    increment1: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement1: (state) => {
      state.value -= 1
    },
    assegna_account_google: (state, action) => {
      state.value = action.payload
    },
    assegna_debug_redux: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment1, decrement1, assegna_account_google, assegna_debug_redux } = assegna_variabile.actions

export default assegna_variabile.reducer