import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';


const Dropdown_materie = (props) => {

    const [value1, setValue1] = useState('');


    const handleSelect = (item_id) => {
        

        const nuova_materia_id = {
            materia_id: item_id
        }
        console.log(nuova_materia_id)
        
        let newArr = [...props.lista_domande_render]; // copying the old datas array
//        newArr[props.index].materia_id = item_id; // replace e.target.value with whatever you want to change it to
     
        var result = props.lista_materie.find(item => item._id === item_id);
        console.log(result)
//        console.log(result.materia)
        if (newArr[props.index].materia_id === undefined) {
            console.log("indefinito")
            const materia = {
                materia: result.materia,
                colore_sfondo: result.colore_sfondo
            }    
            newArr[props.index].materia_id = materia
        }

        newArr[props.index].materia_id.materia = result.materia; // replace e.target.value with whatever you want to change it to
        props.SET_lista_domande_render(newArr);


        axios.patch('/domande/modifica_materia_id/' + props.item._id, nuova_materia_id)
            .then((res) => {
                console.log(res.data)
//                props.SET_cambio_stato(!props.cambio_stato)
            }).catch((error) => {
                console.log(error)
            });
    }


    function displayStarRating() {
        var stringa
        const btn = { color: "red", margin: "5%", boxShadow: "5px 5px 3px rgba(46, 46, 46, 0.62)" }
        return btn;
    }


    function Inserisci_materia_attuale() {
        let btn = "..."
        if (props.item.materia_id === undefined) {return btn}
        if  (!(props.item.materia_id === undefined || props.item.materia_id == null)) 
            { btn = props.item.materia_id.materia} 
        return btn
    }

    return (
        <DropdownButton
            variant="primary"
            title={Inserisci_materia_attuale()}
            onSelect={handleSelect} >
            {props.lista_materie.map(function (item1, index2) {
                {
                    return <div>
                        <Dropdown.Item eventKey={item1._id}> {item1.materia} </Dropdown.Item>
                    </div>
                }
            })
            }
            <Dropdown.Divider />
            <Dropdown.Item eventKey="some link">some link</Dropdown.Item>
        </DropdownButton>
    )
}

export default Dropdown_materie;