import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import axios from "axios";
import { Form, ListGroup, Row, Col} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
const { Trova_tutti_gli_alunni_per_la_classe } = require('../funzioni_comuni_array_classi')

 
const Gestione_pulsanti_assegnazione = (props) => {
    //    const [somma, calcola_somma] = useState('');
    
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const id_utente = useSelector(state => state.variabile.value)
   
    const onSwitchAction = (classe_id, index2) => {
      setIsSwitchOn(!isSwitchOn);
      console.log(classe_id + ' ' + index2)
      Trova_tutti_gli_alunni_per_la_classe(id_utente, props.materiale, classe_id, 
                                           props.array_classi_semplice[index2].key_8_cifre)
        let booleano = true
        axios.patch('/materiali/spuntatura_invio_materiale_classe/' 
                    + props.materiale._id + '/' + booleano + '/' + index2)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
    };
    
                                        

        return (
            <div>
    
                {props.array_classi.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <>
                                <Row>
                                    <Col>
                                    {item.nome}
                                    </Col>
                                    <Col>
                                    <Form.Switch
                                        onChange={() => onSwitchAction(item._id, index2)}
                                        id="custom-switch"
                                        label="spalmato"
                                        disabled={props.array_classi_semplice[index2].spalmata}
                                        checked={props.array_classi_semplice[index2].spalmata}
                                    />
                                    </Col>
                                </Row>
                            </>
                        }
                    })
                }
    
            </div>
        )
    }
export default Gestione_pulsanti_assegnazione;