import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import axios from "axios";
import { Form, ListGroup, Row, Col} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'

 
const Gestione_creazione_domanda = (props) => {
     const [numero_domande_abbinate, SET_numero_domande_abbinate] = useState(0);
    
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const id_utente = useSelector(state => state.variabile.value)
   
    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
        setIsSwitchOn(props.item_array.bookmark_trasformato_domanda)
    }, [props.item_array.bookmark_trasformato_domanda])



    useEffect(() => {

    if (props.Video_id_selezionato == props.video_id )    
    axios.get('/domande/controlla_se_domanda_presente/' + props.item_array._id)
    .then((res) => {
//        console.log(' appena fecciate da mongo')
//        console.log(res.data)
        SET_numero_domande_abbinate(res.data.length)
        }).catch((error) => {
            console.log(error)
        });

    }, [props.item_array._id, props.Video_id_selezionato])



    const onSwitchAction = (classe_id, index2) => {
 
    axios.patch('/video/bookmark_trasformato_in_domanda/' + props.item_array._id + '/' + !isSwitchOn)
        .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });

    if (!isSwitchOn) {


        axios.get('/domande/controlla_se_domanda_presente/' + props.item_array._id)
        .then((res) => {
    //        console.log(' appena fecciate da mongo')
    //        console.log(res.data)
            if (res.data.length == 0) { 

                SET_numero_domande_abbinate(1)    
                const domanda = {
                    domanda: "...",
                    risposta: "...",
                    materia: "...",
                    utente: id_utente,
                    indice_aux: 15000,
                    video_id_indice: props.item_array._id,
                    video_id: props.video_id
                }
                axios.post('/domande/salva', domanda)
                .then((res) => {
                    console.log(res.data)
                }).catch((error) => {
                    console.log(error)
                });
            }
        }).catch((error) => {
                console.log(error)
        });
    }

    if (isSwitchOn) {
        SET_numero_domande_abbinate(0)  
        axios.delete('/domande/cancella_domanda_per_bookmark/' + props.item_array._id)
        .then((res) => {
            console.log(res.data)
    
        }).catch((error) => {
            console.log(error)
        });
    }

    setIsSwitchOn(current => !current)
    };



    return (
        <>
                    
            <Form.Switch
                class="btn btn-outline-danger"
                onChange={() => onSwitchAction(1, 2)}
                id="custom-switch"
                label=""
                disabled={false}
                checked={isSwitchOn}
            />
            {props.Video_id_selezionato == props.video_id && <h1>{numero_domande_abbinate}</h1>}
            
        </>
    )
    }
export default Gestione_creazione_domanda;