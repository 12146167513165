import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Button, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import axios from 'axios'


const Cambia_nome_materia = (props) => {
//    const [somma, calcola_somma] = useState('');
const [valore, set_funzione_valore] = useState(props.item.materia);

const handleChange1 = (event) => {
    console.log('xxxx:' + event.key);
    if (event.key === 'Enter') {
        Salva_materia()
        set_funzione_valore('')
    }
}


const handleChange = (event) => {
    set_funzione_valore(event.target.value)
//    console.log('yyyy:' + valore);
}


const Salva_materia = () => {
    console.log('do validate22' + valore);
    let  materia1 = {
        materia: valore

    }
    var lista_aux = props.lista_materie
    set_funzione_valore('')

    axios.patch('/materie/modifica_nome/' + props.item._id, materia1)
    .then((res) => {
        console.log(res.data)
//                props.SET_cambio_stato(!props.cambio_stato)
    }).catch((error) => {
        console.log(error)
    });

}

    return (
        <div>
            <form>
                <input type="text" placeholder="" value={valore} onKeyDown={handleChange1}  onChange={handleChange} />
                <Button variant="outline-primary" onClick={() => Salva_materia()}>Salva</Button>{' '}
            
            </form>

        </div>
    )
}
export default Cambia_nome_materia;