
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { AiOutlineQuestionCircle } from "react-icons/ai";



const Nuova_domanda = (props) => {

    const id_utente = useSelector(state => state.variabile.value)


    const Salva_nuova_domanda = () => {
        //    funzione_set_aggiornamento(true)

        const domanda = {
            domanda: "",
            risposta: "",
            materia: "...",
            utente: id_utente,
//            indirizzo_S3_immagine_domanda: null,
//            elenco_pdf: [],
//            elenco_link: [],
            indice_aux: props.max_indice_aux + 1
        }
        console.log(domanda)
        Salva_domanda_in_mongo_db(domanda)
    }


    const Salva_domanda_in_mongo_db = (domanda) => {
        //    funzione_set_aggiornamento(true)
        console.log(domanda)
        props.set_ordine_visualizzazione('sort_new')
        axios.post('/domande/salva', domanda)
            .then((res) => {
                console.log(res.data)

//                var lista_aux = [... props.lista_domande_render]
//                console.log(lista_aux)
//                lista_aux.unshift(domanda)
//                console.log(lista_aux)
//                props.SET_lista_domande_render(lista_aux)
                  props.SET_cambio_stato(!props.cambio_stato)

            }).catch((error) => {
                console.log(error)
            });
    }


    return (
        <div className="d-flex flex-row-reverse">
            <Button onClick={() => Salva_nuova_domanda()}>
                  <AiOutlineQuestionCircle />
          </Button>

        </div>
    )
}

export default Nuova_domanda;