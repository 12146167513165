const axios = require('axios')

function AWS_uploadFile_PDF(file) {
   console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa ssaedgfwerrfgh')
};
exports.AWS_uploadFile_PDF = AWS_uploadFile_PDF;


function make_codice_alfanumerico(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}


function Metti_la_classe_in_tutti_i_materiali (id_utente, programma_id, nuova_classe_id) {
    //    funzione_set_aggiornamento(true)
    console.log('Carica_domande_x_account')
    let end_date = 5

    axios.get('/materiali/materiale_carica_tutti/' + id_utente + '/' + programma_id)
        .then((res) => {
            console.log('MATERIALI appena fecciate da mongo')
            console.log(res.data)
            res.data.map(function(item, index, array){
                aggiungi_la_classe_mancante(item._id, nuova_classe_id)
                console.log(this) // 80
            }, 80);

        }).catch((error) => {
            console.log(error)
        });
}
exports.Metti_la_classe_in_tutti_i_materiali = Metti_la_classe_in_tutti_i_materiali


function aggiungi_classi_non_spalmate_array (materiale_id, programma_della_classe, id_utente) {
    axios.get('/materiali/trova_materiale/' + materiale_id)
    .then((res) => {
        console.log('MATERIALI appena fecciate da mongo')
        console.log(res.data)
        let lista_classi_gia_presenti = res.data.array_classi
        console.log('id_utente' + id_utente)
        console.log('programma_della_classe' + programma_della_classe)
        
        axios.get('/classi/classi_carica_tutti_programma/' + id_utente + '/' + programma_della_classe)
        .then((res) => {
            console.log('CLASSI appena fecciate da mongo')
            let lista_classi_per_programma = res.data
            controlla_se_aggiungere_classi_ad_array(lista_classi_gia_presenti, lista_classi_per_programma, materiale_id)        

        }).catch((error) => {
            console.log(error)
        });


        
    }).catch((error) => {
        console.log(error)
    });
}
exports.aggiungi_classi_non_spalmate_array = aggiungi_classi_non_spalmate_array


function trova_classi_che_seguono_il_programma (programma_della_classe, id_utente) {
    axios.get('/classi/classi_carica_tutti_programma/' + id_utente + '/' + programma_della_classe)
    .then((res) => {
        console.log('CLASSI appena fecciate da mongo')
        return res.data
    }).catch((error) => {
        console.log(error)
    });
}
exports.trova_classi_che_seguono_il_programma = trova_classi_che_seguono_il_programma



function controlla_se_aggiungere_classi_ad_array(array_classi, lista_classi_per_programma, id_materiale) {

    //        console.log('!!!!!! CHIAMaTA  Aggiunta una classe')
    let i 
    console.log(array_classi)
    console.log(lista_classi_per_programma)
            
    lista_classi_per_programma.map(function(item, index, array){
        i = array_classi.findIndex(e => e == item._id);
            console.log(item._id)
        if (i == -1) {

            aggiungi_la_classe_mancante(id_materiale, item._id)
        }
        console.log(this) // 80
    }, 80);
};
exports.controlla_se_aggiungere_classi_ad_array = controlla_se_aggiungere_classi_ad_array;



function aggiungi_la_classe_mancante(id_materiale,id_classe) {
    
    console.log('CHIAMATA  Aggiunta una classe')
    let classe_gia_spalmata = false
    let key_8_cifre = make_codice_alfanumerico(8)
    axios.put('/materiali/aggiungi_classe_al_materiale/' + id_materiale 
                                                        + '/' + id_classe 
                                                        + '/' + classe_gia_spalmata
                                                        + '/' + key_8_cifre
                                                        )
        .then((res) => {
//                console.log('Aggiunta una classe')
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
};        
exports.aggiungi_la_classe_mancante = aggiungi_la_classe_mancante;




function Trova_tutti_gli_alunni_per_la_classe(id_utente, materiale, classe_id, key_8_cifre) {
        
    axios.get('/alunni/alunni_carica_per_classe/' + id_utente + '/' + classe_id)
    .then((res) => {
        console.log('ALUNNI appena fecciate da mongo')
        console.log(res.data)
        res.data.map(function(item, index, array){
            
            const nuovo_materiale = {
                nome: 'p_1',
                utente: id_utente,
                alunno: item._id,
                key_8_cifre: key_8_cifre,
                domanda: materiale.domanda._id,
                key_password: materiale.key_password,
                key_tipo: materiale.key_tipo
            }
            let oggetto_costruito 
            oggetto_costruito = materiale
            oggetto_costruito.utente = id_utente
            oggetto_costruito.alunno = item._id
            oggetto_costruito.email_alunno = item.email
            oggetto_costruito.key_matrice = 'alunno'

            oggetto_costruito.key_8_cifre = key_8_cifre
//                console.log(nuovo_materiale)
            axios.post('/materiali/salva_nuovo', oggetto_costruito)
            console.log(this) // 80
        }, 60);
         
    }).catch((error) => {
        console.log(error)
    });
}

exports.Trova_tutti_gli_alunni_per_la_classe = Trova_tutti_gli_alunni_per_la_classe;


