import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Gestione_Alunno from './Alunno_x0_0';
import Gestione_Classe from './Classe_x0_0';


const Link_gestione_classi = (props) => {
    const [classe_selezionata, set_classe_selezionata] = useState('');
    const { user } = useAuth0();
    const [lista_classi, SET_lista_classi] = useState([]);

    useEffect(() => {
        console.log('USEEFFECT')
    }, []);

    return (
        <div>
            <Gestione_Classe lista_classi={lista_classi} SET_lista_classi={SET_lista_classi} 
                             set_classe_selezionata={set_classe_selezionata} 
                             account_dati={props.account_dati}
                             />

            <Gestione_Alunno classe_selezionata={classe_selezionata} 
                             account_dati={props.account_dati}
                            />
        </div>
    )
}
export default Link_gestione_classi;

