import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import Login_Profile from '../Login_Profile';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Form, Nav,  Button, NavDropdown } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import "./Navbar_new.css";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const Navbar_new = (props) => {
    //    const [somma, calcola_somma] = useState('');

    const id_utente = useSelector(state => state.variabile.value)
    const isLoggedIn = id_utente === "612fb32ad7c7ea0bf37cbe8f"
    const { user, isAuthenticated } = useAuth0();

    //    const id_utente = "";

    // idroslab
  
  
    function handleChange(event) {
      console.log(event.target)
      const { name, value, type } = event.target;
      console.log('cc5' + name + event.target.checked)
      if (name === "utente_debug") {
  //        dispatch(assegna_debug_redux(event.target.checked))
         console.log('ENTRST cc5' + name + event.target.checked)

          let updatedValue = {};
          updatedValue = {"utente_debug": event.target.checked};
          props.SET_account_dati(prevInput => ({
              ...prevInput,
              ...updatedValue
      }))
          axios.patch('/account/modifica_debug/' + id_utente + '/' +  event.target.checked)
          .then((res) => {
              console.log(res.data)
          //                props.SET_cambio_stato(!props.cambio_stato)
          }).catch((error) => {
              console.log(error)
          });
      }
    }

// idroslab
    const styles = {
      main: {
        display: 'none'
      },
      inputText: {
        padding: "10px",
        color: "red",
      },
    };


    return (
        <Navbar bg="light" expand="lg" sticky="top">
        <Container fluid>


          <Navbar.Brand href="#">Toolmemo 
          {user != undefined && 

            <img src={user.picture} alt={user.name}  width="25" height="25"  />
          }
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
            <Nav.Link href="#/url_video">Multimedia</Nav.Link>

            <Nav.Link href="#/url_domande_risposte">Studia</Nav.Link>
            <Nav.Link href="#/url_domande_mp3">Mp3</Nav.Link>

            <Nav.Link href="#/url_classi_alunni">Classi</Nav.Link>
            <Nav.Link href="#/url_materiale_UDA">Materiale</Nav.Link>
            <Nav.Link href="#/url_memo_bell">Memo Bell</Nav.Link>


            


            </Nav>
                 {user != undefined && user.name == "Paolo Sordi" && <>
                  <input  name="utente_debug"
                  type="checkbox"
                  defaultChecked={props.account_dati.utente_debug}
                  value={props.account_dati.utente_debug}
                  onChange={handleChange}
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                      Show Debug
                  </label>
                </>}
                <Login_Profile SET_account_dati ={props.SET_account_dati} />
                <LoginButton />
     
                <LogoutButton />
  
           
          </Navbar.Collapse>

        </Container>
      </Navbar>

        )
    }
    export default Navbar_new;
