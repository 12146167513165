import React, { useState, useEffect } from 'react';
import {Button, Modal, Form, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';
import Gestione_variabili_per_calcoli from "./X15_2_gestisci_calcoli";
import Gestione_testo_per_numeri_random from "./X15_1_base_calcoli";
import Risposte_multiple from "./X15_3_risposte_multiple";
import Gestione_immagini from "./X15_8_gestione_immagini.jsx";
import JSONPretty from 'react-json-pretty';
import Keyboard_input from "./X15_9_keyboard_input_edit.jsx";


async function salva_file_server_s3({ image, description }) {
    const formData = new FormData();
    formData.append("image", image)
    formData.append("description", description)

    const result = await axios.post('/transfer_s3/file_jpg', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    console.log('tornato nel front end')
    console.log(result.data)
    return result.data
}

function removeObjectWithId(arr, _id) {
    const objWithIdIndex = arr.findIndex((obj) => obj._id === _id);
  
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
  
    return arr;
  }


const Risposta_modifica = (props) => {
    const [input, setInput] = useState({
        domanda: '',
        risposta: ''
    });
    const [cambio_stato1, set_cambio_stato1] = useState(false)
    const [lista_variabili, set_lista_variabili] = useState(props.item.variabili_calcoli)
    const [lista_risposte_multiple, set_lista_risposte_multiple] = useState(props.item.risposte_multiple)
    const [show, set_Show] = useState(false);
   
    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
//        console.log('useeffect 1')

    }, [lista_variabili,cambio_stato1])


    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
//        console.log('useeffect 3')
        set_lista_risposte_multiple (props.item.risposte_multiple)
        set_lista_variabili(props.item.variabili_calcoli)
//        console.log('useeffect 3')
    }, [props.item])

    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
//        props.setShow_edit_domanda(props.show_edit_domanda)
//        console.log('useeffect 4')
    }, [props.show_edit_domanda])

    const handleClose = () => {
        console.log('Chiusura pagine modale')

        props.setShow_edit_domanda(false);
        //    funzione_set_aggiornamento(true)

        let newArr = [...props.lista_domande_render]; // copying the old datas array
        //        newArr[props.index].materia_id = item_id; // replace e.target.value with whatever you want to change it to
        newArr[props.index].domanda = input.domanda; // replace e.target.value with whatever you want to change it to
        newArr[props.index].risposta = input.risposta; // replace e.target.value with whatever you want to change it to
        props.SET_lista_domande_render(newArr);
        

        axios.patch('/domande/modifica/' + props.item._id, input)
            .then((res) => {
                console.log(res.data)
                props.SET_cambio_stato(!props.cambio_stato)

            }).catch((error) => {
                console.log(error)
            });
    }

    const handleClose_senza_salvare = () => {
        props.setShow_edit_domanda(false);
    }


    const apertura_finestra_modale = () => {
        console.log('apertura finestra modale EDIT')
        
        setInput(prevInput => {
            return {
                ...prevInput,
                ['domanda']: props.item.domanda,
                ['risposta']: props.item.risposta
            }
        })
        props.setShow_edit_domanda(true)
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    const cancella_variabile =  (id, id_array)  => {
        console.log('CANCELLA VARIABILE')
        console.log(id)
        console.log(id_array)
       
        axios.patch('/domande_calcoli/cancella_variabile/' + id + '/' + id_array)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log('errore')
                console.log(error)
            });
        //        props.SET_variab_aggiorna(!aux)
       
        let lista = lista_variabili
        console.log('CANCELLAZIONE')
        console.log(lista)
        removeObjectWithId(lista, id_array);
        console.log(cambio_stato1)
        set_lista_variabili(lista)
        set_cambio_stato1(!cambio_stato1)
    }

    return (
            <>
                <Modal
                    show={props.show_edit_domanda}
                    onHide={handleClose}
                    onShow= {apertura_finestra_modale}
                    backdrop="static"
                    keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Keyboard_input item={input} />
                        <Form>
                            <Form.Group controlId="exampleForm.ControlTextarea1">

                                <Form.Label> Domanda</Form.Label>
                                <Form.Control as="textarea" name="domanda" value={input.domanda} onChange={handleChange} rows={3} />

                                <Gestione_testo_per_numeri_random tipo_chiamata={2} 
                                                                stringa_passata = {input.domanda} 
                                                                item={props.item} 
                                                                lista_variabili={lista_variabili} />
                                {props.item.indirizzo_S3_immagine_domanda != undefined && <Gestione_immagini domanda={props.item} key={props.item.indirizzo_S3_immagine_domanda.key} 
                                                   salva="domanda" /> }
                                
 
                                <p> 
                                <Form.Label>Risposta</Form.Label>
                                <Form.Control as="textarea" name="risposta" value={input.risposta} onChange={handleChange} rows={3} />
                                <Gestione_testo_per_numeri_random   tipo_chiamata={2} 
                                                                    stringa_passata = {input.risposta} 
                                                                    item={props.item} 
                                                                    lista_variabili={lista_variabili} />
                                </p>                               
                            </Form.Group>
                        </Form>

                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>nome</th>
                                <th>minimo</th>
                                <th>massimo</th>
                                <th>passo</th>
                                <th>azioni</th>
                               
                                </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(lista_variabili) && <>              
          
                            {lista_variabili.map(function (item2, index2) {
                            //                                        var aux = ottieni_url(item2.indirizzo_S3)
                            //console.log(item2)
                                {
                                    return  <>
                                    <tr>
                                    <td>{item2.nome}</td>
                                    <td>{item2.minimo}</td>
                                    <td>{item2.massimo}</td>
                                    <td>{item2.passo}</td>
                                        <td>
                                            <button onClick={() => cancella_variabile(props.item._id,item2._id)}> Cancella</button>
                                        </td>
                                    </tr>
                                    </>
                                    
                                }
                            })
                            } 
                            </>
                            }

                        </tbody>
                         </Table>

                        <Gestione_variabili_per_calcoli lista_variabili={lista_variabili} 
                                                        set_lista_variabili={set_lista_variabili}  
                                                        item={props.item}  
                                                        cambio_stato1 = {cambio_stato1} 
                                                        set_cambio_stato1 = {set_cambio_stato1} />
                        <Risposte_multiple domanda={props.item} 
                                            lista_risposte_multiple = {lista_risposte_multiple}
                                            set_lista_risposte_multiple = {set_lista_risposte_multiple} 
                                            set_cambio_stato1 = {set_cambio_stato1}
                                            account_dati={props.account_dati}
                                            /> 
                        {(props.account_dati.utente_debug) &&
          
                            <JSONPretty data={lista_risposte_multiple} />
                        }          
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose_senza_salvare}>
                            Annulla
                        </Button>
                        <Button variant="primary" onClick={handleClose}>Salva</Button>
                    </Modal.Footer>
                </Modal>
            </>
        
    )
}
export default Risposta_modifica;