import React, { useState } from 'react';
//import ReactTooltip from 'react-tooltip';
import { ListGroup, Card, Modal, Col, Row, Tooltip, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios'
import '../App.css';



const Mostra_Form_link_per_studiare = (props) => {
    const [somma, calcola_somma] = useState('');

    const handleClose = () => props.setShow_link(false);
    const handleShow = () => props.setShow_link(true);

    const [input, setInput] = useState({
        nome: "",
        link: ""
    });

    function handleChange(event) {
        const { name, value } = event.target;
        console.log('sssssss')

        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    function handleClick(event) {
        event.preventDefault();
        //        console.log(input);
        //        const new_ditta = {
        //            ...input,
        //            data_nascita: end_Date
        //        }
        console.log('gggggggggg_11111111111111111111');
        console.log(props.item._id);

        console.log(input.testo);
        console.log(input.link);
        //        axios.post('http://localhost:3001/create', newNote)
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
        axios.put('/domande/aggiungi_link_per_studiare/' + props.item._id, input)
            .then((res) => {
                console.log('Aggiunto un link')
            }).catch((error) => {
                console.log('eerrore nella richiesta')
                console.log(error)
            });
        var aux = props.cambio_stato
        props.SET_cambio_stato(!aux)
        props.setShow_link(false)
    }


    return (
        <div>
            <Modal
                show={props.show_link}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Dialog>

                    <form>
                        <div className='form-group'>
                            <div className='rettangolo_rosso'>
                                <h5>Nuovo Link</h5>
                                <p>
                                    <Row>
                                        <Col>
                                            <label>Commento1</label>
                                            <input onChange={handleChange} placeholder="Testo" name="testo" value={input.testo} autoComplete="off" className="form-control"></input>
                                        </Col>
                                        <Col>
                                            <label>Link</label>
                                            <input onChange={handleChange} placeholder="Link" name="link" value={input.link} autoComplete="off" className="form-control"></input>
                                        </Col>
                                    </Row>
                                </p>
                            </div>

                        </div>
                        <div class="d-flex flex-row-reverse">
                            <button onClick={handleClick} className="btn btn-lg btn-info">Salva</button>
                        </div>
                    </form>

                </Modal.Dialog>
            </Modal>
        </div>
    )
}

export default Mostra_Form_link_per_studiare;
