import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown, Accordion, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const Dropdown_ordine_visualizzazione = (props) => {

    const [value1, setValue1] = useState('');
    const handleSelect=(e)=>{
        console.log(e);
        props.set_ordine_visualizzazione(e)
        props.SET_cambio_stato(!props.cambio_stato)
    }

    return (
        <DropdownButton 
        alignRight
        title= {props.ordine_visualizzazione}
        id="dropdown-menu-align-right"
        onSelect={handleSelect} >
                <Dropdown.Item eventKey="sort_old">sort_old</Dropdown.Item>
                <Dropdown.Item eventKey="sort_new">sort_new</Dropdown.Item>
                <Dropdown.Item eventKey="sort_easy">sort_easy</Dropdown.Item>
                <Dropdown.Item eventKey="sort_hard">sort_hard</Dropdown.Item>
                <Dropdown.Item eventKey="sort_forget">sort_forget</Dropdown.Item>

         </DropdownButton>
    )
}

export default Dropdown_ordine_visualizzazione;