import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import Gestione_file_mp3 from "./X43_Gestione_file_mp3.jsx";
import Gestione_file_mp3_aws from "./X43_Gestione_file_mp3_aws.jsx";

import Gestione_youtube from "./X41_Gestione_youtube.jsx";
import Gestione_video_mp4 from "./X42_Gestione_video_mp4.jsx";

// www.idroslab.com
function check_variabili_oggetto(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}

const Gestione_allegati_multimediali = (props) => {
//    const [somma, calcola_somma] = useState('');
    return (
    <>
        {(props.item.video_id !== null) &&  check_variabili_oggetto (props.item, 'video_id', 'tipo') == 'youtube' &&
            <Gestione_youtube item={props.item.video_id} id_bookmark={props.item.video_id_indice}/> 
        }
        {(props.item.video_id !== null) && check_variabili_oggetto (props.item, 'video_id', 'tipo') == 'mp3' &&
            <Gestione_file_mp3 item={props.item.video_id} id_bookmark={props.item.video_id_indice}/> 
        } 

        {(props.item.video_id !== null) && check_variabili_oggetto (props.item, 'video_id', 'tipo') == 'mp3' &&
            <Gestione_file_mp3_aws item={props.item.video_id} id_bookmark={props.item.video_id_indice}/> 
        } 

        {(props.item.video_id !== null) && check_variabili_oggetto (props.item, 'video_id', 'tipo') == 'video' &&
            <Gestione_video_mp4 item={props.item.video_id} id_bookmark={props.item.video_id_indice} /> 
        }     
    </>
    )
}
export default Gestione_allegati_multimediali;