import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import Navbar from "./compon_domande/Navbar";
import Navbar_new from "./compon_domande/Navbar_new";

import Lista_domande from "./compon_domande/X0_Main_gestione_studio";
import Lista_mp3 from "./compon_mp3/X0_lista_domande_mp3";

import Link_gestione_classi from "./compon_classe/link_gestione_classe";
import Link_impostazioni_personali from "./compon_domande/link_impostazioni";
import Link_gestione_materiale from "./compon_programmi/0_link_gestione_materiale";
import Link_gestione_video from "./compon_video/link_gestione_video";
import Link_memo_bell from "./compon_memo_bell/Link_memo_bell";

import Link_monitoraggio from "./compon_domande/link_monitoraggio";
import ShippingLabel from "./compon_domande/X31_prova_stampa_pdf"
import Bacheca_alunno from "./compon_classe/Alunno_bacheca"
import Root_principale from "./Root_principale"

import { Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';

function App() {
  const { isLoading } = useAuth0();

  const [account_dati, SET_account_dati] = useState({});
  const [input_opzioni, SET_input_opzioni] = useState({
    show_nomat: true,
    show_debug: false,
    scelta_query: "solo_testo",
    show_materie_tutte: true,
    scelta_non_walkmind: false,
    ordine_visualizzazione: 'sort_hard',
    number_max: 10,
    testo_ricerca: ''
  });
  
  if (isLoading) return <div>Loading...</div>

  return (
    <>
      <Navbar_new SET_account_dati={SET_account_dati} account_dati={account_dati} />
      <Routes>
        <Route path="url_impostazioni" element={<Link_impostazioni_personali />} />
        <Route path="url_domande_risposte" element={<Lista_domande account_dati={account_dati} SET_account_dati={SET_account_dati} SET_input_opzioni={SET_input_opzioni} input_opzioni={input_opzioni} />} />
        <Route path="url_domande_mp3" element={<Lista_mp3 account_dati={account_dati} SET_account_dati={SET_account_dati} SET_input_opzioni={SET_input_opzioni} input_opzioni={input_opzioni} />} />
   
        <Route path="url_classi_alunni" element={<Link_gestione_classi account_dati={account_dati} />} />
        <Route path="url_video" element={<Link_gestione_video account_dati={account_dati} />} />
        <Route path="url_materiale_UDA" element={<Link_gestione_materiale account_dati={account_dati} />} />
        <Route path="url_monitor_classi" element={<Link_monitoraggio />} />
        <Route path="url_prova_pdf" element={<ShippingLabel />} />
        <Route path="url_bacheca_alunno" element={<Bacheca_alunno account_dati={account_dati}/>} />
        <Route path="url_memo_bell" element={<Link_memo_bell account_dati={account_dati}/>} />

        <Route path="" element={<Root_principale account_dati={account_dati}  SET_input_opzioni={SET_input_opzioni}/>} />

      </Routes>
    </>
  );
}

export default App;
