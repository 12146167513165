import React, { useState, useRef, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import AudioPlayer from 'react-h5-audio-player';
import axios from 'axios';

import YouTube from "react-youtube"; 

function check_variabili_oggetto(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}


const Walkmind_play_spezzone_youtube_con_link = (props) => {
//    const [somma, calcola_somma] = useState('');
const reactPlayerRef = useRef();
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});
const [url, SET_url] = useState('');
const[play_stato, SET_play_stato] = useState(false)

const opts = {
    height: "390",
    width: "640",
    playerVars: {
        start: 100,
        end: 1000,
    // https://developers.google.com/youtube/player_parameters
        autoplay: 0
    }
};


useEffect(() => {
    if (props.indice_chiamata_componenti == 'fine play del pezzo mp3 amazon') {
        prova_a_mandare_in_play_un_bookmark() 
    }
}, [props.indice_chiamata_componenti]);


useEffect(() => {
     
}, [props.item.video_id]);


function prova_a_mandare_in_play_un_bookmark() { 
    
    console.log('BOOKMARK BOOKMARK BOOKMARK BOOKMARK BOOKMARK BOOKMARK')
    let btn, btn1
    btn = check_variabili_oggetto (props.item.video_id, 'tipo')
//    console.log('btn1 ' + btn);
    btn1 = check_variabili_oggetto (props.item.video_id, 'link')
//    console.log('btn1 ' + btn1);
    if (props.show && btn == 'youtube' && btn1 != null) {

        const bookmark_estratto = props.item.video_id.elenco_bookmark.filter(row => row._id === props.item.video_id_indice).pop();
        console.log(bookmark_estratto)
        SET_bookmark_estratto_stato(bookmark_estratto)
       
        console.log(reactPlayerRef)
        if (reactPlayerRef.current  != undefined && bookmark_estratto.tempo != undefined) { 
            console.log('BOOK BRK BOARK BKMARK BOOKMARK')
 
            reactPlayerRef.current.seekTo(bookmark_estratto.tempo);
            SET_play_stato(true)
        }



        const intervallo = bookmark_estratto.tempo_finale - bookmark_estratto.tempo 

        setTimeout(() => {
            console.log("Delayed for 1 second.");
            console.log(reactPlayerRef);
            if (reactPlayerRef.current != null) 

                reactPlayerRef.current.pauseVideo()

                props.SET_indice_chiamata_componenti('fine play del pezzo youtube')
                return;
        }, 1000*intervallo); 
    } 
 ;
    
    if (btn1 == null || btn != 'youtube') {
        props.SET_indice_chiamata_componenti('fine play del pezzo youtube')
        return;            
    }
}

    const onReady = (e) => {
    //        setPlayer(e.target);
        reactPlayerRef.current = e.target;
    };
    





return (
<>
    {props.item.video_id != undefined &&  props.item.video_id.tipo == 'youtube' &&
    <div className="d-flex justify-content-center">
    {/* url amazon {url} */}
            <YouTube
                videoId={props.item.video_id.link}
                onReady={onReady}
                opts={opts}
            />
    </div>
    }
</>
)

}
export default  Walkmind_play_spezzone_youtube_con_link;