const colorazione = (item_array, colore_background, currentTime2_s) => {
    var hStyle = "d-flex flex-row justify-content-between align-items-center mt-1 bg-secondary ";
    if (colore_background == 'success') {
    }     
    if (currentTime2_s > item_array.tempo && 
        currentTime2_s < item_array.tempo_finale)
        hStyle = "d-flex flex-row justify-content-between align-items-center mt-1 bg-success ";
     
    if (currentTime2_s < item_array.tempo || 
        currentTime2_s > item_array.tempo_finale)
        hStyle = "d-flex flex-row justify-content-between align-items-center mt-1 bg-warning ";
     
   
    if (item_array.bookmark_messo_in_bug === true) {
        hStyle = "d-flex flex-row justify-content-between align-items-center mt-1 bg-danger text-white";
    }
    if (item_array.solo_ascolto === true) {
        hStyle = "d-flex flex-row justify-content-between align-items-center mt-1 bg-dark text-white";
    }
    if (item_array.bookmark_trasformato_domanda === true) {
        hStyle = hStyle + " text-danger";
    }
    
    return hStyle;
}
exports.colorazione = colorazione;




/*

    function colorazione(item_array) {
        var hStyle = "d-flex flex-row-reverse justify-content-end align-items-center mt-1 bg-secondary text-white";
        if  (playerRef != null && playerRef.current != null && playerRef.current.hasOwnProperty('getCurrentTime')) {  
            if (elapsedTime > item_array.tempo && 
                playerRef.current.getCurrentTime() < item_array.tempo_finale)
                hStyle = "d-flex flex-row-reverse justify-content-end align-items-center mt-1 bg-success text-black";
            
            if (elapsedTime < item_array.tempo || 
                playerRef.current.getCurrentTime() > item_array.tempo_finale)
                hStyle = "d-flex flex-row-reverse justify-content-end align-items-center mt-1 bg-warning text-black";
        } 
        if (item_array.bookmark_messo_in_bug === true) {
            hStyle = "d-flex flex-row-reverse justify-content-end align-items-center mt-1 bg-danger text-white";
        }
        if (item_array.solo_ascolto === true) {
            hStyle = "d-flex flex-row-reverse justify-content-end align-items-center mt-1 bg-dark text-white";
        }
        return hStyle;
    }

*/