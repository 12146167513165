import React, { useState } from 'react';
import '../App.css';
import axios from "axios";

//import axios from "axios";
import { Dropdown, Button, Modal, Form, Row, Col } from 'react-bootstrap';
//import DatePicker from "react-datepicker";
import { AiOutlineMenuFold } from 'react-icons/ai';


const Nuovo_Video_Edit = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [end_Date, set_End_Date] = useState(new Date());
    const [input, setInput] = useState({
        nome: props.item.nome   
    });

    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        console.log('sssssss')
        console.log(end_Date)
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    function handleClick(event) {
    
        console.log('sssssss')
        console.log(end_Date)
   
        axios.patch('/classi/classe_modifica/' + props.item._id, input)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)  
            });
        setShow(false)
    }

    function cancellazione_video() {
        console.log('sssssss')
        console.log(props.item._id)
   
        axios.patch('/video/video_sposta_nel_cestino/' + props.item._id)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });

        /*
        axios.delete('/video/cancella_video/' + props.item._id)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log(error)
            });
        */    
        props.SET_variab_aggiorna(current => !current)
    }


    const Permetti_modifiche = (e) =>{
        props.SET_Video_selezionato(props.item._id)
        props.SET_modifica_dati_file_multimediale(true)
    }



    function cancellazione_file_multimediale() {
   
        axios.post('/transfer_s3_mp3/file_multimedia_cancella/' + props.item.aws_key)
        .then((res) => {
            console.log('XXXXXXXXXXXXXXXXXXXXX   Cancellato file mp3')
        }).catch((error) => {
            console.log('errore nella richiesta')
            console.log(error)
        });

        let variabili1 = {
       
        }

        let variabili = {
            aws_key: '',
            aws_filename: '',
            tipo: ''
        }

        axios.patch('/video/video_modifica_dati/' + props.item._id, variabili)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
   
        props.SET_variab_aggiorna(current => !current)
    }

    return (
        <div class="d-flex flex-row-reverse">
            <Dropdown>
                <Dropdown.Toggle variant="Light" id="dropdown-basic">
                    Menu  <AiOutlineMenuFold />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <div class="dropdown-divider"></div>

                    
                    {/* <Dropdown.Item onClick={handleShow} >Edit Video</Dropdown.Item> */} 
                    <Dropdown.Item onClick={() => Permetti_modifiche()} >Permetti Modifiche</Dropdown.Item>

                    <Dropdown.Item onClick={cancellazione_video} >Sposta nel Cestino</Dropdown.Item>

                    <Dropdown.Item onClick={cancellazione_file_multimediale} >Cancella file multimediale</Dropdown.Item>

                    
                </Dropdown.Menu>
            </Dropdown>

            <Modal
                show={show}
                
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
             <Modal.Header closeButton>
               <Modal.Title>Modal heading</Modal.Title>
           </Modal.Header>
               
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                    <Modal.Body>
                                     <Row>
                                        <Col>
                                            <label>Nome</label>
                                            <input onChange={handleChange} placeholder="Nome" name="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                                        </Col>
                                    </Row>
                                </Modal.Body>
        
                                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClick}>
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Nuovo_Video_Edit;
