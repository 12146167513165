import React, { useState } from 'react';
import axios from 'axios'
import '../App.css';
import { Dropdown } from 'react-bootstrap';
import Risposta_modifica from './X15_0_edit_risposta';
import { useSelector } from 'react-redux'
import Mostra_Form_link_per_studiare from './X12_form_allegati_link_studio';
import Mostra_Form_pdf_per_studiare from './X13_form_allegati_pdf';
import Mostra_Form_esercizio from './X30_form_esercizio';
const { Sintesi_vocale_esterna } = require('../funzioni_comuni_sintesi_vocale')

async function salva_file_server_s3({ image, description }) {
    const formData = new FormData();
    formData.append("image", image)
    formData.append("description", description)
    
    const result = await axios.post('/transfer_s3/file_pdf', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    console.log('tornato nel front end')
    console.log(result.data)
    return result.data
}


const Gestione_menu_alto_destra = (props) => {
    const [file, setFile] = useState()
    const [description, setDescription] = useState("")
    const [images, setImages] = useState([])
    const [show_edit_domanda, setShow_edit_domanda] = useState(false);
    
    const id_utente = useSelector(state => state.variabile.value)
    const isLoggedIn = id_utente === "612fb32ad7c7ea0bf37cbe8f"

    const [show_link, setShow_link] = useState(false);
    const [show_pdf, setShow_pdf] = useState(false);
    const [show_esercizio, setShow_esercizio] = useState(false);


   
    function Cambia_nome_allegati() {
        //        console.log('date')
        //        console.log(data_inizio)
        //        console.log(data_fine)
        if (props.elenco_pdf !== undefined) { 
       
            if (props.elenco_pdf.length > 0) {
                return <strong >
                    Allegati
                </strong>
            }
        }
        return <strong>
            |||
        </strong>
    }


    const handleSelect=(e)=>{
        console.log(e);
        if (e === "Cestino_Domanda") {Inserisci_cartella_speciale ('cestino')}    
        if (e === "Riattiva_Domanda") {Inserisci_cartella_speciale ('attiva')}    

        if (e === "Modifica_Domanda") {setShow_edit_domanda(true); console.log('entrato');}    
        if (e === "Play_Domanda") {funzione_play_audio(props.item.indirizzo_S3_mp3_domanda.key)}    
        if (e === "Play_Risposta") {funzione_play_audio(props.item.indirizzo_S3_mp3_risposta.key)}    
        if (e === "Nuovo_link") {setShow_link(true)}    
        if (e === "Nuovo_pdf") {setShow_pdf(true)}    
        if (e === "Nuovo_esercizio") {setShow_esercizio(true)}    
        if (e === "Vocale_Domanda") {Sintesi_vocale_esterna (props.item, 1)}    
        if (e === "Vocale_Risposta") {Sintesi_vocale_esterna (props.item, 2)}    
       

        

    }


    function Inserisci_cartella_speciale (parametro) {
        const param = {
            parametro: parametro
        }
        axios.patch('/domande/sposta_in_cartella_speciale/' +  props.item._id, param)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });

        }
    



    function funzione_play_audio(link_passato) {
        console.log('funzione_play_audio')
        axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + link_passato)
        .then((res) => {
            console.log('url appena fecciate da mongo')
            //                console.log(res.data)
           
            console.log(res.data)

            let audio = new Audio(res.data)
            console.log(audio.duration)
            audio.addEventListener('loadedmetadata', (e) => {
                console.log(e.target.duration);
            });

            audio.play()
            audio.addEventListener('ended', (e) => {
                console.log('finito');
            });

            //                var dateObj = new Date(res.data.data_nascita);
            //                set_End_Date(dateObj)
        }).catch((error) => {
            console.log(error)
        });
    }
    

    function cancellazione_domanda(e) {
        //        axios.post('http://localhost:3001/create', newNote)
        console.log('Richiesta di cancellazione domanda')


        axios.post('/transfer_s3/file_pdf_cancella/' + props.item.elenco_pdf.indirizzo_S3)
            .then((res) => {
                console.log('Aggiunto un file pdf')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });

        axios.post('/transfer_s3_mp3/file_mp3_cancella/' + props.item.indirizzo_S3_mp3_domanda.key)
            .then((res) => {
                console.log('XXXXXXXXXXXXXXXXXXXXX   Cancellato file mp3')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });

        axios.post('/transfer_s3_mp3/file_mp3_cancella/' + props.item.indirizzo_S3_mp3_risposta.key)
            .then((res) => {
                console.log('XXXXXXXXXXXXXXXXXXXXX   Cancellato file mp3')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });

        axios.delete('/domande/cancella/' + props.item._id)
            .then((res) => {
                console.log(res.data)
                var aux = props.cambio_stato
                props.SET_cambio_stato(!aux)
            }).catch((error) => {
                console.log(error)
            });
    }


    return (
        <div>

            <Dropdown  onSelect={handleSelect}>
                <Dropdown.Toggle id="dropdown-basic">
                    {Cambia_nome_allegati()}
                </Dropdown.Toggle>

                <Dropdown.Menu   >
                    <div className="verticale">
    
                    </div>
                    {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
                    <Dropdown.Item eventKey="Modifica_Domanda">Modifica domanda</Dropdown.Item>
                    {props.item.cestino === 'bug' && <Dropdown.Item eventKey="Riattiva_Domanda">Riattiva domanda</Dropdown.Item>}
                    <Dropdown.Divider />
                  
                    <Dropdown.Item eventKey="Play_Domanda">Play_domanda</Dropdown.Item>
                    <Dropdown.Item eventKey="Play_Risposta">Play_risposta</Dropdown.Item>
                    <Dropdown.Divider />
                  
                    <Dropdown.Item eventKey="Vocale_Domanda">Vocale_domanda</Dropdown.Item>
                    <Dropdown.Item eventKey="Vocale_Risposta">Vocale_risposta</Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="Nuovo_link">Nuovo link</Dropdown.Item>
                    <Dropdown.Item eventKey="Nuovo_pdf">Nuovo Pdf</Dropdown.Item>
                    <Dropdown.Item eventKey="Nuovo_esercizio">Nuovo Esercizio</Dropdown.Item>
                    <Dropdown.Divider />
                    
                    <Dropdown.Item eventKey="Cestino_Domanda">Cestino domanda</Dropdown.Item>
                  
                    {isLoggedIn && (
                        <>
                        {props.item._id} 
                        </>
                    )}
                </Dropdown.Menu>
            </Dropdown>
            <Risposta_modifica  show_edit_domanda= {show_edit_domanda}  
                                setShow_edit_domanda={setShow_edit_domanda}
                                cambio_stato= {props.cambio_stato}
                                SET_cambio_stato = {props.SET_cambio_stato}
                                lista_domande_render={props.lista_domande_render} 
                                SET_lista_domande_render={props.SET_lista_domande_render}
                                index={props.index}
                                item={props.item} 
                                account_dati={props.account_dati}
                                />

            <Mostra_Form_link_per_studiare item={props.item} setShow_link = {setShow_link} 
                                            show_link = {show_link}  
                                            cambio_stato= {props.cambio_stato}
                                            SET_cambio_stato = {props.SET_cambio_stato} />

            <Mostra_Form_pdf_per_studiare item={props.item} setShow_pdf = {setShow_pdf} 
                                            show_pdf = {show_pdf}  
                                            cambio_stato= {props.cambio_stato}
                                            SET_cambio_stato = {props.SET_cambio_stato} />
                                                                           
            <Mostra_Form_esercizio  domanda={props.item} setShow_esercizio = {setShow_esercizio} 
                                    show_esercizio = {show_esercizio} />                               

        </div>
    )
}
export default Gestione_menu_alto_destra;
