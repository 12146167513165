import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from "axios";
import { DropdownButton, Dropdown, Button, ButtonGroup, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import Nuova_Classe_Edit from "./Classe_x0_Edit";
//import DatePicker from "react-datepicker";
//import Crea_nuovo_alunno from "./Alunno_x0_1_crea_alunno";
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import JSONPretty from 'react-json-pretty';
const { Metti_la_classe_in_tutti_i_materiali } = require('../funzioni_comuni_array_classi')

const {  } = require('../compon_programmi/010_Programma_x0_0')


const Gestione_Classe = (props) => {
    const [input, setInput] = useState({
        nome: ''  

    });
    const [variab_aggiorna, SET_variab_aggiorna] = useState(false);
    const [lista_programmi, SET_lista_programmi] = useState([]);
    const [programma_selezionato_id, SET_programma_selezionato_id] = useState('');
    const [programma_selezionato, SET_programma_selezionato] = useState('');
    
    const id_utente = useSelector(state => state.variabile.value)
    const { user, isAuthenticated } = useAuth0();
    

    useEffect(() => {
        console.log('CLASSI USE EFFECT')
        Carica_programmi_x_account()
        
        Carica_classi_x_account()

    }, [variab_aggiorna,id_utente]);


    const handleSelect = (item_id) => {
        const nuovo_programma_id = {
            programma_id: item_id
        }
        SET_programma_selezionato_id(item_id)
        var result = lista_programmi.find(item => item._id === item_id);
        SET_programma_selezionato(result.nome)
    }


    const Carica_classi_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_classi_x_account')
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}

        axios.get('/classi/classi_carica_tutti/' + id_utente)
            .then((res) => {
                console.log('CLASSI appena fecciate da mongo')
                console.log(res.data)
                props.SET_lista_classi(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }
    
    const Carica_programmi_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_Programmi_x_account')
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}
    
        axios.get('/programmi/programmi_carica_tutti/' + id_utente)
            .then((res) => {
                console.log('PROGRAMMI appena fecciate da mongo')
                console.log(res.data)

                SET_lista_programmi(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }


    function handleChange(event) {
        const { name, value } = event.target;

        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    function handleClick_salva_nuova_classe(event) {
        event.preventDefault();
        const nuova_classe = {
            nome: input.nome,
            utente: id_utente,
            programma: programma_selezionato_id
        }

        console.log(input);
        axios.post('/classi/salva_nuovo', nuova_classe)
        .then((res) => {
            console.log('CLASSE appena salvata da mongo')
            console.log(res.data._id)
            let nuova_classe_id = res.data._id
            Metti_la_classe_in_tutti_i_materiali (id_utente, programma_selezionato_id, nuova_classe_id)
            let aux = variab_aggiorna
            SET_variab_aggiorna(!aux)
            
    
        }).catch((error) => {
            console.log(error)
        });


    }


    function Scelta_classe1(item) {
        
        console.log(item._id)     
   
    }


    const Scelta_classe=(e)=>{
        console.log(e.target.value)
        props.set_classe_selezionata(e.target.value)
    }

    function Inserisci_materia_attuale() {
        let btn = "..."
        return btn
    }

    return (
        <div>

            <div className='container'>
                <h1>Inserisci nuova classe </h1>
                <form>

                    <div className="d-flex flex-row">
        
                        <div className='form-group'>
                            <input onChange={handleChange} name="nome" placeholder="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                        </div>
                        <DropdownButton
                            variant="primary"
                            title=   {(programma_selezionato !== '') ? programma_selezionato : 'seleziona programma'  }  


                            onSelect={handleSelect} >
                            {lista_programmi.map(function (item1, index2) {
                                    {
                                        return <div>
                                            <Dropdown.Item eventKey={item1._id}> {item1.nome} </Dropdown.Item>
                                        </div>
                                    }
                                })
                            }
                            <Dropdown.Divider />
                            <Dropdown.Item eventKey="some link">some link</Dropdown.Item>
                        </DropdownButton>
                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button onClick={handleClick_salva_nuova_classe} disabled={programma_selezionato === '' || programma_selezionato_id === ''} className="btn btn-lg btn-info">Salva</button>
                    </div>

                </form>
            </div>



            <div className='container'>

                <ListGroup>
                    {props.lista_classi.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <div>
                                <Row>
                                     <Nuova_Classe_Edit item={item} variab_aggiorna={variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}/>

                                </Row>
                                <Row>
                                    <Col>
                                        <Row><p>Nome: <strong>{item.nome}</strong></p></Row>
                                    </Col>
                                    <Col>
                                        {(item.programma !== undefined && item.programma !== null) &&        
                                            <>          
                                            Programma: {item.programma.nome}         

                                            </> 
                                        }                
                                    </Col>
                                </Row>
                                <Row>
                                    {(props.account_dati.utente_debug) &&
                                        <JSONPretty data={item} />
                                    }
                                

                                </Row>

                                <hr class="rounded"></hr>

                            </div>
                        }
                    })
                    }
                </ListGroup>


                <h1>Inserisci nuovo Alunno </h1>

                <ButtonGroup onClick={Scelta_classe}>
                    {props.lista_classi.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <>
                                <Button value={item._id} variant="secondary">{item.nome}</Button>
 
                            </>
                        }
                    })
                    }
                </ButtonGroup>
                </div>

</div >

)
}

export default Gestione_Classe;
