import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import Gestione_creazione_domanda from "./Crea_domanda_Slide";
import Gestione_bug_bookmark  from "./Gestisci_bug_bookmark";
import Colore_background_bookmark from "./Colore_background.jsx";
import Bookmark_cancella from "./Bookmark_cancella.jsx";
import Trasforma_in_minuti_secondi from "./Trasforma_tutto_in_minuti_secondi.jsx";
import Bookmark_edit_punto_inizio from "./Bookmark_edit_punto_inizio.jsx";
import Bookmark_edit_durata from "./Bookmark_edit_durata.jsx";
import Nota_per_bookmark from "./Bookmark_edit_nota.jsx";



const Lista_componenti_bookmark = (props) => {
//    const [somma, calcola_somma] = useState('');
    return (
    <>
       <h3>{props.index_lista_bookmark}</h3>
          
       {(props.item_array && props.Bookmark_id_selezionato == props.item_array._id && !props.item_array.solo_ascolto) && <> 

            <Gestione_bug_bookmark video_id={props.video_id} item_array={props.item_array} />
            <Colore_background_bookmark SET_colore_background = {props.SET_colore_background}
                                                        colore_background = {props.colore_background}
                                                        item_array = {props.item_array} 
                                                        currentTime2_s = {props.currentTime2_s}
                                                        trigger_per_bookmark = {props.trigger_per_bookmark}


            />
   

        </>}  
              
        <Trasforma_in_minuti_secondi tempo_in_secondi={props.item_array.tempo} />
       
        {props.Bookmark_id_selezionato == props.item_array._id && !props.item_array.solo_ascolto  && <> 

                    
            <Trasforma_in_minuti_secondi tempo_in_secondi={props.item_array.tempo_finale} />
            <Bookmark_edit_punto_inizio item_array={props.item_array} 
                                                        SET_variab_aggiorna={props.SET_variab_aggiorna} 
                                                        reactPlayerRef={props.reactPlayerRef} 
                                                        trigger_per_bookmark = {props.trigger_per_bookmark}
                                                        SET_trigger_per_bookmark = {props.SET_trigger_per_bookmark}
                                                        /> 
            <Bookmark_edit_durata   item_array={props.item_array} 
                                    SET_variab_aggiorna={props.SET_variab_aggiorna} 
                                    reactPlayerRef ={props.reactPlayerRef}  
                                    trigger_per_bookmark = {props.trigger_per_bookmark}
                                    SET_trigger_per_bookmark = {props.SET_trigger_per_bookmark}

                                    /> 
            <Nota_per_bookmark  video_id={props.video_id} item_array={props.item_array} 
                                Video_id_selezionato={props.Video_id_selezionato} 
                                edit_testo={props.edit_testo} SET_edit_testo={props.SET_edit_testo} />
             <Bookmark_cancella id={props.video_id} item_array_id={props.item_array._id}  SET_variab_aggiorna={props.SET_variab_aggiorna}/>
                      
             <Gestione_creazione_domanda Video_id_selezionato={props.Video_id_selezionato}  video_id={props.video_id} item_array={props.item_array} />
      
        </>}
        {!(props.Bookmark_id_selezionato == props.item_array._id && !props.item_array.solo_ascolto)  && <> 

                    {props.item_array.nota}
      

        </>}
    </>
    )
}
export default Lista_componenti_bookmark;