import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';


const Link_monitoraggio = () => {
    const [somma, calcola_somma] = useState('');
    const { user } = useAuth0();

    useEffect(() => {
        console.log('USEEFFECT')
        Controllo_account_in_mongo_db()
    }, []);


    const Controllo_account_in_mongo_db = () => {
        //    funzione_set_aggiornamento(true)

        const variabile = 'google-oauth2|100873674024014033953';
//        console.log(user.sub)
        const body = {
//            sub: user.sub,
        }
        axios.get('/account/trova_account/' + variabile)
            .then((res) => {
                if (typeof res.data[0] === 'undefined') {
                    console.log('UTENTE NON ESISTE')
//                    Salva_account_in_mongo_db()
                    //                    Salva_domande_iniziali()
                } else {
                    console.log('UTENTE ESISTE')

                    console.log(res.data)
                    console.log(res.data[0]._id)
//                    dispatch(assegna_account(res.data[0]._id))
                }
            }).catch((error) => {
                console.log(error)
            });
    }

    return (
        <div>
            
ciao1

        </div>
    )
}
export default Link_monitoraggio;


/*
            <Link className="link" to="/url_monitor_ditte">Monitor ditte </Link>
            <Link className="link" to="/images">immagini S3 </Link>
*/
