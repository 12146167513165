import React, { useState, useEffect, useRef } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Input, Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import axios from "axios";
import Trasforma_in_minuti_secondi from "./Trasforma_tutto_in_minuti_secondi.jsx";

const Bookmark_edit_punto_inizio = (props) => {
//    const [somma, calcola_somma] = useState('');
const [input, setInput] = useState(null);

useEffect(() => {
    setInput(props.item_array.tempo)
}, [props.item_array]);


useEffect(() => {
   
    if (props.trigger_per_bookmark == 37) {
        salva_punto_inizio (props.item_array.tempo - 5)
        // va bene per i video
        if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.hasOwnProperty('seekTo')) {    

            props.reactPlayerRef.current.seekTo(props.item_array.tempo - 5, "seconds")
        }
        if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.audio != undefined) {    

            props.reactPlayerRef.current.audio.current.currentTime = props.item_array.tempo - 5
            props.reactPlayerRef.current.audio.current.play()
        }
        // youtube
    }
    if (props.trigger_per_bookmark == 39) {
        salva_punto_inizio (props.item_array.tempo + 5)
        // va bene per i video
        if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.hasOwnProperty('seekTo')) {    

            props.reactPlayerRef.current.seekTo(props.item_array.tempo + 5, "seconds")
        }

        if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.audio != undefined) {    

            props.reactPlayerRef.current.audio.current.currentTime = props.item_array.tempo + 5
            props.reactPlayerRef.current.audio.current.play()
        }
         // youtube

        

    }

    props.SET_trigger_per_bookmark(0)
}, [props.trigger_per_bookmark]);


useEffect(() => {

}, [input]);


function onclick_durata (indice) {
    let nuovo_stato_indice = input + indice
     axios.patch('/video/correggi_punto_inizio_nel_bookmark/' + props.item_array._id + '/' + nuovo_stato_indice)
    .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });
    setInput (prevState => prevState + indice)
    props.SET_variab_aggiorna(current => !current)
 
}



function onclick () {
let punto_iniziale = 0
// per file video

if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.hasOwnProperty('getCurrentTime')) {    
    console.log(props.reactPlayerRef.current.getCurrentTime())
    punto_iniziale = props.reactPlayerRef.current.getCurrentTime() 

}
// per file mp3
if (props.reactPlayerRef.current != undefined && props.reactPlayerRef.current.audio != undefined) {    
    punto_iniziale =props.reactPlayerRef.current.audio.current.currentTime 
}

// per yoputube
/*  
    console.log(props.reactPlayerRef.current.audio.current.currentTime)
*/    
     axios.patch('/video/correggi_punto_inizio_nel_bookmark/' + props.item_array._id + '/' + punto_iniziale)
    .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });
    
//    setInput (prevState => prevState + indice)
    salva_punto_inizio (punto_iniziale)
    
}


function salva_punto_inizio (punto_iniziale) {
    // per file video
    
    axios.patch('/video/correggi_punto_inizio_nel_bookmark/' + props.item_array._id + '/' + punto_iniziale)
    .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });
        
    //    setInput (prevState => prevState + indice)
    props.SET_variab_aggiorna(current => !current)
}


    return (
    <>
       {/** 
       <Button variant="outline-primary" onClick={() => onclick_durata(15)}>+15</Button>
       <Button variant="outline-primary" disabled={props.item_array.tempo<=15} onClick={() => onclick_durata(-15)}>-15</Button>
       */}

                 
        <Button variant="outline-primary" disabled={props.item_array.tempo<=15} onClick={() => onclick()}> Inizio</Button>
       
                    
    </>
    )
}
export default Bookmark_edit_punto_inizio;