import React, { useState, useRef, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import AudioPlayer from 'react-h5-audio-player';
import axios from 'axios';
import ReactPlayer from 'react-player'

function check_variabili_oggetto(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}


const Walkmind_play_spezzone_video_con_link = (props) => {
//    const [somma, calcola_somma] = useState('');
const reactPlayerRef = useRef();
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});
const [url, SET_url] = useState('');

const[play_stato, SET_play_stato] = useState(false)

useEffect(() => {
    if (props.indice_chiamata_componenti == 'fine play del pezzo youtube') {
        prova_a_mandare_in_play_un_bookmark() 
    }
}, [props.indice_chiamata_componenti]);


useEffect(() => {
    carica_url_per_aws() 
}, [props.item.video_id]);


function prova_a_mandare_in_play_un_bookmark() { 
    
    console.log('BOOKMARK BOOKMARK BOOKMARK BOOKMARK BOOKMARK BOOKMARK')
    let btn, btn1
    btn = check_variabili_oggetto (props.item.video_id, 'tipo')
//    console.log('btn1 ' + btn);
    btn1 = check_variabili_oggetto (props.item.video_id, 'aws_key')
//    console.log('btn1 ' + btn1);
    if (props.show && btn == 'video' && btn1 != null) {

        const bookmark_estratto = props.item.video_id.elenco_bookmark.filter(row => row._id === props.item.video_id_indice).pop();
        console.log(bookmark_estratto)
        SET_bookmark_estratto_stato(bookmark_estratto)
       
        console.log(reactPlayerRef)
        if (reactPlayerRef.current  != undefined && bookmark_estratto.tempo != undefined) { 
            reactPlayerRef.current.seekTo(bookmark_estratto.tempo, "seconds");
            SET_play_stato(true)
        }



        const intervallo = bookmark_estratto.tempo_finale - bookmark_estratto.tempo 

        setTimeout(() => {
            console.log("Delayed for 1 second.");
            console.log(reactPlayerRef);
            if (reactPlayerRef.current != null) SET_play_stato(false)

                props.SET_indice_chiamata_componenti('fine play del pezzo VIDEO amazon')
                return;
        }, 1000*intervallo); 
    } 
    console.log("---------" + btn1);
    
    if (btn1 == null || btn != 'video') {
        props.SET_indice_chiamata_componenti('fine play del pezzo VIDEO amazon')
        return;            
    }
}


const onPlay = () => {
    console.log(reactPlayerRef.current)
}


function carica_url_per_aws() {
//console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX carica_url_per_aws' )

const btn1 = check_variabili_oggetto (props.item.video_id, 'aws_key')
const btn2 = check_variabili_oggetto (props.item.video_id, 'tipo')

// console.log('XXXXXXXXX btn1 ' + btn1);
if (btn1 != null && btn2 == "video") {
        console.log('XXXXXXXX preso ' + props.item._id)
        console.log(url)

        axios.get('/transfer_s3_mp3/file_multimedia_ottieni_url/' + props.item.video_id.aws_key)
//            axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + 'ca32b2e50d177b9ce41c0cd148407f5')
        .then((res) => {
            console.log(res.data)
            SET_url(res.data)
        }).catch((error) => {
            console.log('error')
            console.log(error)
        });
    
    }
}

return (
<>
    {props.item.video_id != undefined &&  props.item.video_id.tipo == 'video' &&
    <div className="d-flex justify-content-center">
    {/* url amazon {url} */}
          <ReactPlayer ref={reactPlayerRef} 
                        url={url} 
                        controls={true} 
                       
                        playing={play_stato}
                        />
    
    </div>
    }
</>
)

}
export default Walkmind_play_spezzone_video_con_link;