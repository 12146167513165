import React, { useState, useRef, useEffect} from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import {CurrentStreamTime} from "./CurrentStreamTime";
import { ListGroup, Button, Row, Col} from 'react-bootstrap';
import axios from "axios";
import ReactPlayer from 'react-player'


import Lista_componenti_bookmark from "./Lista_componenti_bookmark.jsx";

import ringer from "../compon_mp3/camera2.wav";
import ringer_quite from "../compon_mp3/quite.mp3";

const { colorazione } = require('../funzioni_comuni_colorazione_video')
// Only loads the YouTube player

 
const Gestione_video = (props) => {
//    const [somma, calcola_somma] = useState('');

const[currentTime1, setCurrentTime1] = useState('00:00');
const[currentTime2_s, setCurrentTime2_s] = useState('');

const [currentTime2, setCurrentTime2] = useState('00:00')
const [play_stato, SET_play_stato] = useState(false)
const [edit_testo, SET_edit_testo] = useState(false);
const [colore_background, SET_colore_background] = useState('primary');
const audio_ring = new Audio(ringer);
const [url, SET_url] = useState('');
const reactPlayerRef = useRef();
const audio_quite = new Audio(ringer_quite);


useEffect(() => {

//    if (props.item.aws_filename != undefined && props.item.aws_filename.includes('.mp4')) carica_url_per_aws()

}, [props.item.aws_filename]);


useEffect(() => {


}, [props.item._id]);

useEffect(() => {
    if (props.item.aws_key != undefined && props.item.aws_filename.includes('.mp4')) carica_url_per_aws()


}, [props.item.aws_key]);


useEffect(() => {
    console.log('YYYYYYYY3 ' + props.trigger_per_bookmark) 

    if (props.trigger_per_bookmark == 1 && 
        props.item._id == props.Video_id_selezionato && 
        !edit_testo && !props.modifica_dati_file_multimediale) {

        imposta_bookmark(1)
    } 
    if (props.trigger_per_bookmark == 3 && 
        props.item._id == props.Video_id_selezionato && 
        !edit_testo && !props.modifica_dati_file_multimediale) {

        imposta_bookmark(2)
    } 
    if (props.trigger_per_bookmark == 2 && 
        props.item._id == props.Video_id_selezionato && 
        !edit_testo && !props.modifica_dati_file_multimediale) {
//        reactPlayerRef.current.audio.current.currentTime = reactPlayerRef.current.audio.current.currentTime - 25
          reactPlayerRef.current.seekTo(currentTime2_s - 10, "seconds")
          audio_quite.play()
    } 
    props.SET_trigger_per_bookmark(0) 

}, [props.trigger_per_bookmark]);



    function imposta_bookmark(tipo_chiamata) {
        // Using the callback version of `setState` so you always
        // toggle based on the latest state
        audio_ring.play()
        props.SET_counter_sleep_telefonino(0)

        let segnalibro = {
            nota: "",
            tempo: currentTime2_s,
            tempo_finale: currentTime2_s + 30
        
        }
        if (currentTime2_s > 20) {segnalibro.tempo = currentTime2_s - 20} 

        if (tipo_chiamata == 2) {segnalibro.solo_ascolto = true}
        //        axios.post('http://localhost:3001/create', newNote)
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
        axios.put('/video/aggiungi_bookmark/' + props.item._id, segnalibro)
            .then((res) => {
                console.log('Aggiunto un bookmark')
                console.log(res.data)
                console.log('bookmark _id assigned is: %s', res.data.elenco_bookmark[res.data.elenco_bookmark.length-1]._id);
                props.SET_Bookmark_id_selezionato(res.data.elenco_bookmark[res.data.elenco_bookmark.length-1]._id)

                
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });
    //        var aux = props.cambio_stato
    //        props.SET_cambio_stato(!aux)
        props.SET_variab_aggiorna(current => !current)
    }


    function carica_url_per_aws(e) {
    //        console.log('AAAAAAAAAAAAAAAAAAAA yyyyyyyyyyyyyyyyyyXXXXXXXX preso ' + props.item)
    //        console.log(props.item)
            
        if (props.item.aws_key != undefined ) {
                console.log('AAAAAAAAAAAAAAAAAAAA XXXXXXXX preso ' + props.item._id)
//                console.log(url)

                axios.get('/transfer_s3_mp3/file_multimedia_ottieni_url/' + props.item.aws_key)
    //            axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + 'ca32b2e50d177b9ce41c0cd148407f5')
                .then((res) => {
//                    console.log(res)
                    SET_url(res.data)
                }).catch((error) => {
                    console.log('error')
                    console.log(error)
                });
        }
    }


    function handle_seek_mp3(item_array) {
//        console.log(tempo_salto)
        reactPlayerRef.current.seekTo(item_array.tempo, "seconds")
        SET_play_stato(true)
        props.SET_Video_selezionato(props.item._id)
        props.SET_Bookmark_id_selezionato(item_array._id)
//        player.currentTime = tempo_salto
    }


    function onProgress(e) {
        setCurrentTime2_s(e.playedSeconds)
        if (props.item._id != props.Video_id_selezionato) {
            SET_play_stato(false)
    }
//      player.currentTime = tempo_salto
    }

    
    function onPlay(e) {
        props.SET_Video_selezionato(props.item._id)
        SET_play_stato(true)
//      player.currentTime = tempo_salto
    }
    

    const Correggi_punto_inizio = (id_array) => {
        console.log('ciaoerrore')
        axios.patch('/video/correggi_punto_inizio_video/' + props.item._id + '/' + id_array + '/' + currentTime2_s)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log('errore')
            console.log(error)
        });
    //        props.SET_variab_aggiorna(!aux)
        props.SET_variab_aggiorna(current => !current)
    }





    return (
        <div>
            <div className='currentTime'>
                <p>⏱ {currentTime2}  ⏱ {currentTime2_s} </p>
            </div>
            <div className='d-flex justify-content-center'> 

                <ReactPlayer ref={reactPlayerRef} 
                            url={url} 
                            light={true}
                            controls={true} onProgress={onProgress}
                            onPlay={onPlay}
                            playing={play_stato}
                            />
            </div>
            {props.item._id == props.Video_id_selezionato && 
         
            <div className="d-grid gap-2">
                    <Button variant={'primary'} size="lg" onClick={() => imposta_bookmark(1)}> Bookmark 2 </Button>
            </div>
            }

           

            <ListGroup variant="flush">
                {props.item.elenco_bookmark.map(function (item_array, index2) {
                    //                                        var aux = ottieni_url(item2.indirizzo_S3)
                    //                        console.log(item2)
                    {
                        return <div className={colorazione(item_array, colore_background, currentTime2_s)}>

                            <Lista_componenti_bookmark  item_array={item_array} index_lista_bookmark = {index2}
                                                        Video_id_selezionato={props.Video_id_selezionato}
                                                        Bookmark_id_selezionato = {props.Bookmark_id_selezionato} 
                                                        video_id={props.item._id} 
                                                        currentTime2_s = {currentTime2_s}
                                                        SET_colore_background = {SET_colore_background}
                                                        colore_background = {colore_background}
                                                        trigger_per_bookmark = {props.trigger_per_bookmark} 
                                                        SET_trigger_per_bookmark = {props.SET_trigger_per_bookmark}
                                                        reactPlayerRef={reactPlayerRef} 
                                                        SET_variab_aggiorna={props.SET_variab_aggiorna} 
                                                        edit_testo={edit_testo} SET_edit_testo={SET_edit_testo} />
                            <span>
                      
                       
                            <Button variant="outline-primary" onClick={() => handle_seek_mp3(item_array)}>Play</Button>{' '}
                 
                            </span>
                               
                            {/*  <Button variant="outline-primary" onClick={() => Correggi_punto_inizio(item_array._id)}>correggi</Button>{' '}
                               */ }
                         
                            {props.Bookmark_id_selezionato == item_array._id && !item_array.solo_ascolto  && <> 
       
                             
                             

                        
                            </>}             
                        </div>
                    }
                }).reverse()
                }
            </ListGroup>
        </div>
    )
}
export default Gestione_video;


