import React, { useState, useEffect } from 'react';
import { Button, DropdownButton, Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { create, all } from 'mathjs'
import parse from 'html-react-parser';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const Gestione_testo_per_numeri_random = (props) => {
    const [value1, setValue1] = useState('');
    const math = create(all,  {})


    function elaborazione_testo_per_calcoli() {
//        var re = /\{{(.*?)\}}/g;
        var re = /\[\[(.*?)\]\]/g
        //        console.log(props.stringa_passata);
        let calcoli_estratti         
        let result = ''
        let ogg_var = {}
        let calcoli_presenti = false
        result = props.stringa_passata
//        console.log('lista_variabili')

//        console.log('sasasa ' + props.lista_variabili)

        //        if (props.item._id === "62fa6e7b67a92983e63b2722") {console.log("WWWWWW" + result)}
        if (typeof props.stringa_passata === 'string' || props.stringa_passata instanceof String)
// it's a string
        {calcoli_estratti = props.stringa_passata.match(re)}

        if (props.lista_variabili !== undefined) {
            props.lista_variabili.map(function (item2_var, index2) {
                ogg_var[item2_var.nome] = item2_var.valore_attuale;
            })
        }

        if (calcoli_estratti != null) {

            calcoli_presenti = true   
//        console.log('entrato');
        
            props.stringa_passata.match(re).map(function (item1, index2) {
//            console.log(item1)
            let item1_stringa = item1.substring(2, item1.length - 2) 
//            console.log(item1_stringa);
    
            let variabile_trovata = false 
            props.lista_variabili.map(function (item2_var, index2) {
//                console.log(item2_var)
                if (item2_var.nome === item1_stringa) {
//                    console.log("sono uguali");
                    result = result.replace(item1, item2_var.valore_attuale);
//                    let result_risposta = eval("let " + item2_var.nome + "=" + numero);
//                    const count = oggetto_variabili.push({nome: item2_var.nome, numero:numero});
//                    eval("let " + item2_var.nome + "=" + numero);
                    variabile_trovata = true  
                }
                //                                        var aux = ottieni_url(item2.indirizzo_S3)
            })
            if (!variabile_trovata) {
//                console.log(ogg_var);

                let esegui_calcolo = true
                let controllo_stringa = item1_stringa 
                props.lista_variabili.map(function (item_aux, index3) {
                
                    //                    console.log(item_aux);
                if (typeof ogg_var[item_aux.nome] === 'number') {

                    controllo_stringa = controllo_stringa.replace(item_aux.nome, '');
                }
                })
                controllo_stringa = controllo_stringa.replace('0', '');
                controllo_stringa = controllo_stringa.replace('1', '');
                controllo_stringa = controllo_stringa.replace('2', '');
                controllo_stringa = controllo_stringa.replace('3', '');
                controllo_stringa = controllo_stringa.replace('4', '');
                controllo_stringa = controllo_stringa.replace('5', '');
                controllo_stringa = controllo_stringa.replace('6', '');
                controllo_stringa = controllo_stringa.replace('7', '');
                controllo_stringa = controllo_stringa.replace('8', '');
                controllo_stringa = controllo_stringa.replace('9', '');
             
                controllo_stringa = controllo_stringa.replace('=', '');
                controllo_stringa = controllo_stringa.replace('/', '');
                controllo_stringa = controllo_stringa.replace('*', '');
                controllo_stringa = controllo_stringa.replace('+', '');
                controllo_stringa = controllo_stringa.replace('-', '');
                controllo_stringa = controllo_stringa.replace('(', '');
                controllo_stringa = controllo_stringa.replace(')', '');
                controllo_stringa = controllo_stringa.replace(' ', '');
                if (controllo_stringa.length > 0) esegui_calcolo = false 

                props.lista_variabili.map(function (item_aux, index3) {
   
//                    console.log(item_aux);
                if (typeof ogg_var[item_aux.nome] === 'number') {

                    item1_stringa = item1_stringa.replace(item_aux.nome, 'ogg_var["'+ item_aux.nome + '"]');
                }

                    //                                        var aux = ottieni_url(item2.indirizzo_S3)
                })
                 let ris1 = "/ERRORE/"
//                 console.log(item1_stringa)
                 if (esegui_calcolo) {
                     eval("ris1=" + item1_stringa);
                 }
//                 ris1 =  math.evaluate(item1_stringa)
//                 console.log(ris1)  
                 result = result.replace(item1, ris1);
            }
 
            //                                        var aux = ottieni_url(item2.indirizzo_S3)
        })
   
    }
//    console.log(result)
return <>
    <p>

        {props.tipo_chiamata == 2 && calcoli_presenti && (
                        <>
                        <small> {result} </small> 
                        </>
        )}
        {props.tipo_chiamata === undefined && typeof result === 'string' && (
                        <>
                        {parse(result)} 
                        </>
        )}

        
        </p> {/* <p>
        <small>{JSON.stringify(calcoli_estratti)}</small>
</p> */}
</>
 
}

    return (
        <div>
            {elaborazione_testo_per_calcoli()}
        </div>
    )
}
export default Gestione_testo_per_numeri_random;