import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import axios from "axios";
//import DatePicker from "react-datepicker";
import { Button, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';



const Alunno_Form_Base = (props) => {

    const [variab_aggiorna, SET_variab_aggiorna] = useState('');
    const [end_Date, set_End_Date] = useState(new Date());
    const [input, setInput] = useState({
        nome: '',
        cognome: '',
        luogo_nascita: '',
        email: '',
        email_genitore: '',
        classe: '',
        corso: ''
    });


    useEffect(() => {
        console.log('USEEFFECT alunno_form_base')
        console.log(props.id)

        axios.get('/alunni/trova_alunno/' + props.id)
            .then((res) => {
                console.log('domande appena fecciate da mongo')
                //                console.log(res.data)
                setInput(res.data)
                console.log(res.data.data_nascita)
                //                var dateObj = new Date(res.data.data_nascita);
                //                set_End_Date(dateObj)

            }).catch((error) => {
                console.log(error)
            });
    }, []);



    function handleChange(event) {
        const { name, value } = event.target;
        console.log('sssssss')

        console.log(end_Date)
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    function handleClick(event) {
        event.preventDefault();
        axios.patch('/alunni/alunno_modifica/' + props.id, input)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });
        props.setShow(false)
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
    }

    return (
        <div>
                  <Modal.Header closeButton>
                    <Modal.Title>Form Alunno</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <p>

                                 <Row>
                                    <Col>
                                        <label>Cognome</label>
                                        <input onChange={handleChange} placeholder="Cognome" name="cognome" value={input.cognome} autoComplete="off" className="form-control"></input>
                                    </Col>
                                    <Col>
                                        <label>Nome</label>
                                        <input onChange={handleChange} placeholder="Nome" name="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                                    </Col>
                                </Row>
                            
                        </p>
                        <p>
                            <Row>
                                <Col>
                                    <label>Luogo di nascita</label>
                                    <input onChange={handleChange} placeholder="Città di nascita" name="luogo_nascita" value={input.luogo_nascita} autoComplete="off" className="form-control"></input>
                                </Col>
                                <Col>
                                    <label>Data di nascita</label>
                                    <input onChange={handleChange} placeholder="Data di nascita" name="data_nascita" value={input.data_nascita} autoComplete="off" className="form-control"></input>
                                </Col>


                            </Row>
                        </p>
                        <p>
                            <Row>
                                <Col>
                                    <label>Codice Fiscale</label>
                                    <input onChange={handleChange} placeholder="Codice Fiscale" name="codice_fiscale" value={input.codice_fiscale} autoComplete="off" className="form-control"></input>
                                </Col>

                            </Row>
                        </p>

                        <p>

                            <Row>
                                <Col>
                                    <label>Indirizzo</label>
                                    <input onChange={handleChange} placeholder="Via, Piazza, Vicolo " name="indirizzo_via" value={input.indirizzo_via} autoComplete="off" className="form-control"></input>
                                </Col>
                                <Col>
                                    <label>Civico</label>
                                    <input onChange={handleChange} placeholder="Civico " name="indirizzo_civico" value={input.indirizzo_civico} autoComplete="off" className="form-control"></input>
                                </Col>

                                <Col>
                                    <label>Città </label>
                                    <input onChange={handleChange} placeholder="Città" name="indirizzo_citta" value={input.indirizzo_citta} autoComplete="off" className="form-control"></input>
                                </Col>
                            </Row>
                        </p>
                        <p>

                            <Row>
                                <Col>
                                    <label>Classe</label>
                                    <input onChange={handleChange} placeholder="Classe" name="classe" value={input.classe} autoComplete="off" className="form-control"></input>
                                </Col>
                                <Col>
                                    <label>Corso </label>
                                    <input onChange={handleChange} placeholder="Autoriparatori - Manut. Elettrici - Moda" name="corso" value={input.corso} autoComplete="off" className="form-control"></input>
                                </Col>
                            </Row>
                        </p>

                        <p>
                            <Row>
                                <Col>
                                    <label>Email_personale</label>
                                    <input onChange={handleChange} placeholder="Email" name="email" value={input.email} autoComplete="off" className="form-control"></input>
                                </Col>
                                <Col>
                                    <label>Email1 genitore</label>
                                    <input onChange={handleChange} placeholder="Email genitore" name="email_genitore1" value={input.email_genitore1} autoComplete="off" className="form-control"></input>
                                    <label>Email2 genitore</label>
                                    <input onChange={handleChange} placeholder="Email genitore" name="email_genitore2" value={input.email_genitore2} autoComplete="off" className="form-control"></input>
                                </Col>

                            </Row>
                        </p>
                    </Modal.Body>
                    

                    <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleClick}>
                        Save Changes
                    </Button>
                    </Modal.Footer>
        
           
        </div>
    );
}
export default Alunno_Form_Base;