import React, { useState } from 'react';
//import './navbar.css';
import { Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { assegna_debug_redux } from '../REDUX_feauture/assegna_variabile'
import axios from 'axios';


const Opzioni_Visualizzazione = (props) => {
    const dispatch = useDispatch()
    const id_utente = useSelector(state => state.variabile.value)
   

    function handleChange(event) {
   
    console.log(event.target)
    const { name, value, type } = event.target;
    console.log('cc5' + name + event.target.checked)

    if (name === "show_debug") {
//        dispatch(assegna_debug_redux(event.target.checked))
        let updatedValue = {};
        updatedValue = {"utente_debug": event.target.checked};
        props.SET_account_dati(prevInput => ({
            ...prevInput,
            ...updatedValue
    }))
        axios.patch('/account/modifica_debug/' + id_utente + '/' +  event.target.checked)
        .then((res) => {
            console.log(res.data)
        //                props.SET_cambio_stato(!props.cambio_stato)
        }).catch((error) => {
            console.log(error)
        });
    }


    if (type === "checkbox") 
    {
        props.setinput_opzioni(prevInput => {
            return {
                ...prevInput,
                [name]: event.target.checked
            }
        })
    } else
    {
        props.setinput_opzioni(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    } 
    props.SET_cambio_stato(!props.cambio_stato)
    }

    const handleChange_radio = e => {
        e.persist();
        const { name, value, type } = e.target;
        let valore_da_assegnare = e.target.value
        if (e.target.value == '1') valore_da_assegnare = true
        if (e.target.value == '0') valore_da_assegnare = false

        console.log('handleChange_radio');
        console.log(e.target);
        console.log(valore_da_assegnare);
        props.setinput_opzioni(prevInput => {
            return {
                ...prevInput,
                [name]: valore_da_assegnare
            }
        })
        props.SET_cambio_stato(!props.cambio_stato)

    };


    return (
        <div>
            <Col>       
                <input  name="show_nomat" 
                type="checkbox" 
                defaultChecked={props.input_opzioni.show_nomat}
                value={props.input_opzioni.show_nomat}
                onChange={handleChange}
                />
                <label class="form-check-label" for="flexCheckChecked">
                    Show senza materie
                </label>
            </Col>
            <Col>       
                <input  name="show_debug" 
                type="checkbox" 
                defaultChecked={props.input_opzioni.show_debug}
                value={props.input_opzioni.show_debug}
                onChange={handleChange}
                />
                <label class="form-check-label" for="flexCheckChecked">
                    Show Debug
                </label>
            </Col>
            <Col>
                <Form.Group controlId="kindOfStand">
                    <div className="d-flex flex-row">
                    &nbsp;
                    <Form.Check
                        name="scelta_query"
                        value="solo_mp3"
                        type="radio"
                        aria-label="radio 1"
                        label="Solo mp3"
                        onChange={handleChange_radio}
                        checked={props.input_opzioni.scelta_query === "solo_mp3"}
                    />
                    &nbsp;
                    <Form.Check
                        name="scelta_query"
                        value="solo_testo"
                        type="radio"
                        aria-label="radio 2"
                        label="Solo testo"
                        onChange={handleChange_radio}
                        checked={props.input_opzioni.scelta_query === "solo_testo"}
                    />
                    &nbsp;
                    <Form.Check
                        name="scelta_query"
                        value="tutto"
                        type="radio"
                        aria-label="radio 2"
                        label="tutto"
                        onChange={handleChange_radio}
                        checked={props.input_opzioni.scelta_query === "tutto"}
                    />
                    <Form.Check
                        name="scelta_query"
                        value="bookmark"
                        type="radio"
                        aria-label="radio 2"
                        label="bookmark"
                        onChange={handleChange_radio}
                        checked={props.input_opzioni.scelta_query === "bookmark"}
                    />
                    
                    </div>

                </Form.Group>


                <Form.Group controlId="kindOfStand_nonwalkmind">
                    <div className="d-flex flex-row">
                    &nbsp;
                    <Form.Check
                        name="scelta_non_walkmind"
                        value = "0"
                        type="radio"
                        aria-label="radio 1"
                        label="walkmind"
                        onChange={handleChange_radio}
                        checked={props.input_opzioni.scelta_non_walkmind == false}
                    />
                    &nbsp;
                    <Form.Check
                        name="scelta_non_walkmind"
                        value = "1"
                        type="radio"
                        aria-label="radio 2"
                        label="non walkmind"
                        onChange={handleChange_radio}
                        checked={props.input_opzioni.scelta_non_walkmind == true}
                    />
                    &nbsp;
                   
                    
                    </div>

                </Form.Group>
            
            
            </Col>
        </div>
    )
}
export default Opzioni_Visualizzazione;