import React, { useState, useRef, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import YouTube from "react-youtube"; 


const Gestione_youtube = (props) => {
//    const [somma, calcola_somma] = useState('');


const reactPlayerRef = useRef();
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});
const[stato_del_Player, SET_stato_del_Player] = useState(0);


const opts = {
    height: "390",
    width: "640",
    playerVars: {
        start: 100,
        end: 1000,
    // https://developers.google.com/youtube/player_parameters
        autoplay: 0
    }
};


useEffect(() => {
    console.log('-X0--- USEEFFECT -----')
    const bookmark_estratto = props.item.elenco_bookmark.filter(row => row._id === props.id_bookmark).pop();
//    console.log(bookmark_estratto.tempo)
}, [props.item]);



const onReady = (e) => {
    
    reactPlayerRef.current = e.target;
    console.log(props.id_bookmark)
    const bookmark_estratto = props.item.elenco_bookmark.filter(row => row._id === props.id_bookmark).pop();

    SET_bookmark_estratto_stato(bookmark_estratto)
   
//    reactPlayerRef.current.seekTo(bookmark_estratto.tempo);
//bookmark_idroslab    
   
//    player.seekTo(bookmark_estratto.tempo)

};

const onStateChange = (e) => {
    if (e.data === 1) {
        if (stato_del_Player != 1) {
        // Cerca il tempo specificato solo se il video è in riproduzione
            reactPlayerRef.current.seekTo(bookmark_estratto_stato.tempo, true); // 03:54 in secondi
            SET_stato_del_Player(e.data)
            const intervallo = bookmark_estratto_stato.tempo_finale - bookmark_estratto_stato.tempo 
  
            setTimeout(() => {
                console.log("Delayed for 1 second.");
                reactPlayerRef.current.pauseVideo()
                
            }, 1000 * intervallo); 
        }
    }
  
};

const onPlay = (e) => {
 
//    player.seekTo(bookmark_estratto.tempo)
         
};

return (
    <>
        <YouTube
            videoId={props.item.link}
            opts={opts}
            onReady={onReady}
            onPlay={onPlay}
            onStateChange={onStateChange}
        />
        
    </>
)
}
export default Gestione_youtube;