import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import YouTube from "react-youtube"; 
import { ListGroup, Button, Row, Col} from 'react-bootstrap';
import axios from "axios";
import Gestione_creazione_domanda from "./Crea_domanda_Slide";
import Nota_per_bookmark from "./Bookmark_edit_nota.jsx";
import Trasforma_in_minuti_secondi from "./Trasforma_tutto_in_minuti_secondi.jsx";
import ringer from "../compon_mp3/camera2.wav";
 
const Gestione_seminario_Realtime = (props) => {
//    const [somma, calcola_somma] = useState('');

const [player, setPlayer] = useState(null);
const [edit_testo, SET_edit_testo] = useState(false);
const audio_ring = new Audio(ringer);

    function handleShow() {
    }

    function handleShow1(tempo_salto) {

    }

    const onReady = (e) => {

    };

    function handleClick() {
        audio_ring.play()
        props.SET_Video_selezionato(props.item._id)
        console.log('gggggggggg_11111111111111111111');
//        props.SET_counter_sleep_telefonino(100)
//        console.log(player.getCurrentTime())

        var x = new Date(props.item.date);
        console.log(x);
        console.log(Date.now());

        let seconds = (Date.now() - x.getTime())/1000;
        console.log(seconds);

        const segnalibro = {
            nota: "",
            tempo: seconds,
            tempo_finale: seconds + 30
         
        }
        //        axios.post('http://localhost:3001/create', newNote)
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
        axios.put('/video/aggiungi_bookmark/' + props.item._id, segnalibro)
            .then((res) => {
                console.log('Aggiunto un bookmark')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });
//        var aux = props.cambio_stato
//        props.SET_cambio_stato(!aux)
        props.SET_variab_aggiorna(current => !current)

    }

    const cancella_link = (id_array) => {
        console.log('sdfd')
        axios.patch('/video/cancella_bookmark/' + props.item._id + '/' + id_array)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log('errore')

                console.log(error)
            });
        //        props.SET_variab_aggiorna(!aux)
        props.SET_variab_aggiorna(current => !current)
    }




    return (
        <div>

        <div className='d-flex justify-content-end'>        
     
            <Button variant="primary" onClick={handleClick}>
                Nuovo bookmark
            </Button>                                 
            <Button variant="primary" onClick={handleShow1}>
                Player seek
            </Button>                                 
        </div>
            <ListGroup variant="flush">
                {player != null && player.getCurrentTime()}

                {props.item.elenco_bookmark.map(function (item_array, index2) {
                    //                                        var aux = ottieni_url(item2.indirizzo_S3)
                    //                        console.log(item2)
                    {
                        return <ListGroup.Item className="d-flex justify-content-between">
                                <div className="d-flex flex-row-reverse justify-content-end align-items-center mt-3">
                           
                                <Nota_per_bookmark  video_id={props.item._id} item_array={item_array} 
                                                    Video_id_selezionato={props.Video_id_selezionato} 
                                                    edit_testo={edit_testo} SET_edit_testo={SET_edit_testo} 
                                />
                                <Trasforma_in_minuti_secondi tempo_in_secondi={item_array.tempo} />

                                </div>
                                <div className="d-flex flex-row-reverse justify-content-end align-items-center mt-3">
                            
                                    <Gestione_creazione_domanda Video_id_selezionato={props.Video_id_selezionato}  video_id={props.item._id} item_array={item_array} />
                                    <Button variant="outline-primary" onClick={() => cancella_link(item_array._id)}>Cancella</Button>{' '}

                                </div>
                            </ListGroup.Item>
                    }
                }).reverse()
                }
            </ListGroup>

        </div>
    )
}
export default Gestione_seminario_Realtime;