import React, { useState } from 'react';
//import ReactTooltip from 'react-tooltip';
import { ListGroup, Card, Modal, Col, Row, Tooltip, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios'
import '../App.css';

async function salva_file_server_s3({ image, description }) {
    const formData = new FormData();
    formData.append("image", image)
    formData.append("description", description)
    const result = await axios.post('/transfer_s3/file_pdf', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    console.log('tornato nel front end')
    console.log(result.data)
    return result.data
}

const Mostra_Form_pdf_per_studiare = (props) => {

    const [description, setDescription] = useState("")
    const [images, setImages] = useState([])

    const [file, setFile] = useState()

    const handleClose = () => props.setShow_pdf(false);
    const handleShow = () => props.setShow_pdf(true);

    const handleClose_senza_salvare = () => {
        props.setShow_pdf(false);
    }

    const fileSelected = event => {
        const file = event.target.files[0]
        setFile(file)
    }

    const Invia_file_al_bucket_S3 = async event => {
        event.preventDefault()
        const result = await salva_file_server_s3({ image: file, description })
        setImages([result.image, ...images])
        console.log(result.Key)
        console.log(file)

        axios.put('/domande/aggiungi_allegato/' + props.item._id + '/' + result.Key + '/' + file.name)
            .then((res) => {
                console.log('Aggiunto un file pdf')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });
        var aux = props.cambio_stato
        props.SET_cambio_stato(!aux)

    }


    function handleClick(event) {
     
    }


    return (
        <div>
            <Modal
                show={props.show_pdf}
                size="lg"
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Dialog>

                    <form onSubmit={Invia_file_al_bucket_S3}>
                        <div class="d-flex justify-content-between">
 
                            <input onChange={fileSelected} type="file" accept="application/pdf"></input>
                            <Button variant="outline-primary" type="submit">Carica File pdf</Button>
                        </div>
                    </form>     

                </Modal.Dialog>
            </Modal>
        </div>
    )
}

export default Mostra_Form_pdf_per_studiare;