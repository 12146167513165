import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"

const Colore_background_bookmark = (props) => {
//    const [somma, calcola_somma] = useState('');

useEffect(() => {


//     console.log('ccccccccccccccccc')


}, [props.trigger_per_bookmark]);


    return (
    <></>
    )
}
export default Colore_background_bookmark;