import React, { useState, useEffect, useRef } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import microPhoneIcon from "./microphone.svg";
import ringer_just from "./just.mp3";

const Riconoscimento_vocale = (props) => {


    const audio_just = new Audio(ringer_just);
    const commands = [
        {
          command: "open *",
          callback: (website) => {
            window.open("http://" + website.split(" ").join(""));
          },
        },
        {
            command: "incrementa *",
            callback: (numero) => {
                console.log ('sadgfasssssssssssssssssssssssssssssss') 
                props.SET_input_microfono('incrementa ' + numero)
            },
        },
        {
          command: "facile",
          callback: () => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('facile')
          },
        },
        {
          command: "facilissimo",
          callback: () => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('facilissimo')
          },
        },
        {
          command: "difficile",
          callback: (numero) => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('difficile')
          },
        },
        {
          command: "sciocchezza",
          callback: (numero) => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('sciocchezza')
          },
        },
        {
          command: "correggere",
          callback: (numero) => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('correggere')
          },
        },
        {
          command: "elimina",
          callback: (numero) => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('elimina')
          },
        },
        {
          command: "errore",
          callback: (numero) => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('errore')
          },
        },
        {
          command: "cestino",
          callback: (numero) => {
              console.log ('sadgfasssssssssssssssssssssssssssssss') 
              props.SET_input_microfono('cestino')
          },
        },
        {
          command: "change background colour to *",
            callback: (color) => {
                document.body.style.background = color;
            },
        },
        {
          command: "reset",
          callback: () => {
            handleReset();
          },
        },
        ,
        {
          command: "reset background colour",
          callback: () => {
            document.body.style.background = `rgba(0, 0, 0, 0.8)`;
          },
        },
    ];
    const { transcript,  listening, resetTranscript } = useSpeechRecognition({ commands });

//    const microphoneRef = useRef(null);

      
  useEffect(() => {
//    console.log('props.accensione_microfono: ' + props.accensione_microfono)
    if (props.accensione_microfono) 
      { console.log('props.accensione_microfono: ' + props.accensione_microfono)
      audio_just.play()
      audio_just.addEventListener('ended', (e) => {
          
//      setTimeout(() => {
        handleListing()
      })
//       }, 2000)
    }
    if (!props.accensione_microfono) 
       {
//     console.log('props.accensione_microfono: ' + props.accensione_microfono)
       stopHandle()
    }
      
  }, [props.accensione_microfono]);


  useEffect(() => {
    //    console.log('props.accensione_microfono: ' + props.accensione_microfono)
    if (listening) {
      props.setIsListening(true);
      props.SET_numero_accensioni_microfono (prevState => prevState +1)
//     props.SET_input_microfono('microfono_acceso')
    }
          
  }, [listening]);

  
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return (
            <div className="mircophone-container">
            Browser is not Support Speech Recognition.
            </div>
        );
    }

    const handleListing = () => {
     
   
//      microphoneRef.current.classList.add("listening");
      SpeechRecognition.startListening({
        continuous: true,
      });
    };


    const stopHandle = () => {
      props.setIsListening(false);
//      microphoneRef.current.classList.remove("listening");
      SpeechRecognition.stopListening();
      resetTranscript();
    };


    const handleReset = () => {
      stopHandle();
      resetTranscript();
    };

    
    return (
      <div className="microphone-wrapper">
        <div className="mircophone-container">
     
          <div className="microphone-status">
            {/* props.isListening ? "Microfono acceso ......." : "Microfono spento" */}
          </div>
          {props.isListening && (
            <button className="microphone-stop btn" onClick={stopHandle}>
              Stoppato 
            </button>
          )}

          <p> Microfono: {listening ? 'on' : 'off'}</p>
        </div>
        {transcript && (
          <div className="microphone-result-container">
            <div className="microphone-result-text">{transcript}</div>
            <button className="microphone-reset btn" onClick={handleReset}>
              Reset
            </button>
          </div>
        )}
      </div>
    );

};
export default Riconoscimento_vocale;