import React, { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector, useDispatch } from 'react-redux'
import Nota_per_bookmark from "./Bookmark_edit_nota.jsx";

const WebSocketComponent = (props) => {
  const id_utente = useSelector(state => state.variabile.value)

//  console.log('CCCCCCCCCCCCCCCCCCCCC  id_utente ' + id_utente)

  const getWebSocketUrl = () => {
      let indirizzo_web = 'ws://localhost:5000'
//    if (window.location.protocol === 'https:') 
      
      if (window.location != undefined && window.location.href.includes('https')) {
        
         indirizzo_web = 'wss://toolmemo.herokuapp.com/' 
      }
//        console.log(indirizzo_web)

//      console.log(window.location.href)
  
//    let protocol =  ? 'wss' : 'ws';
//    let host = window.location.host;
//    return `${protocol}://${host}`;
      return indirizzo_web;
  };
  const [socketUrl] = useState(getWebSocketUrl());

  const [messageHistory, setMessageHistory] = useState([]);
  const [item_array, SET_item_array] = useState({});

  const [websocket_clientId, setClientId] = useState(null);


  useEffect(() => {
// in realta probabilmente non funziona    
    console.log(lastMessage);
    const messaggio = JSON.parse(lastMessage);
    if (lastMessage !== null) {
      console.log(lastMessage.data);
    }

  
  }, [lastMessage]);

	useEffect(() => {
		if (id_utente != 0 && websocket_clientId != undefined) {
			console.log('id_utente ' + id_utente)
			sendMessage(JSON.stringify({type:"riconoscimento", mongo_id: id_utente, clientId: websocket_clientId}))
		}
	}, [id_utente, websocket_clientId]);



	useEffect(() => {
		if (id_utente != 0 && websocket_clientId != undefined) {
			console.log('spedito 1 messaggio id_utente per questo account' + id_utente)
			sendMessage(JSON.stringify({type:"bookmark_id_appena_creato", message: props.Bookmark_id_selezionato, to: id_utente, clientId: websocket_clientId}))
		}
	}, [props.Bookmark_id_selezionato]);

  

	const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
			onOpen: () => {
			//      	console.log('Connected to WebSocket')
			//      	console.log('Connected to WebSocket2')
			//      sendMessage(JSON.stringify({type:"message", to: 'ss' ,clientId: clientId}))
			},
		onMessage: (message) => {
		
		const data = JSON.parse(message.data);
	//      console.log(data);
		if (data.type === 'id') {
			setClientId(data.clientId);

		}
		if (data != undefined && data.type == "messaggio_crea_bookmark") {
			props.SET_trigger_per_bookmark(1)
	//        console.log(lastMessage.data);
		}
		if (data != undefined && data.type == "bookmark_id_appena_creato") {

			SET_item_array(prevInput => {
				return {
					...prevInput,
					['nota']: '',
					['_id']: data.message
				}
			})
			
	//        console.log(lastMessage.data);
		}
		},
	});


  const handleClickSendMessage = () => {
    sendMessage('Hello from React');
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <>
    <button onClick={() => sendMessage(JSON.stringify({type:"messaggio_stesso_client", to: id_utente, clientId: websocket_clientId}))}>
      Send Message
    </button>
	<button onClick={() => sendMessage(JSON.stringify({type:"messaggio_stesso_client_escluso_mittente", to: id_utente, clientId: websocket_clientId}))}>
      Send Message escluso mittente
    </button>
  	<button onClick={() => sendMessage(JSON.stringify({type:"messaggio_crea_bookmark", to: id_utente, clientId: websocket_clientId}))}>
      Send Crea bookmark
    </button>
    {websocket_clientId && <p>Your client ID: {websocket_clientId}</p>}
    {lastMessage ? <p>Last message: {lastMessage.data}</p> : <p>No messages received yet</p>}
    <p>WebSocket status: {readyState}</p>

    <Nota_per_bookmark  video_id={'passaggio_finto'} item_array={item_array} 
						Video_id_selezionato={'passaggio_finto'} 
						edit_testo={true} />

  </>
  );
};

export default WebSocketComponent;