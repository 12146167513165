import React, { useState } from 'react';
import axios from "axios";
//import axios from "axios";
import { Dropdown, Button, Modal, Form, Row, Col } from 'react-bootstrap';

const Mail_richiesta_dati = (props) => {

    const [value, setValue] = useState();

    const handleSubmit1 = (e) => {
        alert('scrivi qualcosa')
    }


    function Invia_mail_richiesta_dati() {
        console.log('invia_mail_richiesta_dati');

        axios.post('/alunni/invia_mail_richiesta_dati/', props.alunno)

        axios.patch('/alunni/mail_richiesta_dati/' + props.alunno._id + '/' + '0')
            .then((res) => {
                console.log(res.data)
                //                SET_variab_aggiorna(JSON.stringify(res.data))
            }).catch((error) => {
                console.log(error)
            });
        var aux = props.variab_aggiorna
        props.SET_variab_aggiorna(!aux)

    }

    return (
        <Dropdown.Item onClick={Invia_mail_richiesta_dati} >Mail Richiesta Dati</Dropdown.Item>

    )
}
export default Mail_richiesta_dati;