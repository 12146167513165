import React, { useState } from 'react';
import '../App.css';
import axios from "axios";

//import axios from "axios";
import { Dropdown, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import Mail_richiesta_dati from "./Alunno_x1_mail_dati";
//import DatePicker from "react-datepicker";
import Alunno_Form_Base from "./Alunno_form_base";
import { AiOutlineMenuFold } from 'react-icons/ai';



const Nuovo_Alunno_Edit = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [end_Date, set_End_Date] = useState(new Date());


    function cancellazione_alunno() {
        //        axios.post('http://localhost:3001/create', newNote)
        axios.delete('/alunni/cancella_alunno/' + props.alunno._id)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });
        var aux = props.variab_aggiorna
        props.SET_variab_aggiorna(!aux)
    }

    return (
        <div class="d-flex flex-row-reverse">
            <Dropdown>
                <Dropdown.Toggle variant="Light" id="dropdown-basic">
                    Menu  <AiOutlineMenuFold />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Mail_richiesta_dati alunno={props.alunno} variab_aggiorna={props.variab_aggiorna} SET_variab_aggiorna={props.SET_variab_aggiorna} />
                    <div class="dropdown-divider"></div>
                    <Dropdown.Item onClick={handleShow} >Edit alunno</Dropdown.Item>
                    <Dropdown.Item onClick={cancellazione_alunno} >Cancella Alunno</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal
                show={show}
                
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Dialog>
                    <Modal.Title id="example-custom-modal-styling-title">
                    </Modal.Title>
                        <Alunno_Form_Base id={props.alunno._id} setShow={setShow} handleClose={handleClose} />
                </Modal.Dialog>
            </Modal>
        </div>
    )
}

export default Nuovo_Alunno_Edit;
