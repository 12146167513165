import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import JSONPretty from 'react-json-pretty';

function TriggerExample(props) {
  
 
  
  function Inserisci_tooltip() {
    //        console.log('IMMAGINE IMMAGINE')
    let stringa = JSON.stringify(props.item, null, 2) 
    stringa = "x1c1"
    return <Tooltip id="tooltip4">{stringa}</Tooltip>
  }


  return (
    <OverlayTrigger
      placement="left"
      delay={{ show: 250, hide: 400 }}
      overlay={Inserisci_tooltip()}
    >
      <Button variant="success">Debug2</Button>
    </OverlayTrigger>
  );
}

export default TriggerExample;