import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import JSONPretty from 'react-json-pretty';

function Off_canvas_debug(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Debug1
      </Button>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <JSONPretty data={props.item} />
            <JSONPretty data={props.input_opzioni} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default Off_canvas_debug;
