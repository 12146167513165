const synth = window.speechSynthesis;


const Sintesi_vocale_esterna = (item, tipo_chiamata) =>  {
    //    funzione_set_aggiornamento(true)
//        console.log('xxxxx Carica_domande_x_account')
let testo_da_leggere = 'indefinito'

if (tipo_chiamata == 1 && item.domanda !== undefined) testo_da_leggere = item.domanda
if (tipo_chiamata == 2 && item.risposta !== undefined) testo_da_leggere = item.risposta
console.log('///////  finestra spenta ' + item.domanda);
console.log('///////  finestra spenta ' + item.risposta);


testo_da_leggere = testo_da_leggere.replace(new RegExp('<p>', "g"), ".");
testo_da_leggere = testo_da_leggere.replace(new RegExp('<br>', "g"), ".");
testo_da_leggere = testo_da_leggere.replace(new RegExp('<ul>', "g"), ".");
testo_da_leggere = testo_da_leggere.replace(new RegExp('<b>', "g"), ".");

testo_da_leggere = testo_da_leggere.replace(new RegExp('</p>', "g"), "");
testo_da_leggere = testo_da_leggere.replace(new RegExp('</br>', "g"), "");
testo_da_leggere = testo_da_leggere.replace(new RegExp('</ul>', "g"), "");
testo_da_leggere = testo_da_leggere.replace(new RegExp('</b>', "g"), ".");



    if(window['speechSynthesis'] === undefined) {

    }

//        const synth = window.speechSynthesis;
    var utterance = new SpeechSynthesisUtterance(testo_da_leggere);
    utterance.voice = synth.getVoices()[9];
    
    synth.speak(utterance);
    
    let r = setInterval(() => {
        console.log('XXXXXXXXXXXXXX' + synth.speaking);
        if (!synth.speaking) {
            clearInterval(r);
        } else {
            console.log('XXXXXXXXXXXXXX resume' + synth.speaking);
            synth.pause();

            synth.resume();
        }
    }, 10000);

    utterance.addEventListener('pause', (e) => {
        console.log('pausa ');
    
    });
    utterance.addEventListener('error', (e) => {
        console.log('error ');
    
    });

    utterance.addEventListener('end', (e) => {
        console.log('finito ');

    });
    console.log('YYYYYYYYYYYYY ' )
}


exports.Sintesi_vocale_esterna = Sintesi_vocale_esterna;