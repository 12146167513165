import React, { useState, useEffect} from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import axios from 'axios';

const Gestione_assegnazione_classi = (props) => {
//    const [somma, calcola_somma] = useState('');

       //        console.log('!!!!!! CHIAMaTA  Aggiunta una classe')
        
      


 
    return (
        <div>
        </div>
    )
}

export default Gestione_assegnazione_classi;