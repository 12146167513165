import React, { useState, useEffect, useRef } from 'react';
//import './navbar.css';
import { Dropdown, DropdownButton, Label, Input, Button, Modal, Form } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import { BsFillFileEarmarkPlusFill } from "react-icons/bs";
import { AiFillMinusCircle,AiFillPlusCircle  } from "react-icons/ai";
import axios from 'axios'
import Dropdown_materie_new from '../compon_domande/X6_dropdown_materie_NEW';
import ringer from "./camera2.wav";
import ringer_jump from "./jump.mp3";
import ringer_quite from "./quite.mp3";
import ringer_crash from "./crash.mp3";
import ringer_scroll from "./scroll.mp3";
import ringer_wes from "./wes.mp3";
import ringer_invalid from "./invalid.mp3";

import Gestione_testo_per_numeri_random from "../compon_domande/X15_1_base_calcoli";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Edit_al_volo from "./Y0_edit_al_volo.jsx";
import JSONPretty from 'react-json-pretty';
import Riconoscimento_vocale from './X2_riconoscimento_vocale';
import microPhoneIcon from "./microphone.svg";

import Walkmind_play_spezzone_mp3_con_link from "./Y1_walkmind_mp3_con_link.jsx";
import Walkmind_play_spezzone_mp3_su_amazon from "./Y1_walkmind_mp3_con_amazon.jsx";
import Walkmind_play_spezzone_video_su_amazon from "./Y2_walkmind_video_con_amazon.jsx";
import Walkmind_play_spezzone_youtube_con_link from "./Y2_walkmind_video_con_youtube.jsx";
const synth = window.speechSynthesis;

function check_variabili_oggetto(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}


const Walkmind = (props) => {
//    const [somma, calcola_somma] = useState('');
const [show, setShow] = useState(false);
const [index, setindex] = useState(0);
const [numero_accensioni_microfono, SET_numero_accensioni_microfono] = useState(0);
const [indice_ultima_interazione, set_indice_ultima_interazione] = useState(0);
const [indice_variazione_difficolta_domanda, SET_indice_variazione_difficolta_domanda] = useState(0);

const [indice_chiamata_componenti, SET_indice_chiamata_componenti] = useState('nulla');
const [item, setitem] = useState({});

const [numero_errori, SET_numero_errori] = useState(0);
const [accensione_microfono, SET_accensione_microfono] = useState(false);
const [input_microfono, SET_input_microfono] = useState('');
const [isListening, setIsListening] = useState(false);
const [nota_bookmark_estratto, SET_nota_bookmark_estratto] = useState('');

const [isworking, SET_isworking] = useState(false);
const [input, setInput] = useState({
    domanda: '',
    risposta: ''
});

let indice_globale = 0
const finestra_aperta = useRef(false);
const Edit_domanda_risposta_ref = useRef(false);
// questi due servono per il bookmark

const audio_ring = new Audio(ringer);
const audio_jump = new Audio(ringer_jump);
const audio_quite = new Audio(ringer_quite);
const audio_crash = new Audio(ringer_crash);
const audio_scroll = new Audio(ringer_scroll);
const audio_wes = new Audio(ringer_wes);
const audio_invalid = new Audio(ringer_invalid);

//console.log('XXXXXXXXXXXXXXXXXXX')
useEffect(() => {
  //  console.log('use effctt')
  // tastiera
    if (finestra_aperta.current == true && !Edit_domanda_risposta_ref.current) {

        if (input_microfono == 'facile' ) {
            console.log('XXXXS')
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
                SET_indice_variazione_difficolta_domanda(5)
            })
        }
        if (input_microfono == 'facilissimo' ) {
            console.log('XXXXS')
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
                SET_indice_variazione_difficolta_domanda(15)
          
            })

        }
        if ( input_microfono == 'difficile') {
            console.log('XXXXS')
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
                SET_indice_variazione_difficolta_domanda(-5)

            })

        }
        if ( input_microfono == 'sciocchezza') {
            console.log('XXXXS')
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
                SET_indice_variazione_difficolta_domanda(50)
            
            })
        }
        if ( input_microfono == 'elimina') {
            console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXS')
            Inserisci_cartella_speciale_hashtag_migliorato ()
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
              
            
            })
        }

        if ( input_microfono == 'correggere') {
            console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXS')
            Metti_bookmark_in_bug ()
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
            })
        }



        if ( input_microfono == 'errore') {
            console.log('XXXXS')
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
                Inserisci_cartella_speciale ('bug')
            
            })

        }        
        if ( input_microfono == 'cestino') {
            console.log('XXXXS')
            audio_wes.play()
            audio_wes.addEventListener('ended', (e) => {
                Inserisci_cartella_speciale ('cestino')
            
            })

        }

        SET_input_microfono('')
   

        window.addEventListener('keypress', e => {
//        console.log(e.key)
        if (e.key === '1' ) {
            e.stopImmediatePropagation();
            regola_la_difficolta_della_domanda(5)
            console.log('ssssssssssssssssssssssssssssssssss' + e.key)
            audio_wes.play()
       }
        if (e.key === 'z' || e.key === 'Z' ) {
            e.stopImmediatePropagation();
            regola_la_difficolta_della_domanda(-5)
            console.log('ssssssssssssssssssssssssssssssssss' + e.key)
            audio_wes.play()
        }
        if (e.key === '3') {
            Inserisci_cartella_speciale ('bug')
            e.stopImmediatePropagation();
            console.log('ssssssssssssssssssssssssssssssssss' + e.key)
            audio_wes.play()
        }        
        if (e.key === '5' ) {
            e.stopImmediatePropagation();
            Inserisci_cartella_speciale ('cestino')
            console.log('ssssssssssssssssssssssssssssssssss' + e.key)
            audio_wes.play()
        }
        });
    }
  }, [index,input_microfono]);



useEffect(() => {
    console.log('mostra finestra')
    setShow(props.show_finestra)
}, [props.show_finestra]);



useEffect(() => {
    console.log('HHHH ' + indice_chiamata_componenti)
   
    if (indice_chiamata_componenti == 'chiamata_di_sintesi_1') {
        //        prova_a_mandare_in_play_un_bookmark(item)
        Sintesivocale (item, 1)
    }
    if (indice_chiamata_componenti == 'fine_sintesi_vocale_1') {
        //        prova_a_mandare_in_play_un_bookmark(item)
        Sintesivocale (item, 3)
    }

    if (indice_chiamata_componenti == 'fine_sintesi_vocale_nota') {
        //        prova_a_mandare_in_play_un_bookmark(item)
        funzione_play_audio_domanda (item)
    }      
    if (indice_chiamata_componenti == 'fine_play_mp3_domanda') {
        //        prova_a_mandare_in_play_un_bookmark(item)
        intervallo_pausa(item)
    }      
    if (indice_chiamata_componenti == 'Fine_intervallo_pausa') {
        //        prova_a_mandare_in_play_un_bookmark(item)
        Sintesivocale (item, 2)
    }      
    if (indice_chiamata_componenti == 'fine_sintesi_vocale_2') {
        //        prova_a_mandare_in_play_un_bookmark(item)
        funzione_play_audio_risposta(item)
    }      
//    Fine_play_file_mp3_risposta
//    fine play del pezzo mp3
    if (indice_chiamata_componenti == 'fine play del pezzo mp3 amazon') {
        
    }
    if (indice_chiamata_componenti == 'fine play del pezzo youtube') {
        
    }
    if (indice_chiamata_componenti == 'fine play del pezzo VIDEO amazon') {
        archiviazione_domanda_partenza_microfono()
    }
    if (indice_chiamata_componenti == 'spegnimento microfono') {
        regola_la_difficolta_della_domanda ()
    }
}, [indice_chiamata_componenti]);



const Chiusura_della_finestra_modale = () => {
    console.log('Chiusura finestra modale')
    setShow(false);
    SET_isworking (true)
    finestra_aperta.current = false;
    props.SET_cambio_stato(current => !current)
}


const apertura_finestra = () => {
    setShow(true)
    if (!props.input_opzioni.solo_mp3) {
        let updatedValue = {};
        updatedValue = {"solo_mp3":true};
        props.setinput_opzioni(prevInput => ({
        
            ...prevInput,
            ...updatedValue
        }))
        props.SET_cambio_stato(current => !current)
    }
}


const pulsante_play = e => {
//    e.stopImmediatePropagation();
    if (Edit_domanda_risposta_ref.current) {
        Edit_domanda_risposta_ref.current = false;
        if (index > 0) {indice_globale = index}
        axios.patch('/domande/modifica/' + item._id, input)
        .then((res) => {
            console.log(res.data)
      
        }).catch((error) => {
            console.log(error)
        });

    }

    if (!isworking) {
        console.log('SALVATAGGIO')
    }
    SET_isworking(true)
    setShow(true)
    finestra_aperta.current = true;
    console.log(props.lista_domande_render.length)
    console.log('Apertura_finestra_modale')
    console.log('@@@@@@ Play_in_loop');
            
    Play_in_loop()
}


// *****************************************************************************************************************************
const Play_in_loop = () => {
//  arrivato al termine della lista
//console.log('FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
// console.log('index' + index)
// console.log('props.lista_domande_render.length' + props.lista_domande_render.length)
    SET_indice_variazione_difficolta_domanda(0)

    if (index == props.lista_domande_render.length) {

    }

    if (index < props.lista_domande_render.length) {
        console.log('finestra_aperta ' + finestra_aperta.current)
        console.log('show ' + show)
        inizio_domanda_pausa(props.lista_domande_render[index])
        setitem(props.lista_domande_render[index])
        setindex(current => current + 1)

    }
    if (indice_globale === 0) {

    }
}

function inizio_domanda_pausa(item) {

    let millisecondi = 3000
    let btn 
    btn = check_variabili_oggetto (item.indirizzo_S3_mp3_domanda, 'inizio_pausa')
    if (!isNaN(btn)) {
        millisecondi = item.indirizzo_S3_mp3_risposta.intervallo_pausa
    }
    if (item.materia_id === undefined) 
    {
        console.log('audio.duration' + audio_ring.duration)
        audio_invalid.volume = 0.1  
        audio_invalid.play()
    }
    setTimeout(() => {
        
        SET_indice_chiamata_componenti('chiamata_di_sintesi_1')
    }, millisecondi)
}


const funzione_play_audio_domanda = (item) => {
    console.log('funzione_play_audio')
    
    let btn 
    btn = check_variabili_oggetto (item.indirizzo_S3_mp3_domanda, 'key')
    // console.log('btn1 ' + btn);
    if (btn !== '') {
        axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + btn)
        .then((res) => {
            console.log('url appena fecciate da mongo')

            let audio = new Audio(res.data)
    
            audio.addEventListener('loadedmetadata', (e) => {
                console.log('tempo domanda ' +e.target.duration);
            });
            if (finestra_aperta.current) audio.play()
            audio.addEventListener('ended', (e) => {
                console.log('domanda end');
                const timeout = setTimeout(() => {
                    if (finestra_aperta.current) {
                         SET_indice_chiamata_componenti('fine_play_mp3_domanda')
                    }
                         
                }, 6000);
            });
        }).catch((error) => {
            console.log(error)
            audio_crash.play()
            SET_numero_errori (prevState => prevState + 1)
            SET_indice_chiamata_componenti('fine_play_mp3_domanda')
            console.log('32')

        });
    } else 
    {
        console.log('3')

    }
    console.log(btn)
    if (btn == null) {
        SET_indice_chiamata_componenti('fine_play_mp3_domanda')
     
        console.log('4')
    }
}



const Sintesivocale = (item, tipo_chiamata) => {
    if (!show) 
    {
        console.log('///////  finestra spenta ');
        return;
    }
    let btn 
        
    btn = check_variabili_oggetto (item.indirizzo_S3_mp3_domanda, 'key')
    // console.log('///////  ' + btn);
     

//    console.log('/////// entrato nella sintesi vocale  ');
    
    let testo_da_leggere = ''
    if (tipo_chiamata == 1 && item.domanda !== undefined)  testo_da_leggere = item.domanda
    if (tipo_chiamata == 2 && item.risposta !== undefined) testo_da_leggere = item.risposta


    let bookmark_estratto = null
    if (tipo_chiamata == 3 && item.video_id != undefined) {
        bookmark_estratto = item.video_id.elenco_bookmark.filter(row => row._id === item.video_id_indice).pop();
        console.log(bookmark_estratto)
        if (bookmark_estratto != undefined && bookmark_estratto.nota != undefined)  { 
            testo_da_leggere = bookmark_estratto.nota
            SET_nota_bookmark_estratto(bookmark_estratto.nota) 
        }
    }

    if (item.domanda.includes('#no_speach')) testo_da_leggere = 'Non leggibile'
    console.log('//////' + testo_da_leggere);
    

    if (!(btn === '' || btn === null || btn === undefined) || testo_da_leggere.includes('...')) {
        if (tipo_chiamata == 1) {
            SET_indice_chiamata_componenti('fine_sintesi_vocale_1')
            return;
        }
        if (tipo_chiamata == 2) {
               
            SET_indice_chiamata_componenti('fine_sintesi_vocale_2')
            return;
        } 
        if (tipo_chiamata == 3) {
               
            SET_indice_chiamata_componenti('fine_sintesi_vocale_nota')
            return;
        } 
//        console.log('//////RETURN ' + tipo_chiamata);
        
        return;
    }

    testo_da_leggere = testo_da_leggere.replace(new RegExp('<p>', "g"), ".");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('<br>', "g"), ".");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('<ul>', "g"), ".");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('<b>', "g"), ".");

    testo_da_leggere = testo_da_leggere.replace(new RegExp('</p>', "g"), "");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('</br>', "g"), "");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('</ul>', "g"), "");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('</b>', "g"), ".");
    testo_da_leggere = testo_da_leggere.replace(new RegExp('&emsp;', "g"), ".");

    btn = check_variabili_oggetto (item.indirizzo_S3_mp3_domanda, 'key')
//    console.log('////////=  ' + btn);

//    audio_quite.play()
    if(window['speechSynthesis'] === undefined) {

    }
//        const synth = window.speechSynthesis;

    var utterance = new SpeechSynthesisUtterance();
    var voices = window.speechSynthesis.getVoices();
    utterance.voice = voices[9]; // Note: some voices don't support altering params
    utterance.voiceURI = 'native';
    utterance.volume = 1; // 0 to 1
    utterance.rate = 1; // 0.1 to 10
    utterance.pitch = 0; //0 to 2
    utterance.text = testo_da_leggere
    utterance.lang = 'it-IT';

    window.speechSynthesis.speak(utterance);
      
    if (isBrowser) {
        let r = setInterval(() => {
//            console.log('XXbrowser' + isBrowser);
            if (!speechSynthesis.speaking) {
                clearInterval(r);
            } else {
//                console.log('XXXXXXXXXXXXXX resume' + speechSynthesis.speaking);
                speechSynthesis.pause();
                speechSynthesis.resume();
            }
        }, 10000);
    }

    // prima era 10000

    utterance.addEventListener('pause', (e) => {
//        console.log('pausa ');
    });
    utterance.addEventListener('error', (e) => {
//        console.log('error ');
    });
    utterance.addEventListener('end', (e) => {
//        console.log('finito ');
        const timeout = setTimeout(() => {
            if (tipo_chiamata == 1) {
                if (finestra_aperta.current ) 
                SET_indice_chiamata_componenti('fine_sintesi_vocale_1')
            }
            if (tipo_chiamata == 2) {
                SET_indice_chiamata_componenti('fine_sintesi_vocale_2')
            }    
            if (tipo_chiamata == 3) {
                SET_indice_chiamata_componenti('fine_sintesi_vocale_nota')
            }    
        }, 6000);
    });
//    console.log('YYYYYYYYYYYYY ' )
}




function intervallo_pausa(item) {
//    console.log(nuovi_indici)
    let millisecondi = 5000
    let btn 
    btn = check_variabili_oggetto (item.indirizzo_S3_mp3_risposta, 'intervallo_pausa')
    if (!isNaN(btn)) {
        millisecondi = item.indirizzo_S3_mp3_risposta.intervallo_pausa
    }
    setTimeout(() => {
        SET_input_microfono('nulla')
        SET_indice_chiamata_componenti('Fine_intervallo_pausa')
//       console.log("Delayed for 1 second.");
    }, millisecondi)
}


function funzione_play_audio_risposta(item) {
//    console.log('funzione_play_audio')
    
    let btn 
    btn = check_variabili_oggetto (item.indirizzo_S3_mp3_domanda, 'key')
//    console.log('btn1 ' + btn);
    if (btn !== undefined) {
        axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + item.indirizzo_S3_mp3_risposta.key)
        .then((res) => {
//            console.log('url appena fecciate da mongo')
            //                console.log(res.data)
//            console.log(res.data)
//          correggi veloce
            let audio = new Audio(res.data)
//            let audio = new Audio(ringer_crash)

            audio.addEventListener('loadedmetadata', (e) => {
//                console.log('tempo risposta ' + e.target.duration);
            });
//            console.log(item.indirizzo_S3_mp3_risposta.key)
            if (finestra_aperta.current) audio.play()
            audio.addEventListener('ended', (e) => {
                SET_indice_chiamata_componenti('Fine_play_file_mp3_risposta')
         
            });
        }).catch((error) => {
            console.log(error)
            audio_crash.play()
            SET_numero_errori (prevState => prevState + 1)

        });
    }
    if (btn == null) {
        SET_indice_chiamata_componenti('Fine_play_file_mp3_risposta')
     
//        console.log('4')
    }
}



function archiviazione_domanda_partenza_microfono() { 
    indice_globale = indice_globale + 1
//    console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Play_in_loop');

//    console.log('isworking' + isworking)
    if (finestra_aperta.current && !Edit_domanda_risposta_ref.current) {
//        console.log('@@@@@@ Play_in_loop');
        SET_accensione_microfono (true)

        setTimeout(() => {
            SET_accensione_microfono (false)
            SET_indice_chiamata_componenti('spegnimento microfono')
        }, 12500)
    }
}


function regola_la_difficolta_della_domanda () {
//    console.log("documento che viene modificato: " + item_old._id)
    set_indice_ultima_interazione(index)
    let indice_end = item.indice_end
    indice_end += parseInt(indice_variazione_difficolta_domanda)
    if (indice_end < 1) {
        indice_end = 1
    }
    const nuovi_indici = {
        indice_end: indice_end,
        indice_aux: -indice_end,
        data_ultimo_ascolto: Date()

    }
//    console.log(nuovi_indici)
    axios.patch('/domande/modifica_indici/' + item._id, nuovi_indici)
        .then((res) => {
//            console.log(res.data)
        }).catch((error) => {
//            console.log(error)
        });
    axios.patch('/domande/aumenta_indici/' + item.utente)
        .then((res) => {
//            console.log(res.data)
        }).catch((error) => {
//            console.log(error)
        });
        audio_jump.volume = 0.1       
//        audio_jump.play()

    Play_in_loop()

}



function Inserisci_cartella_speciale (parametro) {
    const param = {
        parametro: parametro
    }
    axios.patch('/domande/sposta_in_cartella_speciale/' + item._id, param)
        .then((res) => {
//            console.log(res.data)
        }).catch((error) => {
//            console.log(error)
        });
audio_ring.volume = 0.1  
audio_ring.play()
}


function Inserisci_cartella_speciale_hashtag_migliorato () {
    
    //    console.log (item_old)
        let input = {non_walkmind: true}
       
        axios.patch('/domande/modifica_walkmind_boolean/' + item._id, input)
        .then((res) => {
            console.log(res.data)
            props.SET_cambio_stato(current => !current)
    
        }).catch((error) => {
            console.log(error)
        });
    }
    


function Metti_bookmark_in_bug (parametro) {
    
//    console.log (item_old)
    let input = {}

    axios.patch('/video/metti_bookmark_in_bug/' + item.video_id_indice + '/' + 'true')
    .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });
}


function colorazione(indice) {
    var colore = 'black'
    var colore_bordo = 'solid red'
    colore_bordo = '3px solid ' + 'red'

    var hStyle 
    hStyle = { backgroundColor: 'white', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
/*    
    if (input_microfono == 'microfono_acceso') {
        hStyle = { backgroundColor: 'fuchsia', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
    }
*/        
    if (input_microfono == 'facile') {
        hStyle = { backgroundColor: '#d1fbb6', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
    }
    if (input_microfono == 'facilissimo') {
        hStyle = { backgroundColor: '#b1faa7', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
    }
    if (input_microfono == 'difficile') {
        hStyle = { backgroundColor: '#f7a2a2', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
    }

    if (isListening == true) {
        hStyle = { backgroundColor: '#ebeb0e', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
    }
    return hStyle;
}


    return (
    <>

        <div style={colorazione(1)}>
        
        <div className="d-flex flex-row-reverse" > 
        <Dropdown_materie_new item={item} lista_materie={props.lista_materie} 
                                                SET_lista_domande_render={props.SET_lista_domande_render} 
                                                lista_domande_render= {props.lista_domande_render} 
                                                index={index} /> 

        {item !== null &&
        <Button className="p-2 bg-info flex-fill" 
                onClick={() => Inserisci_cartella_speciale_hashtag_migliorato('not_speach')}>
                Not for speech
        </Button>
        
        }

        <Button className="p-2 bg-info flex-fill" 
                onClick={() => {Edit_domanda_risposta_ref.current = true; SET_isworking(false)} }>
                Edit
        </Button>
        
        </div>
        <h4>
        <ul>
           <li> Nota <b> {nota_bookmark_estratto} </b></li>
           <li> Indice aux {item.indice_aux} - Indice end {item.indice_end} </li>
           <li> Ultimo ascolto {item.data_ultimo_ascolto}  </li>
    
        </ul>
        </h4>
        <Riconoscimento_vocale accensione_microfono = {accensione_microfono} 
                                SET_accensione_microfono = {SET_accensione_microfono}
                                isListening = {isListening}
                                setIsListening = {setIsListening}
                                SET_input_microfono = {SET_input_microfono}
                                SET_numero_accensioni_microfono = {SET_numero_accensioni_microfono}/>
        

        <Walkmind_play_spezzone_mp3_con_link item = {item} 
                                                indice_chiamata_componenti = {indice_chiamata_componenti}
                                                SET_indice_chiamata_componenti = {SET_indice_chiamata_componenti}
                                                show={show} />
        <Walkmind_play_spezzone_mp3_su_amazon item = {item} 
                                                indice_chiamata_componenti = {indice_chiamata_componenti}
                                                SET_indice_chiamata_componenti = {SET_indice_chiamata_componenti}
                                                show={show} />                
        <Walkmind_play_spezzone_video_su_amazon item = {item} 
                                                indice_chiamata_componenti = {indice_chiamata_componenti}
                                                SET_indice_chiamata_componenti = {SET_indice_chiamata_componenti}
                                                show={show} />     
        <Walkmind_play_spezzone_youtube_con_link item = {item} 
                                                indice_chiamata_componenti = {indice_chiamata_componenti}
                                                SET_indice_chiamata_componenti = {SET_indice_chiamata_componenti}
                                                show={show} />     
                                                           
                
                    {/* 

                */}
 

        <div className="d-flex justify-content-center">
        
        
        <Button size="lg" onClick={pulsante_play} disabled={isworking} variant={accensione_microfono? "success"  : "secondary"} >
                <img src={microPhoneIcon} /> Play
                </Button>

        </div>
        <div className="d-flex">
            <Button className="p-2 bg-info flex-fill" 
                    onClick={() => regola_la_difficolta_della_domanda(-5)}>
            <AiFillMinusCircle />
            </Button>
        
            <Button className="p-2 bg-info flex-fill" 
                        onClick={() => regola_la_difficolta_della_domanda(5)}>
                <AiFillPlusCircle />
            </Button>

        </div>
        <div className="d-flex">
            
            <Button className="p-2 bg-info flex-fill" 
            onClick={() => Inserisci_cartella_speciale('cestino')}>
            Cestino
            </Button>
            <Button className="p-2 bg-info flex-fill" 
            onClick={() => Inserisci_cartella_speciale('bug')}>
            Bug
            </Button>
            <Button className="p-2 bg-info flex-fill" 
            onClick={() => Metti_bookmark_in_bug('bug')}>
            Bug Per bookmark
            </Button>

        </div>    
      

        
        <ul>
        <li> COMPONENTI {indice_chiamata_componenti}</li>
        <li> id <b> {item._id} </b></li>
    
        <li> Domanda <b> {item.domanda} </b></li>

        <Gestione_testo_per_numeri_random stringa_passata={item.domanda} item={item} 
                                                lista_variabili={item.variabili_calcoli} />

        {Edit_domanda_risposta_ref.current && <Edit_al_volo item={item} SET_isworking={SET_isworking} input={input} setInput={setInput}/>}                                         
        
        <li> Risposta: <b> {item.risposta} </b></li>
        <li> Nota <b> {nota_bookmark_estratto} </b></li>
        <li> Cestino: <b>{item.cestino} </b></li>

        {/*
        <li> {item.indirizzo_S3_mp3_domanda ? 
            <> {item.indirizzo_S3_mp3_domanda.key} </> : null} </li>
        <li> {item.indirizzo_S3_mp3_risposta ? 
            <> {item.indirizzo_S3_mp3_risposta.key} </> : null} </li>
        */}

        <li>Numero_errori: {numero_errori}</li>
        <li>Indice domanda: {index} / {props.lista_domande_render.length} </li>
        <li>Ultima interazione: {indice_ultima_interazione}  </li>
        <li>numero_accensioni_microfono: {numero_accensioni_microfono}</li>

        {/* <li>isworking: {isworking ? <> true </> : <> false </>} </li> */}
        {/*  <li> <JSONPretty data={props.lista_domande_render} /> </li>  */ }        
        </ul>
    
        </div>

    
         <div className="d-flex flex-row-reverse">
 
         <Button onClick={() => apertura_finestra()}  
                 disabled={!show ? false : true}>
             Walkmind  <BsFillFileEarmarkPlusFill />
         </Button>
    


         </div>
    </>
    )
}
export default Walkmind;