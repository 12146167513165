import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Gestione_Materiale_Multimediale from './Video_x0_0';


const Link_gestione_classi = (props) => {
    const [video_selezionato, set_video_selezionato] = useState('');
    const { user } = useAuth0();
    const [lista_video, SET_lista_video] = useState([]);

    useEffect(() => {
        console.log('USEEFFECT')
    }, []);

    return (
        <div>
            <Gestione_Materiale_Multimediale lista_video={lista_video} SET_lista_video={SET_lista_video} 
                             account_dati={props.account_dati} set_video_selezionato={set_video_selezionato} />
            
        </div>
    )
}
export default Link_gestione_classi;

