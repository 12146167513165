import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import Walkmind from "./Y0_walkmind";
import JSONPretty from 'react-json-pretty';



const Lista_mp3 = (props) => {
//    const [somma, calcola_somma] = useState('')
const [show_alert, setShow_alert] = useState(false);
const { user, isAuthenticated } = useAuth0();
const id_utente = useSelector(state => state.variabile.value)
const [lista_domande_render, SET_lista_domande_render] = useState([]);
const [cambio_stato, SET_cambio_stato] = useState(false);
const [lista_domande, SET_lista_domande] = useState([{ indice_aux: 0 }]);
const [lista_materie, SET_lista_materie] = useState([{ materie: 'aux' }]);

const [max_indice_aux, SET_max_indice_aux] = useState(1); 


useEffect(() => {
//    console.log('-X0--- USEEFFECT -----')
    if (isAuthenticated) {
        console.log('CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC NUOVA LISTA')
//        console.log('-X0------- isAuthenticated ----------')
        setShow_alert(true)

        Carica_materie_x_account()
    }
}, [cambio_stato, id_utente, props.input_opzioni]);


const Carica_materie_x_account = () => {
    //    funzione_set_aggiornamento(true)
//    console.log('#### Carica_materie MP3_x_account')
//    console.log('id_utente' + id_utente)
    axios.get('/materie/carica_tutte/' + id_utente)
        .then((res) => {
            SET_lista_materie(res.data)
            Carica_mp3_x_account(res.data)
        }).catch((error) => {
            console.log(error)
        });
}


const Carica_mp3_x_account = (lista_materie_caricate_da_mongo) => {
    //    funzione_set_aggiornamento(true)
//        console.log('xxxxx Carica_domande_x_account')
//    console.log(ordine_visualizzazione)
//    console.log('  props.input_opzioni ' +  props.input_opzioni)
//    console.log('#### Carica_ MP3_x_account')

//        console.log(id_utente)
    let end_date = 5
    //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}
    axios.get('/domande/carica_tutte_migliorato/', {
        params: {
            id_utente: id_utente,
            ordine_visualizzazione: props.input_opzioni.ordine_visualizzazione,
            solo_mp3: props.input_opzioni.solo_mp3, 
            show_nomat: props.input_opzioni.show_nomat,
            scelta_query: props.input_opzioni.scelta_query,
            
            scelta_non_walkmind: props.input_opzioni.scelta_non_walkmind,
            show_materie_tutte: props.input_opzioni.show_materie_tutte,
            number_max: props.input_opzioni.number_max,
            testo_ricerca: props.input_opzioni.testo_ricerca, 
            lista_materie: JSON.stringify(lista_materie_caricate_da_mongo)
        }
    }).then((res) => {
        SET_max_indice_aux(lista_domande[0].indice_aux)
        if (Array.isArray(res.data)) {
//                   console.log('lista caricata mp3 ' + res.data.length)
            }

           crea_lista_stornati(res.data)
        }).catch((error) => {
            console.log(error)
        });
}


const crea_lista_stornati = (lista_domande_caricate_da_mongo) => {
    var lista_play_aux = []

    lista_domande_caricate_da_mongo.map(function (item, index) {
//            console.log(item.variabili_calcoli)
//            if (item.variabili_calcoli.length>10) {

        item.variabili_calcoli.map(function (item2_var, index2) {
//                console.log(item2_var)
//                    console.log("ZZZZZZZZZZZZZZZZZZZsono uguali");
                let numero = item2_var.minimo + item2_var.passo *
                                Math.floor(Math.random() * (item2_var.massimo-item2_var.minimo)/item2_var.passo)
//                    console.log(numero);
                item.variabili_calcoli[index2].valore_attuale = numero;
            })
//            }
    });
//        console.log(lista_play_aux)
    SET_lista_domande_render(lista_domande_caricate_da_mongo)
//    console.log(lista_domande_caricate_da_mongo)
}
;
    return (
    <>
            
        <Walkmind   lista_domande_render={lista_domande_render} 
                    lista_materie={lista_materie} 
                    SET_lista_domande_render={SET_lista_domande_render} 
                    input_opzioni = {props.input_opzioni}
                    SET_input_opzioni = {props.SET_input_opzioni}
                    SET_cambio_stato = {SET_cambio_stato} 
                    show_finestra = {true}
                    />
        {(props.account_dati.utente_debug) &&
                            <JSONPretty data={user} />
        }
        {(props.account_dati.utente_debug) &&
            <>
            ID UTENTE    {id_utente}
            </>
        }
        {(props.account_dati.utente_debug) &&
                            <JSONPretty data={lista_domande_render} />
        }
    
    </>

    )
}
export default Lista_mp3;