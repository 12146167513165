
import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import { ProgressBar} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


const Gestione_progress_bar = (props) => {

    const id_utente = useSelector(state => state.variabile.value)


 

    function Mostra_progress_bar() {
        let barra = -100*props.item.indice_aux/props.item.indice_end
//        console.log (barra)
        if (barra > 0) {
            return <div>
                           <ProgressBar variant="success" now={barra} />
            </div>
        }
        if (barra < 0) {
            return <div>
                           <ProgressBar variant="danger" now={-1*barra} />
            </div>

        }
    }

  


    return (
        <div>
             {Mostra_progress_bar()}
  
        </div>
    )
}

export default Gestione_progress_bar;