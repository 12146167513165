import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from "axios";
import { ToggleButton, ButtonGroup, Button, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';
//import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import Nuovo_Programma_Edit from "./011_Programma_x0_Edit";
import JSONPretty from 'react-json-pretty';


const Gestione_Programmi = (props) => {
    const [input, setInput] = useState({
        nome: ''
    });
    const [lista_programmi, SET_lista_programmi] = useState([]);
    const [variab_aggiorna, SET_variab_aggiorna] = useState(false);
    const id_utente = useSelector(state => state.variabile.value)
    const { user, isAuthenticated } = useAuth0();

    const [radioValue, setRadioValue] = useState('1');

    

    useEffect(() => {
        Carica_programmi_x_account()
    }, [variab_aggiorna,id_utente]);



    const Carica_programmi_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_Programmi_x_account')
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}

        axios.get('/programmi/programmi_carica_tutti/' + id_utente)
            .then((res) => {
                console.log('PROGRAMMI appena fecciate da mongo')
                console.log(res.data)
                SET_lista_programmi(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }



    function handleChange(event) {
        const { name, value } = event.target;

        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    function handleClick(event) {
        event.preventDefault();
        const nuovo_programma = {
            nome: input.nome,
            utente: id_utente
        }

        console.log(input);
        axios.post('/programmi/salva_nuovo', nuovo_programma)
        let aux = variab_aggiorna
        SET_variab_aggiorna(!aux)
    }


    const Scelta_programma=(e)=>{
        console.log(e.target.value)
        props.SET_programma_selezionato(e.target.value)
    }


    function Scelta_programma2 (event) {


        console.log("ssssssssssssssssssssssssssssssssssss")

        console.log(event.target.value)
        if (event.target.value !== undefined) {
            props.SET_programma_selezionato(event.target.value)
        }
    }



    return (
        <div>
            <div className='container'>
                <h1>Inserisci nuovo Programma </h1>
                <form>
                    <div className='form-group'>
                        <input onChange={handleChange} name="nome" placeholder="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                    </div>

                    <div class="d-flex flex-row-reverse">
                        <button onClick={handleClick} className="btn btn-lg btn-info">Salva</button>
                    </div>
                </form>
            </div>

            <div className='container'>

                <ListGroup>
                    {lista_programmi.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <div>
                                <Row>
                                    <Nuovo_Programma_Edit item={item} variab_aggiorna={variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}/>

                                </Row>
                                <Row>
                                    <Col>
                                        <Row><p>programma: <strong>{item.nome}</strong></p></Row>
                                    </Col>
                                </Row>
                                <Row>

                                    {(props.account_dati.utente_debug) &&
                                    <JSONPretty data={item} />
                                    }

                                </Row>
                                <hr class="rounded"></hr>
                            </div>
                        }
                    })
                    }
                </ListGroup>
                <div class="d-flex flex-column">
          

                <ButtonGroup onClick={Scelta_programma2}>
                    {lista_programmi.map(function (item, idx) {
                        {
                            //                        console.log(item)
                            return <>
                                 <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                                    name="radio"
                                    value={item._id}
                                    checked={item._id === props.programma_selezionato}
                                    >
                                    {item.nome}
                                </ToggleButton>
                            </>
                        }
                    })
                    }
                </ButtonGroup>
                </div>
            </div>
        </div >
    )
}
export default Gestione_Programmi;