import React, { useState } from 'react';
//import ReactTooltip from 'react-tooltip';
import { ListGroup, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios'
import '../App.css';



const Mostra_link_per_studiare = (props) => {
    const [somma, calcola_somma] = useState('');

    const handleClose = () => props.setShow_link(false);
    const handleShow = () => props.setShow_link(true);

    const [input, setInput] = useState({
        nome: "",
        link: ""
    });


    const cancella_link = (id_array) => {
        console.log('sdfd')
        axios.patch('/domande/cancella_link/' + props.item._id + '/' + id_array)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log('errore')

                console.log(error)
            });
        //        props.SET_variab_aggiorna(!aux)
        var aux = props.cambio_stato
        props.SET_cambio_stato(!aux)
    }

    return (
        <>
            <ListGroup variant="flush">
                
                {Array.isArray(props.item.elenco_link) && <>              
                    {props.item.elenco_link.map(function (item2, index2) {
                        //                                        var aux = ottieni_url(item2.indirizzo_S3)
                        //                        console.log(item2)
                        {
                            return <ListGroup.Item className="d-flex justify-content-between">
                                <a href={item2.link} target='_blank'> {item2.testo}</a>
                                <Button variant="outline-primary" onClick={() => cancella_link(item2._id)}>Cancella</Button>{' '}
                                </ListGroup.Item>
                        }
                    })
                    }

                </>
                }
            </ListGroup>
        </>
    )
}

export default Mostra_link_per_studiare;
