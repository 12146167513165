import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import {Tab, Tabs, Button, Modal, Form, Table } from 'react-bootstrap';
import Risposta_singola from "./X15_4_risposta_singola.jsx";

 
 
const Risposte_multiple = (props) => {
//    const [somma, calcola_somma] = useState('');
const [key, setKey] = useState('home');
const input_default= {risposta: ''}


    return (
        <div>
		<Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            >

                {props.lista_risposte_multiple.map(function (risposta_singola, index2) {
                        {
                            
                            return (
                                <Tab
                                key={index2}
                                eventKey={index2}
                                title={index2}>
                                    <Risposta_singola   indice={index2} 
                                                        risposta_singola={risposta_singola} 
                                                        domanda={props.domanda} salva = {'aggiorna'}
                                                        lista_risposte_multiple = {props.lista_risposte_multiple}
                                                        set_lista_risposte_multiple ={props.set_lista_risposte_multiple}
                                                        set_cambio_stato1 = {props.set_cambio_stato1}
                                                        
                                                        setKey = {setKey}
                                                        account_dati={props.account_dati} 
                                                        /> 
                                </Tab>
                            );
                        }
                    })
                }
              
            {console.log('E- un array? ' + Array.isArray(props.lista_risposte_multiple))}
         
      

                <Tab eventKey={props.lista_risposte_multiple.length} title="Nuova">
                    <Risposta_singola   key={props.lista_risposte_multiple.length} 
                                        risposta_singola={input_default} 
                                        domanda={props.domanda} 
                                        salva = {'nuova'}
                                        set_cambio_stato1 = {props.set_cambio_stato1}
                                        lista_risposte_multiple = {props.lista_risposte_multiple}
                                        set_lista_risposte_multiple ={props.set_lista_risposte_multiple}
                                        setKey = {setKey}
                                        account_dati={props.account_dati}
                                        /> 
                </Tab>
                <Tab eventKey={0} title="Nuova">
                    <Risposta_singola   key={0} 
                                        risposta_singola={input_default} 
                                        domanda={props.domanda} 
                                        salva = {'nuova'}
                                        set_cambio_stato1 = {props.set_cambio_stato1}
                                        lista_risposte_multiple = {[]}
                                        set_lista_risposte_multiple ={props.set_lista_risposte_multiple}
                                        setKey = {setKey}
                                        account_dati={props.account_dati}
                                        /> 
                </Tab>
            
            </Tabs>

            {key}
        </div>
    )
}
export default Risposte_multiple;