

import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
const { Sintesi_vocale_esterna } = require('../funzioni_comuni_sintesi_vocale')

const Keyboard_input = (props) => {
//    const [somma, calcola_somma] = useState('');
const [trigger_per_keyboard, SET_trigger_per_keyboard] = useState(0);


const handleKeyDown = (event) => {
    event.stopImmediatePropagation();
//        imposta_bookmark ()
    if(event.ctrlKey && event.keyCode == 37){
        SET_trigger_per_keyboard(37)
        console.log('keyboard 1')
        Sintesi_vocale_esterna (props.item, 1)
    }
    if(event.ctrlKey && event.keyCode == 39){
        SET_trigger_per_keyboard(39)
        console.log('keyboard 2')
        Sintesi_vocale_esterna (props.item, 2)
    }
    if(event.shiftKey && event.keyCode == 37){
        SET_trigger_per_keyboard(137)
        console.log('keyboard 3')

    }
    if(event.shiftKey && event.keyCode == 39){
        SET_trigger_per_keyboard(139)
        console.log('keyboard 4')

    }
};


useEffect(() => {
//        audio_c1.play()
    window.addEventListener('keydown', handleKeyDown);

    // cleanup this component
    return () => {
    window.removeEventListener('keydown', handleKeyDown);
    };
}, [props.item]);



    return (
    <div>ciao2</div>
    )
}
export default Keyboard_input;