import React, { useState } from 'react';
//import ReactTooltip from 'react-tooltip';
import { ListGroup, Col, Row, Modal, Form, Button, ButtonGroup} from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';

import 'bootstrap/dist/css/bootstrap.min.css';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios'
import '../App.css';
import {Link} from 'react-router-dom';
import { useSelector, useDispatch} from 'react-redux'
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import parse from 'html-react-parser';
const { aggiungi_classi_non_spalmate_array } = require('../funzioni_comuni_array_classi')




function make_codice_alfanumerico(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}


const Mostra_form_esercizio = (props) => {
    const [lista_classi, SET_lista_classi] = useState([]);
    const [classe_selezionata, set_classe_selezionata] = useState('');
    const id_utente = useSelector(state => state.variabile.value)
    const [lista_alunni, SET_lista_alunni] = useState([]);

    const handleShow = () => {
        console.log('mostra ')
        Carica_classi_x_account()
    }

    const Chiusura_della_finestra_modale = () => {
        props.setShow_esercizio(false);
    } 

    const handleClose = () => {
    }
    const handleClick = () => {
    }

    const createPDF2 = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await document.querySelector("#pdf6");
        pdf.html(data).then(() => {
          pdf.save("shipping_label.pdf");
        });
    };
    
    const createPDF = async (tipo) => {   
        const pdf = new jsPDF("portrait", "pt", "a4"); 
        const data = await html2canvas(document.querySelector("#pdf5"));
        
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
    console.log(tipo)
    let pdfWidth, pdfHeight

    if (tipo === 1) {        

    console.log('entrato 1')
        pdfWidth = pdf.internal.pageSize.getWidth() ;
        pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    }
    if (tipo === 2) {        
        console.log('entrato 2')
   
        pdfHeight = pdf.internal.pageSize.getHeight() ;
        pdfWidth = (imgProperties.width * pdfHeight) / imgProperties.height;
    }
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("shipping_label.pdf");
    };
    

    const Carica_classi_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_classi_x_account')
        let end_date = 5

        axios.get('/classi/classi_carica_tutti/' + id_utente)
            .then((res) => {
                console.log('CLASSI appena fecciate da mongo')
                console.log(res.data)
                SET_lista_classi(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }

    const Scelta_classe=(e)=>{
        console.log(e.target.value)
        set_classe_selezionata(e.target.value)
        console.log(e.target.value)

        let classe_id = e.target.value
        axios.get('/alunni/alunni_carica_per_classe/' + id_utente + '/' + classe_id)
        .then((res) => {
            console.log('ALUNNI appena fecciate da mongo')
            console.log(res.data)
            SET_lista_alunni(res.data)
        }).catch((error) => {
            console.log(error)
        });
    }


    function spalma_domanda_alunni(event) {
        event.preventDefault()
        console.log('ciaone')
        let key_password_generata = make_codice_alfanumerico(10) 
        let key_8_cifre = make_codice_alfanumerico(8) 

        lista_alunni.map(function (item, index2) {
            {
                console.log(item._id)
                const nuovo_materiale = {
                    nome: 'prova',
                    utente: id_utente,
                    alunno: item._id,
                    domanda: props.domanda._id,
                    key_password: key_password_generata,
                    key_8_cifre: key_8_cifre,
                    key_tipo: 'domanda'
                }
//                console.log(nuovo_materiale)
                axios.post('/materiali/salva_nuovo', nuovo_materiale)
            }
        })
        

        let i = lista_classi.findIndex(e => e._id === classe_selezionata);
        let programma_della_classe = ''
        if (i !== -1) {
            programma_della_classe = lista_classi[i].programma._id
        }

        const nuovo_materiale = {
            nome: 'prova',
            utente: id_utente,
            programma: programma_della_classe,
            domanda: props.domanda._id,
            key_password: key_password_generata,
            key_tipo: 'domanda',
            key_matrice: 'programma'
        }
        axios.post('/materiali/salva_nuovo', nuovo_materiale)
        .then((res) => {
            console.log('Aggiunto materiale')
            console.log(res.data._id)
            let domanda_gia_spalmata = true
            axios.put('/materiali/aggiungi_classe_al_materiale/' 
                       + res.data._id + '/' + classe_selezionata 
                       + '/' + domanda_gia_spalmata  + '/' + key_8_cifre)
            .then((res) => {
                console.log('XXXXXXXXXXXXX Aggiunta una classe array')
                console.log(res.data.array_classi)
                console.log(programma_della_classe)
                aggiungi_classi_non_spalmate_array(res.data._id, programma_della_classe, id_utente)

            }).catch((error) => {
                console.log(error)
            });
        }).catch((error) => {
            console.log(error)
        });
    }

    
    function handleClick_classe (classe_id) {
    }

    return (
    <>
            <Modal
            show={props.show_esercizio}
            size="lg"
            onHide={handleClose}
            onShow={handleShow}
        
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
            <CloseButton  onClick={() => Chiusura_della_finestra_modale()} />
            </Modal.Header>
            <Modal.Dialog>
                <ButtonGroup onClick={Scelta_classe}>
                    {lista_classi.map(function (item, index2) {
                        {
                            //                        console.log(item)
                            return <>
                                <Button value={item._id}  variant="secondary">{item.nome}</Button>
                            </>
                        }
                    })
                    }
                </ButtonGroup>
                <button onClick={() => createPDF(1)} type="button">Download1</button>
                <button onClick={() => createPDF(2)} type="button">Download2</button>
                <div id="pdf5">
                <table>
                {lista_alunni.map(function (item, index2) {
                        {
                            let stringa = '&nbsp;&nbsp;'  
                            props.domanda.variabili_calcoli.map(function (item2_var, index2) {
                                {
                                    let numero = item2_var.minimo + item2_var.passo * 
                                    Math.floor(Math.random() * (item2_var.massimo-item2_var.minimo)/item2_var.passo)
                                    numero = numero.toFixed(2)
                                    stringa = stringa + item2_var.nome + '=' + numero + ' &nbsp;&nbsp; '
                                }
                            })
                            //                        console.log(item)
                            return <>
                                <tr> <td> {item.cognome} {' '} {item.nome}</td> <td>  {parse(stringa)} </td></tr> 
                            </>
                        }
                    })
                    }
                </table>
                </div>
                <form>
                    <div className='form-group'>
                        <div className='rettangolo_rosso'>
                    </div>
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <button onClick={handleClick} className="btn btn-lg btn-info">Salva</button>
                    </div>
                    <div class="d-flex flex-row-reverse">
                        <button onClick={spalma_domanda_alunni} className="btn btn-lg btn-info">Spalma</button>
                    </div>
                </form>

            </Modal.Dialog>
        </Modal>
    </>
    )
}

export default Mostra_form_esercizio;
