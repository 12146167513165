import React, { useState, useRef, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import AudioPlayer from 'react-h5-audio-player';


function check_variabili_oggetto(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj)
}


const Walkmind_play_spezzone_mp3_con_link = (props) => {
//    const [somma, calcola_somma] = useState('');
const reactPlayerRef = useRef();
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});


useEffect(() => {
    if (props.indice_chiamata_componenti == 'Fine_play_file_mp3_risposta') {

        prova_a_mandare_in_play_un_bookmark(props.item) 
    }
}, [props.indice_chiamata_componenti]);


useEffect(() => {

}, [props.item.video_id]);


function prova_a_mandare_in_play_un_bookmark(item) { 
//    console.log('BOOKMARK BOOKMARKBOOKMARKBOOKMARKBOOKMARK')
    let btn, btn1, btn2 
    btn = check_variabili_oggetto (item.video_id, 'tipo')
//    console.log('btn1 ' + btn);
    btn1 = check_variabili_oggetto (item.video_id, 'link')
//    console.log('btn1 ' + btn1);
    btn2 = check_variabili_oggetto (item.video_id, 'tipo')
   
    if (props.show && btn == 'mp3' && btn1 != '') {

        const bookmark_estratto = props.item.video_id.elenco_bookmark.filter(row => row._id === props.item.video_id_indice).pop();
        console.log(bookmark_estratto)
        SET_bookmark_estratto_stato(bookmark_estratto)
       
        console.log(reactPlayerRef)
        reactPlayerRef.current.audio.current.currentTime = bookmark_estratto.tempo;
        reactPlayerRef.current.audio.current.play();

        
        const intervallo = bookmark_estratto.tempo_finale - bookmark_estratto.tempo 

        setTimeout(() => {
//            console.log("Delayed for 1 second.");
            reactPlayerRef.current.audio.current.pause();

            props.SET_indice_chiamata_componenti('fine play del pezzo mp3')
            return;
        
        }, 1000*intervallo); 
    } 
    console.log("LINK---------" + btn1);
 
    if (btn1 == '' || btn1 == null || btn2 != 'mp3') {
        props.SET_indice_chiamata_componenti('fine play del pezzo mp3')
        return;
    }

}

const onPlay = () => {
    console.log(reactPlayerRef.current)

}

    return (
    <>
    {props.item.video_id != undefined && props.item.video_id.link != '' && props.item.video_id.tipo == 'mp3' && <AudioPlayer
                            ref={reactPlayerRef} 
                            autoPlay = {false}
                            autoPlayAfterSrcChange={false}
                            defaultCurrentTime = "01:30"
                            src = {props.item.video_id != undefined && props.item.video_id.link}
                            onPlay={onPlay}
                            // other props here
                        />  }
    </>
    )
}
export default Walkmind_play_spezzone_mp3_con_link;