import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from "axios";

import { Button, ButtonGroup, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import Nuovo_Video_Edit from "./Video_x0_Edit";
import Gestione_youtube from "./Video_youtube_gestione.jsx";
import Gestione_mp3 from "./Video_y_mp3_gestione.jsx";
import Gestione_video from "./Video_y_video_gestione.jsx";
import Gestione_seminario_Realtime from "./Video_seminario_realtime.jsx";
import Modifica_dati_video from "./Modifica_dati_video.jsx";
import Video_Crea_Nuovo from "./Video_x1_crea_nuovo.jsx";
import Trova_contenuto_multimediale from "./Video_x1_find_multimedia.jsx";

import JSONPretty from 'react-json-pretty';
import useWindowDimensions from '../funzioni_comuni_windows';

//import DatePicker from "react-datepicker";
//import Crea_nuovo_alunno from "./Alunno_x0_1_crea_alunno";
import { useSelector, useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react';
import { useScrollInfo } from '@faceless-ui/scroll-info';
import ringer from "../compon_mp3/camera2.wav";
import ringer_jump from "../compon_mp3/jump.mp3";
import ringer_quite from "../compon_mp3/quite.mp3";
import ringer_crash from "../compon_mp3/crash.mp3";
import ringer_scroll from "../compon_mp3/scroll.mp3";
import ringer_wes from "../compon_mp3/wes.mp3";
import ringer_invalid from "../compon_mp3/invalid.mp3";
import bbc_chirp from "../compon_mp3/bbc_buono.mp3";
import ringer_c1 from "../compon_mp3/c1.wav";

import WebSocketComponent from "./Web_socket.jsx";


const Gestione_Materiale_Multimediale = (props) => {

    const [input, SET_input] = useState({
        number_max: 5,
        testo_ricerca: '',
        trigger_carica: false 
    });

    const [variab_aggiorna, SET_variab_aggiorna] = useState(false);
    const [Video_id_selezionato, SET_Video_selezionato] = useState(null);
    const [modifica_dati_file_multimediale, SET_modifica_dati_file_multimediale] = useState(false);
    
    const [trigger_per_bookmark, SET_trigger_per_bookmark] = useState(0);
    const [Bookmark_id_selezionato, SET_Bookmark_id_selezionato] = useState(null);
    const [dateBegin, setDateBegin] = useState(new Date())
    const [date_evita_sleep, SET_date_evita_sleep] = useState(new Date())
    const [counter_sleep_telefonino, SET_counter_sleep_telefonino] = useState(0);

    const id_utente = useSelector(state => state.variabile.value)
    const { user, isAuthenticated } = useAuth0();
  
    const { height, width } = useWindowDimensions();
    const scrollInfo = useScrollInfo();
    const audio_ring = new Audio(ringer);
    const audio_jump = new Audio(ringer_jump);
    const audio_quite = new Audio(ringer_quite);
    const audio_crash = new Audio(ringer_crash);
    const audio_scroll = new Audio(ringer_scroll);
    const audio_wes = new Audio(ringer_wes);
    const audio_invalid = new Audio(ringer_invalid);
    const audio_bbc_chirp = new Audio(bbc_chirp);
    const audio_c1 = new Audio(ringer_c1);

    const handleKeyDown = (event) => {
        event.stopImmediatePropagation();
//        imposta_bookmark ()
        if (event.key === '1' ) {
            console.log('A key was pressed', event.keyCode);
            SET_trigger_per_bookmark(1)
           
        }
        if (event.key === '2'  ) {
            console.log('A key was pressed', event.keyCode);
            SET_trigger_per_bookmark(2)
        
        }
        if (event.key === '3'  ) {
            console.log('A key was pressed', event.keyCode);
             audio_wes.play()
        }
        if(event.ctrlKey && event.keyCode == 37){
            SET_trigger_per_bookmark(37)
        }
        if(event.ctrlKey && event.keyCode == 39){
            SET_trigger_per_bookmark(39)
        }
        if(event.shiftKey && event.keyCode == 37){
            SET_trigger_per_bookmark(137)
        }
        if(event.shiftKey && event.keyCode == 39){
            SET_trigger_per_bookmark(139)
        }
    };

    useEffect(() => {
        console.log('CLASSI USE EFFECT')
        console.log(scrollInfo)
        Carica_video_x_account()
    }, [variab_aggiorna, id_utente, input.number_max]);



    useEffect(() => {
        console.log('CLASSI USE EFFECT')
        console.log(scrollInfo)

        if (input.trigger_carica) Carica_video_x_account()

        SET_input(prevInput => {
            return {
                ...prevInput,
                trigger_carica: false
            }
        })
    }, [input.trigger_carica]);



    useEffect(() => {
//        console.log(scrollInfo.yPercentage)
        let adesso = new Date()

        let seconds = (adesso.getTime() - dateBegin.getTime()) / 1000;
        if (counter_sleep_telefonino > 6) {
            SET_counter_sleep_telefonino(0)
            SET_trigger_per_bookmark(3)
        }
        if (counter_sleep_telefonino > 0) {SET_counter_sleep_telefonino(0)}
//        console.log(seconds)
//        console.log('XXXXXXXXXXXXXXXXX  cambiaedrfg')
        if (seconds > 2 && scrollInfo.yPercentage>95) {
            setDateBegin(new Date())
            SET_input(prevInput => {
                return {
                ...prevInput,
                number_max: prevInput.number_max + 5
            }
        })
        }

    }, [scrollInfo]);



    useEffect(() => {
  
      const interval = setInterval(() => {
        SET_counter_sleep_telefonino((prevCounter) => prevCounter + 1);
            
      }, 1 * 30 * 1000);
  
      return () => clearInterval(interval);
    }, []); 



    useEffect(() => {
      
        if (counter_sleep_telefonino == 8 || counter_sleep_telefonino == 10 || 
            counter_sleep_telefonino == 18 || counter_sleep_telefonino == 19) {
                let multimediale_estratto =  props.lista_video.find(item => item._id === Video_id_selezionato)    
                console.log(multimediale_estratto)
                if (multimediale_estratto != undefined && !multimediale_estratto.tipo.includes('real_time')) 
                audio_bbc_chirp.play()
//            }
        }
        if (counter_sleep_telefonino > 10) {
           
//            SET_counter_sleep_telefonino(0);
        }
       
        if (counter_sleep_telefonino == 3) {
//            audio_crash.play()
        }
    }, [counter_sleep_telefonino]); 

    


    useEffect(() => {
//        audio_c1.play()
        window.addEventListener('keydown', handleKeyDown);
    
        // cleanup this component
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [Video_id_selezionato]);



    const Carica_video_x_account = () => {
        //    funzione_set_aggiornamento(true)
        console.log('Carica_classi_x_account')
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}


        axios.get('/video/video_carica_tutti_migliorato/', {
            params: {
                utente: id_utente,
                number_max: input.number_max, 
                testo_ricerca: input.testo_ricerca      
            }
        }).then((res) => {
//            console.log(res.data)
            props.SET_lista_video(res.data)
        }).catch((error) => {
            console.log(error)
        });
    }

//    https://www.idroslab.com/MP3/focus.mp3

    return (
    <div className="container"  >
        <div className="row" >
        <div className="col-lg-3" >
            <WebSocketComponent Bookmark_id_selezionato ={Bookmark_id_selezionato} SET_trigger_per_bookmark={SET_trigger_per_bookmark}/>

            <Trova_contenuto_multimediale input={input} SET_input={SET_input} />

            {/* <Gestione_materie /> */}
            <Col>
                <Row>
                width: {width} ~ height: {height}
                </Row>
                <Row>
                Video massimi {input.number_max}
                </Row>
                <Row>
                -- {Video_id_selezionato}
                </Row>
            </Col>
        </div>
        <div className="col-lg-9" >
            <div>
                <Video_Crea_Nuovo SET_Video_selezionato={SET_Video_selezionato} SET_variab_aggiorna={SET_variab_aggiorna}/> 
            </div>

            <div>
                <ListGroup>
                    {props.lista_video.map(function (item, index2) {
                        {
                            //                        console.log(item)
                        return <div className='border mt-4'>
                            <Row>
                            <Nuovo_Video_Edit item={item} variab_aggiorna={variab_aggiorna} 
                                                SET_variab_aggiorna={SET_variab_aggiorna}
                                                SET_Video_selezionato={SET_Video_selezionato} 
                                                SET_modifica_dati_file_multimediale={SET_modifica_dati_file_multimediale} 
                                                />
                            </Row>
                            <Modifica_dati_video item={item} Video_id_selezionato={Video_id_selezionato} 
                            SET_variab_aggiorna={SET_variab_aggiorna} counter_sleep_telefono = {counter_sleep_telefonino}
                            modifica_dati_file_multimediale={modifica_dati_file_multimediale}
                            SET_modifica_dati_file_multimediale={SET_modifica_dati_file_multimediale} />

                            {/*
                            <Row>
                                <Col>
                                    <Row><p>Nome: <strong>{item.nome}</strong></p></Row>
                                </Col>
                                <Col>
                                    <Row><p>tipo: <strong>{item.tipo}</strong></p></Row>
                                </Col>
                                <Col>
                                    <Row><p> <strong>{item.link}</strong></p></Row>
                                </Col>
                            </Row>
                            */}
                            <Row>
                            
                            {(item.tipo === 'youtube') &&
                                <Gestione_youtube item={item} 
                                    variab_aggiorna = {variab_aggiorna}
                                    SET_variab_aggiorna = {SET_variab_aggiorna}
                                    SET_Video_selezionato={SET_Video_selezionato}
                                    Bookmark_id_selezionato={Bookmark_id_selezionato}
                                    SET_Bookmark_id_selezionato={SET_Bookmark_id_selezionato}
                                    Video_id_selezionato={Video_id_selezionato}
                                    trigger_per_bookmark = {trigger_per_bookmark} 
                                    SET_trigger_per_bookmark = {SET_trigger_per_bookmark} 
                                    account_dati= {props.account_dati}
                                    SET_counter_sleep_telefonino = {SET_counter_sleep_telefonino}
                                    modifica_dati_file_multimediale={modifica_dati_file_multimediale}
                                /> 
                            }
                            {(item.tipo === 'mp3') &&
                                <Gestione_mp3 item={item} 
                                    variab_aggiorna = {variab_aggiorna}
                                    SET_variab_aggiorna = {SET_variab_aggiorna}
                                    SET_Video_selezionato={SET_Video_selezionato}
                                    Bookmark_id_selezionato={Bookmark_id_selezionato}
                                    SET_Bookmark_id_selezionato={SET_Bookmark_id_selezionato}
                                    trigger_per_bookmark = {trigger_per_bookmark} 
                                    SET_trigger_per_bookmark = {SET_trigger_per_bookmark} 
                                    Video_id_selezionato={Video_id_selezionato}
                                    account_dati= {props.account_dati}
                                    SET_counter_sleep_telefonino = {SET_counter_sleep_telefonino}
                                    modifica_dati_file_multimediale={modifica_dati_file_multimediale}
                                /> 
                            } 
                            {(item.tipo === 'video') &&
                                <Gestione_video item={item} 
                                    variab_aggiorna = {variab_aggiorna}
                                    SET_variab_aggiorna = {SET_variab_aggiorna}
                                    SET_Video_selezionato={SET_Video_selezionato}
                                    Bookmark_id_selezionato={Bookmark_id_selezionato}
                                    SET_Bookmark_id_selezionato={SET_Bookmark_id_selezionato}
                                    trigger_per_bookmark = {trigger_per_bookmark} 
                                    SET_trigger_per_bookmark = {SET_trigger_per_bookmark} 
                                    Video_id_selezionato={Video_id_selezionato}
                                    account_dati= {props.account_dati}
                                    SET_counter_sleep_telefonino = {SET_counter_sleep_telefonino}
                                    modifica_dati_file_multimediale={modifica_dati_file_multimediale}
                                /> 
                            } 
                            {(item.tipo === 'real_time') &&
                                <Gestione_seminario_Realtime item={item} 
                                    SET_variab_aggiorna = {SET_variab_aggiorna}
                                    SET_Video_selezionato={SET_Video_selezionato}
                                    trigger_per_bookmark = {trigger_per_bookmark} 
                                    SET_trigger_per_bookmark = {SET_trigger_per_bookmark} 
                                    Video_id_selezionato={Video_id_selezionato}
                                    SET_counter_sleep_telefonino = {SET_counter_sleep_telefonino}
                                    modifica_dati_file_multimediale={modifica_dati_file_multimediale}
                          
                                /> 
                            } 

                            </Row>

                          
                            {(props.account_dati.utente_debug) &&
                                    <>
                                    Bookmark {Bookmark_id_selezionato}  {' - Video '}
                                    {Video_id_selezionato}

                                    <JSONPretty data={item} />
                                    </>
                            }
                    
                            <hr class="rounded"></hr>

                        </div>
                        }
                    })
                    }
                </ListGroup>
            </div>
        </div>
        </div>
    </div >
)
}

export default Gestione_Materiale_Multimediale;
