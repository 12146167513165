import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import axios from "axios";
//import DatePicker from "react-datepicker";
import { Button, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';



const Gestione_variabili_per_calcoli = (props) => {

    const [variab_aggiorna, SET_variab_aggiorna] = useState('');
    const [end_Date, set_End_Date] = useState(new Date());
    const [input, setInput] = useState({
        nome: '',
        minimo: '',
        massimo: '',
        passo: ''
    });


    useEffect(() => {
        console.log('USEEFFECT alunno_form_base')
        console.log(props.id)
    }, []);


    function handleChange(event) {
        const { name, value } = event.target;
        console.log('sssssss')

        console.log(end_Date)
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    function handleClick(event) {
        event.preventDefault();
        axios.put('/domande_calcoli/aggiungi_una_variabile/' + props.item._id, input)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
        let lista = props.lista_variabili
// devo creare un numero random per lavorare
        let numero = Number(input.minimo) + Number(input.passo) * 
        Math.floor(Math.random() * (Number(input.massimo)-Number(input.minimo))/Number(input.passo))
//                    console.log(numero);
        let input_aux = input
        input_aux.valore_attuale = Number(numero) ; 
        lista.push(input_aux)
        console.log('CALCOLI Aggiunta una variabile')
        console.log(props.cambio_stato1)
        props.set_lista_variabili(lista)
        let a = props.cambio_stato1
        props.set_cambio_stato1(!props.cambio_stato1)
    }

    return (
        <div>
            <p>
                <Row>
                    <Col>
                        <input onChange={handleChange} placeholder="Nome variabile" name="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                    </Col>
                    <Col>
                        <input onChange={handleChange} placeholder="Numero" name="minimo" value={input.minimo} autoComplete="off" className="form-control"></input>
                    </Col>
                    <Col>
                        <input onChange={handleChange} placeholder="Numero" name="massimo" value={input.massimo} autoComplete="off" className="form-control"></input>
                    </Col>
                    <Col>
                        <input onChange={handleChange} placeholder="Passo" name="passo" value={input.passo} autoComplete="off" className="form-control"></input>
                    </Col>
                    <Col>
                        <Button variant="light" onClick={handleClick}>
                            Salva
                            </Button>
                        </Col>
                </Row>
                
            </p>

        </div>
    );
}
export default Gestione_variabili_per_calcoli;