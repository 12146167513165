//import Bookmark_cancella from "./Bookmark_cancella.jsx";
import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import axios from "axios";
import { Button, Modal} from 'react-bootstrap';
import { FaRegTrashAlt } from "react-icons/fa";

const Bookmark_cancella = (props) => {
//    const [somma, calcola_somma] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const cancella_link = () => {
        console.log('sdfd props._id' + props.id)

        axios.delete('/domande/cancella_domanda_per_bookmark/' + props.id)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });

        axios.patch('/video/cancella_bookmark/' + props.id + '/' + props.item_array_id)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log('errore')

                console.log(error)
            });
        //        props.SET_variab_aggiorna(!aux)
        props.SET_variab_aggiorna(current => !current)
        setShow(false)
    }

    return (
    <>
         <Button variant="outline-primary" onClick={handleShow}  > <FaRegTrashAlt /> </Button>{' '}
         <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Atenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>Cancellare il Bookmark Creato. iene eliminata anche la domanda se esiste</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Annulla 
            </Button>
            <Button variant="primary" onClick={() => cancella_link()}>
                Cancella
            </Button>
            </Modal.Footer>
        </Modal>

    </>
    )
}
export default Bookmark_cancella;