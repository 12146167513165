import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'; 
 
const Root_principale = (props) => {
//    const [somma, calcola_somma] = useState('');

const apertura_finestra = () => {
    console.log('ciao21')
    props.SET_input_opzioni(prevInput => {
      return {
          ...prevInput,
          show_nomat: true,
          scelta_query: "solo_mp3",
          scelta_non_walkmind: false,
          show_materie_tutte: true,
          ordine_visualizzazione: 'sort_hard',
          number_max: 40
      }
    })

}

const apertura_finestra2 = () => {
    console.log('ciao21')
    props.SET_input_opzioni(prevInput => {
      return {
          ...prevInput,
          show_nomat: true,
          scelta_query: "solo_testo",
          scelta_non_walkmind: false,
          show_materie_tutte: true,
          number_max: 40,
          ordine_visualizzazione: 'sort_hard'
        }
    })

}

const apertura_finestra3 = () => {
    console.log('ciao21')
    props.SET_input_opzioni(prevInput => {
      return {
          ...prevInput,
          show_nomat: true,
          scelta_query: "tutto",
          scelta_non_walkmind: false,
          show_materie_tutte: true,
          number_max: 40,
          ordine_visualizzazione: 'sort_hard'
          }
    })

}

const apertura_finestra4 = () => {
      console.log('ciao21')
      props.SET_input_opzioni(prevInput => {
        return {
            ...prevInput,
            show_nomat: false,
            scelta_non_walkmind: false,
            scelta_query: "tutto",
            number_max: 40,
            show_materie_tutte: false,
            ordine_visualizzazione: 'sort_hard'
        }
      })
}
const apertura_finestra5 = () => {
  console.log('ciao8')
  props.SET_input_opzioni(prevInput => {
    return {
        ...prevInput,
        show_nomat: true,
        scelta_query: "bookmark",
        show_materie_tutte: false,
        scelta_non_walkmind: false,
        number_max: 40,
        ordine_visualizzazione: 'sort_hard'
      
    }
  })
}



return (
    <>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Solo materie accese</Card.Title>
        <Card.Text>
          Bookmark dai file mp3 archiviati
        </Card.Text>

        <Link to="url_domande_mp3">
            <Button variant="danger" onClick={() => apertura_finestra5()}>Bookmark</Button>
        </Link>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Solo mp3</Card.Title>
        <Card.Text>
          Mostra tutte le materie. Solo gli mp3
        </Card.Text>

        <Link to="url_domande_mp3">
            <Button variant="primary" onClick={() => apertura_finestra()}>Go somewhere</Button>
        </Link>
      </Card.Body>
    </Card>
   
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Solo testo</Card.Title>
        <Card.Text>
          Mostra tutte le materie. Solo il testo
        </Card.Text>

        <Link to="url_domande_mp3">
            <Button variant="primary" onClick={() => apertura_finestra2()}>Go somewhere</Button>
        </Link>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Tutto di tutto</Card.Title>
        <Card.Text>
          Mostra tutte le materie. Sia il testo che gli mp3
        </Card.Text>

        <Link to="url_domande_mp3">
            <Button variant="primary" onClick={() => apertura_finestra3()}>Go somewhere</Button>
        </Link>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Solo materie accese</Card.Title>
        <Card.Text>
          In play solo le materie selezionate. Sia il testo che gli mp3
        </Card.Text>

        <Link to="url_domande_mp3">
            <Button variant="primary" onClick={() => apertura_finestra4()}>Go somewhere</Button>
        </Link>
      </Card.Body>
    </Card>
    
    </>
    
    
  );
}
export default Root_principale;