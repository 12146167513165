import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import Scroll_evento from "./X2_scroll_evento_non_usato.jsx";

import Nuova_domanda from './X3_Nuova_domanda';
import Dropdown_jump from './X5_dropdown_jump';
import Dropdown_materie_new from './X6_dropdown_materie_NEW';
import Risposta_visualizza from './X7_risposta_visualizza_non funzionante.jsx';
import Visualizza_risposta from './X8_mostra_risposta_funzionante';
// forse questo non serve a niente (e' presente il campo nuova materia)
import Scegli_materie from './X9_0_lista_materie';
import Mostra_informazioni from './X10_mostra_informazioni_PAUSA';
// utilizzato per caricare i vecchi file
import Mostra_link_per_studiare from './X12_allegati_link_studio';
import Gestione_menu_alto_destra from "./X11_menu_in_alto_destra";
import Mostra_allegati_pdf_v2 from "./X13_allegati_pdf_v2";
import Gestione_progress_bar from "./X14_progress_bar";
import Dropdown_ordine_visualizzazione from "./X16_ordine_visualizzazione";
import Visualizzazione_alert from "./X18_alert_con_timer";
import Gestione_testo_per_numeri_random from "./X15_1_base_calcoli";
import Opzioni_visualizzazione from "./X19_Opzioni_Visualizzazione";
import TriggerExample from "./X20_button_debug.jsx";
import Offcanvas_debug from "./X21_offcanvas_debug.jsx";
import ShippingLabel from "./X31_prova_stampa_pdf.jsx";
import Upload_files_mp3 from "../compon_mp3/X0_upload_files";
import Gestione_allegati_multimediali from "./X40_Gestione_allegati_multimediali.jsx";
import Trova_contenuto_multimediale from "../compon_video/Video_x1_find_multimedia.jsx";
import { useScrollInfo } from '@faceless-ui/scroll-info';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import parse from 'html-react-parser';
import JSONPretty from 'react-json-pretty';
import '../App.css';
import './X0_0.css';
import ringer_jump from "./jump.mp3";

//import { param } from '../../../routes/posts.js';



async function Carica_materie_x_account( id_utente) {


    axios.get('/materie/carica_tutte/' + id_utente)
    .then((res) => {
        return res.data


    }).catch((error) => {
        console.log(error)
        return 'errore'
    });

}

const Lista_domande = (props) => {

    const [lista_domande, SET_lista_domande] = useState([{ indice_aux: 0 }]);
    const [lista_materie, SET_lista_materie] = useState([{ materie: 'aux' }]);
    const [lista_domande_render, SET_lista_domande_render] = useState([]);
    const [cambio_stato, SET_cambio_stato] = useState(false);
    const [visualizza_cartelle_speciali, SET_visualizza_cartelle_speciali] = useState('cartella_root');
    const [dateBegin, setDateBegin] = useState(new Date())
    const [max_indice_aux, SET_max_indice_aux] = useState(1);
    const [ordine_visualizzazione, set_ordine_visualizzazione] = useState('sort_forget');

    const id_utente = useSelector(state => state.variabile.value)
    const { user, isAuthenticated } = useAuth0();
    const [show_alert, setShow_alert] = useState(false);

    const audio_jump = new Audio(ringer_jump);
    const scrollInfo = useScrollInfo();


//    const id_utente = "612fb32ad7c7ea0bf37cbe8f";
    useEffect(() => {
        console.log('-X0--- USEEFFECT -----')
//        audio_jump.play()

        if (isAuthenticated) {
            console.log('-X0------- isAuthenticated ----------')
            setShow_alert(true)
            Carica_materie_x_account()
        }
    }, [cambio_stato, id_utente, visualizza_cartelle_speciali, 
        props.input_opzioni.show_nomat, props.input_opzioni.scelta_query, props.input_opzioni.number_max]);



    useEffect(() => {
        console.log('CLASSI USE EFFECT')
        if (props.input_opzioni.trigger_carica) Carica_materie_x_account()
        props.SET_input_opzioni(prevInput => {
            return {
                ...prevInput,
                trigger_carica: false
            }
        })
    }, [props.input_opzioni.trigger_carica]);



    useEffect(() => {
        //     toolmemo idroslab
                let adesso = new Date()
                let seconds = (adesso.getTime() - dateBegin.getTime()) / 1000;
        //        console.log(seconds)
        //        console.log('XXXXXXXXXXXXXXXXX  cambiaedrfg')
                if (seconds > 2 && scrollInfo.yPercentage>95) {
                    setDateBegin(new Date())
                    props.SET_input_opzioni(prevInput => {
                        return {
                        ...prevInput,
                        number_max: prevInput.number_max + 5
                    }
                })
                }
    }, [scrollInfo]);



    const Carica_materie_x_account = () => {
        //    funzione_set_aggiornamento(true)
//        console.log('Carica_domande_x_account')
//        console.log('id_utente' + id_utente)
        axios.get('/materie/carica_tutte/' + id_utente)
            .then((res) => {
                SET_lista_materie(res.data)
                Carica_domande_x_account(res.data)
            }).catch((error) => {
                console.log(error)
            });
    }


    const Carica_domande_x_account = (lista_materie_caricate_da_mongo) => {
        //    funzione_set_aggiornamento(true)
//        console.log('xxxxx Carica_domande_x_account')
//        console.log(ordine_visualizzazione)
//        console.log(id_utente)

//        console.log(id_utente)
        let end_date = 5
        //        https://mydomain.dm/fruit/{"name":"My fruit name", "color":"The color of the fruit"}

// idroslab
        axios.get('/domande/carica_tutte_migliorato/', {
            params: {
                id_utente: id_utente,
                ordine_visualizzazione: ordine_visualizzazione,
                visualizza_cartelle_speciali: visualizza_cartelle_speciali,
                show_nomat: props.input_opzioni.show_nomat,
                scelta_query: props.input_opzioni.scelta_query,
                
                scelta_non_walkmind: props.input_opzioni.scelta_non_walkmind,
                number_max: props.input_opzioni.number_max,
                testo_ricerca: props.input_opzioni.testo_ricerca,  
                lista_materie: JSON.stringify(lista_materie_caricate_da_mongo)
            }
        }).then((res) => {
                SET_max_indice_aux(lista_domande[0].indice_aux)
                if (Array.isArray(res.data)) SET_lista_domande_render(res.data)

                crea_lista_stornati(res.data)
                console.log(res.data[5]);
            }).catch((error) => {
                console.log(error)
            });
    }


    const crea_lista_stornati = (lista_domande_caricate_da_mongo) => {
        var lista_play_aux = []

        lista_domande_caricate_da_mongo.map(function (item, index) {
//            console.log(item.variabili_calcoli)
//            if (item.variabili_calcoli.length>10) {

            item.variabili_calcoli.map(function (item2_var, index2) {
//                console.log(item2_var)
//                    console.log("ZZZZZZZZZZZZZZZZZZZsono uguali");
                    let numero = item2_var.minimo + item2_var.passo *
                                    Math.floor(Math.random() * (item2_var.massimo-item2_var.minimo)/item2_var.passo)
//                    console.log(numero);
                    item.variabili_calcoli[index2].valore_attuale = numero;
                })
//            }
        });
//        console.log(lista_play_aux)
        SET_lista_domande_render(lista_domande_caricate_da_mongo)
    }

    
    function colorazione(item) {
        const valore_minimo = -100;
        const valore_massimo = 100;
        var colore = 'black'
        var colore_bordo = 'solid red'
        //        if (lista_file_mp3[index2].colore_bordo != undefined) {
        //            colore_bordo = '3px solid ' + lista_file_mp3[index2].colore_bordo
        //        }
        var modulo_vettore
        modulo_vettore = Math.max(valore_minimo, item.indice_aux)
        modulo_vettore = Math.min(valore_massimo, modulo_vettore)

        var colore2 = [255, 0, 0]
        var colore1 = [0, 255, 0]

        var vettore = addvector(colore2, colore1)
        var vettore1 = dividevector(colore1, vettore, valore_massimo + modulo_vettore, valore_massimo, valore_minimo)
        //        console.log(vettore1)
        var spessore = 1 + 2 * Math.abs((modulo_vettore - valore_minimo) / (valore_massimo - valore_minimo))
        colore_bordo = '' + Math.ceil(spessore) + 'px solid rgb(' + Math.ceil(vettore1[0]) + ',' + Math.ceil(vettore1[1]) + ',' + Math.ceil(vettore1[2]) + ' )'

//        console.log(modulo_vettore)
//        console.log(item.materia_id)

        let btn
        if (item.materia_id === undefined)
            {btn = "#cfd8dc"}
        if (!(item.materia_id === undefined || item.materia_id == null))
            {btn = item.materia_id.colore_sfondo}


        var hStyle = {
            backgroundColor: btn, color: colore, margin: '10px',
            padding: '10px', border: colore_bordo, borderRadius: '2vmin'
        };
        return hStyle;
    }



    function addvector(a, b) {
        return a.map((e, i) => e - b[i]);
    }

    function dividevector(colore1, a, b, vmax, vmin) {
        return a.map((e, i) => colore1[i] + e * b / (vmax - vmin));
    }


    function Inserisci_immagine_se_presente(item) {
//        console.log('IMMAGINE IMMAGINE')

        let btn = "XXXX non definito"
        if (item.indirizzo_S3_immagine_domanda === undefined) {return 'undefined'}

        if (item.indirizzo_S3_immagine_domanda.key === undefined) {return 'undefined1'}
        if (item.indirizzo_S3_immagine_domanda.key === null) {return 'null'}
        if (item.indirizzo_S3_immagine_domanda.key === '') {return ''}

        btn = "/transfer_s3/images/" + item.indirizzo_S3_immagine_domanda.key

// in caso si debba cancellare qualche immagine
//if (fileKey === "169627d6b259529742a9adb2e67870e4") return
//if (fileKey === "d905fa6011ef96911487811c80364bcb") return

        if (item.indirizzo_S3_immagine_domanda.key == "d905fa6011ef96911487811c80364bcb" )
        {
            const nuova_immagine_s3 = {
                key:  '',
                descrizione: 'descrizione'
            }
            axios.patch('/domande/modifica_immagine_domanda_S3/' + item._id, nuova_immagine_s3)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log(error)
            });
        }
//           console.log(btn)

        return <>
             <img src={btn} className='img-fluid shadow-4' />
             </>
    }


    function trasforma_data(data_item) {
        var localDate = new Date(data_item);
//        console.log(data_item.getFullYear())
        var localDate = new Date(data_item).toLocaleString("it-IT", {
            localeMatcher: "best fit",
            timeZoneName: "short"
        });
//        console.log(localDate)
        return <> {localDate} </>
    }


    const handleScroll = event => {
//        console.log('rappporto: ', event.currentTarget.offsetHeight / event.currentTarget.scrollTop  + '- scrollTop: ', event.currentTarget.scrollTop ,' - ' , event.currentTarget.scrollHeight );

//        console.log('rappporto: ', event.currentTarget.scrollHeight - event.currentTarget.scrollTop - event.currentTarget.clientHeight)
        if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop - event.currentTarget.clientHeight < 10) {
//            scrolledToBottom(e);
              console.log('cambiaedrfg')
              props.SET_input_opzioni(prevInput => {
                return {
                    ...prevInput,
                    number_max: prevInput.number_max + 5
                }
            })
        }
     };


      function buttonHandler (parametro)  {

        if (parametro === 'bug') {
        if (visualizza_cartelle_speciali === 'cartella_bug') {SET_visualizza_cartelle_speciali('cartella_root')}
        if (visualizza_cartelle_speciali === 'cartella_root') {SET_visualizza_cartelle_speciali('cartella_bug')}
        }

        if (parametro === 'cestino') {
            if (visualizza_cartelle_speciali === 'cartella_cestino') {SET_visualizza_cartelle_speciali('cartella_root')}
            if (visualizza_cartelle_speciali === 'cartella_root') {SET_visualizza_cartelle_speciali('cartella_cestino')}
            }
      }


    return (

    <div className="container" >
           {(props.account_dati.utente_debug) &&
                            <JSONPretty data={props.input_opzioni} />
        }
        <Trova_contenuto_multimediale input={props.input_opzioni} SET_input={props.SET_input_opzioni} />

        <Visualizzazione_alert  show={show_alert} setShow={setShow_alert} />
        <div className="row" >
        <Upload_files_mp3   cambio_stato={cambio_stato}
                            SET_cambio_stato={SET_cambio_stato} />
            <Opzioni_visualizzazione    input_opzioni={props.input_opzioni}
            setinput_opzioni={props.SET_input_opzioni}
            cambio_stato={cambio_stato}
            SET_account_dati = {props.SET_account_dati}
            SET_cambio_stato={SET_cambio_stato}
        />  
        {visualizza_cartelle_speciali === 'cartella_root' && <>
            <Nuova_domanda lista_domande_render={lista_domande_render}
                SET_lista_domande_render={SET_lista_domande_render}
                cambio_stato={cambio_stato}
                SET_cambio_stato={SET_cambio_stato}
                max_indice_aux={max_indice_aux}
                set_ordine_visualizzazione = {set_ordine_visualizzazione} />

            <Dropdown_ordine_visualizzazione    set_ordine_visualizzazione={set_ordine_visualizzazione}
                                                ordine_visualizzazione={ordine_visualizzazione}
                                                cambio_stato={cambio_stato}
                                                SET_cambio_stato={SET_cambio_stato}/>
            </>
        }
        <div className="col-lg-3">

     
            {(props.account_dati.utente_debug) &&
                <>
                ID UTENTE    {id_utente}
                </>
            }
            {/* <Gestione_materie /> */}
                <Scegli_materie lista_materie={lista_materie}
                SET_lista_materie={SET_lista_materie}
                SET_cambio_stato={SET_cambio_stato}
                cambio_stato={cambio_stato}
                lista_domande={lista_domande} />
        </div>
        <div className="col-lg-9" >
            {/* <button onClick={() => SET_cambio_stato(!cambio_stato)}>
                Carica lista domande
            </button> */}
        <div className="d-flex justify-content-between flex-row-reverse">

            <button onClick={() => buttonHandler('bug')} type="button">
                BUG
            </button>
            <button onClick={() => buttonHandler('cestino')} type="button">
                CESTINO

            </button>
            {visualizza_cartelle_speciali}
            {visualizza_cartelle_speciali === 'cartella_bug' && <>
                {lista_domande_render.length}
            </>}

       
        </div>
        {lista_domande_render.map(function (item, index2) {
            {
            if (index2 === 0) {
//                        SET_max_indice_aux(item.indice_aux)
            }
            //                    console.log(item)
            return <div style={colorazione(item)}>
                <div className="d-flex flex-column mb-3">
                    <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row">
                        {/* <Dropdown_materie item={item} lista_materie={lista_materie} SET_cambio_stato={SET_cambio_stato} cambio_stato={cambio_stato} /> */}


                        {(props.input_opzioni.show_debug) &&
                        <>
                        <TriggerExample item={item} />
                        <Offcanvas_debug item={item} input_opzioni={props.input_opzioni} />
                        </>
                        }


                    </div>
                    <div className="d-flex flex-row-reverse">
                        {/* <Dropdown_materie item={item} lista_materie={lista_materie} SET_cambio_stato={SET_cambio_stato} cambio_stato={cambio_stato} /> */}
                        <Gestione_menu_alto_destra  lista_domande_render={lista_domande_render}
                                                    SET_lista_domande_render={SET_lista_domande_render}
                                                    index={index2} item={item}
                                                    elenco_pdf={item.elenco_pdf}
                                                    SET_cambio_stato={SET_cambio_stato}
                                                    cambio_stato={cambio_stato}
                                                    account_dati={props.account_dati}
                                                    />
                        <Dropdown_materie_new item={item} lista_materie={lista_materie}
                                            SET_lista_domande_render={SET_lista_domande_render}
                                            lista_domande_render= {lista_domande_render}
                                            index={index2} />
                    </div>

                    </div>
                    <div className="d-flex justify-content-between">
                        {/* <JSONPretty data={item.materia_id} /> */}

                    </div>
                    <div className="primariga">
                        <div className="rettangolo_domanda">
                                {item.testo_prova}
                                <Gestione_testo_per_numeri_random stringa_passata={item.domanda} item={item}
                                                                lista_variabili={item.variabili_calcoli} />

                            {/* <p><small>{parse(item.domanda)}</small></p> */}


                        </div>

                        <div className="rettangolo_domanda">
                            {/* <Accordion_Risposta item={item} SET_cambio_stato={SET_cambio_stato} cambio_stato={cambio_stato} /> */}
                            {/* <Mostra_informazioni item={item} /> */}
                            {item.indice_aux} su {item.indice_end}
                        </div>
                    </div>
                    {Inserisci_immagine_se_presente(item)}

                    <div>
                        <div className="mt-1 border border-secondary">
                            <Mostra_link_per_studiare item={item} SET_cambio_stato={SET_cambio_stato} cambio_stato={cambio_stato}  />
                        </div>
                        <div className="mt-1 border border-secondary" >
                            <Mostra_allegati_pdf_v2 item={item} elenco_pdf={item.elenco_pdf} SET_cambio_stato={SET_cambio_stato} cambio_stato={cambio_stato} />
                        </div>
                        <div className="mt-1">

                            <Gestione_progress_bar item={item}/>
                            <Gestione_allegati_multimediali item={item}/>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-1">
                            <div className="d-flex flex-row mt-1">
                                <small>{trasforma_data(item.date)}</small>
                                 <small> &nbsp;&nbsp;&nbsp;&nbsp;  Ultimo ascolto: {trasforma_data(item.data_ultimo_ascolto)}</small>
                            </div>


                            {visualizza_cartelle_speciali === 'cartella_root' &&
                            <div className="d-flex flex-row-reverse mt-1">

                                <Dropdown_jump item={item} lista_domande_render= {lista_domande_render}
                                            index={index2}
                                            SET_lista_domande_render= {SET_lista_domande_render} />
                                <Visualizza_risposta item={item} />
                            </div>
                            }
                        </div>

                    </div>
                    {(props.account_dati.utente_debug) &&

                       <JSONPretty data={item} />
                    }
                </div>
            </div>
            }
        })
        }
        </div>
        </div>
    </div>


    )
}

export default Lista_domande;
