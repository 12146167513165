import React, { useState } from 'react';
//import ReactTooltip from 'react-tooltip';
import { ButtonToolbar, Col, Tooltip, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import parse from 'html-react-parser';
import Gestione_testo_per_numeri_random from "./X15_1_base_calcoli";


const Visualizza_risposta = (props) => {
    //    const [somma, calcola_somma] = useState('');
    
    function mostra_ultima_volta_jump() {
        var date = new Date();
        var timestamp = Math.round(date.getTime() / 1000 / 60 | 0);
        var tempo_passato = timestamp - props.item.microtime_last
        var stringa
        if (tempo_passato < 60) {
            stringa = 'last time ' + tempo_passato + ' minuti'
            return stringa;
        }
        if (tempo_passato < 60 * 24) {
            tempo_passato = tempo_passato / 60
            stringa = 'last time ' + tempo_passato.toFixed(1) + ' ore'
            return stringa;
        }
        tempo_passato = tempo_passato / (24 * 60)
        stringa = 'last time ' + tempo_passato.toFixed(1) + ' giorni'
        return stringa;
    }
    
    
    const popoverLeft = (
        <Popover id="popover-positioned-left" title="Popover left">
                 <strong><p>Risposta </p></strong>  {/* parse(props.item.risposta) */}
                 <Gestione_testo_per_numeri_random stringa_passata={props.item.risposta} 
                                                   item={props.item}
                                                   lista_variabili={props.item.variabili_calcoli}
                                                   /> 

        </Popover>
        );
        
        return (
            <div >
            
            <ButtonToolbar>
            <OverlayTrigger trigger="hover" placement="left" overlay={popoverLeft}>
            <Button>Risposta</Button>
            </OverlayTrigger>
            </ButtonToolbar>
            
            </div>
            )
        }

export default Visualizza_risposta;
