import React, { useState } from 'react';
import '../App.css';
import axios from "axios";

//import axios from "axios";
import { Dropdown, Button, Modal, Form, Row, Col } from 'react-bootstrap';
//import DatePicker from "react-datepicker";
import { AiOutlineMenuFold } from 'react-icons/ai';



const Nuovo_Materiale_Edit = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [end_Date, set_End_Date] = useState(new Date());
    const [input, setInput] = useState({
        nome: props.item.nome   
    });

    
    function handleChange(event) {
        const { name, value } = event.target;
        console.log('sssssss')

        console.log(end_Date)
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }


    function handleClick(event) {
        event.preventDefault();
        //        console.log(input);
        //        const new_alunno = {
        //            ...input,
        //            data_nascita: end_Date
        //        }
        //        console.log(new_alunno);
        //        axios.post('http://localhost:3001/create', newNote)
        axios.patch('/materiali/materiale_modifica/' + props.item._id, input)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });
        setShow(false)
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
    }


    function cancellazione_materiale() {
        axios.delete('/materiali/cancella_materiale/' + props.item._id)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log(error)
            });
            props.SET_aggiornamento(current => !current)
    }


    return (
        <div class="d-flex flex-row-reverse">
            <Dropdown>
                <Dropdown.Toggle variant="Light" id="dropdown-basic">
                    Menu  <AiOutlineMenuFold />
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item onClick={handleShow} >Edit Materiali</Dropdown.Item>
                    <Dropdown.Item onClick={cancellazione_materiale}>Cancella Materiale</Dropdown.Item>
                    <div class="dropdown-divider"></div>
                  
                </Dropdown.Menu>
            </Dropdown>

            <Modal
                show={show}
                
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
            <Modal.Header closeButton>
            <Modal.Title>Edit materiale</Modal.Title>
            </Modal.Header>
               
            <Modal.Title id="example-custom-modal-styling-title">

            </Modal.Title>
            <Modal.Body>
                    <Row>
                    <Col>
                        <label>Nome</label>
                        <input onChange={handleChange} placeholder="Nome" name="nome" value={input.nome} autoComplete="off" className="form-control"></input>
                    </Col>
                </Row>
            </Modal.Body>

   `        <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClick}>
                    Save Changes
                </Button>
            </Modal.Footer>`
               
            </Modal>
        </div>
    )
}

export default Nuovo_Materiale_Edit;
