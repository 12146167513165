import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Form, Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';

const Trova_contenuto_multimediale = (props) => {
//    const [somma, calcola_somma] = useState('');

    function handleChange(event) {
        
        const { name, value } = event.target;
        console.log(props.input)
        props.SET_input(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    function Carica_file_multimedia() {
      
        props.SET_input(prevInput => {
            return {
                ...prevInput,
                trigger_carica: true
            }
        })
    }

    return (
    <>
        <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label> Ricerca </Form.Label>
            <Form.Control type="text" name="testo_ricerca" value={props.input.testo_ricerca} onChange={handleChange}/>
    
            <Button variant="primary" onClick={() => Carica_file_multimedia()}>
                Ricerca
            </Button>                          
        </Form.Group>
    </>
    )
}
export default Trova_contenuto_multimediale;