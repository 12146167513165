import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import JSONPretty from 'react-json-pretty';
import { assegna_account_google } from './REDUX_feauture/assegna_variabile'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';

const Profile = (props) => {
  const { user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch()
  const id_utente1 = useSelector(state => state.variabile.value)


  useEffect(() => {
    console.log('---------- USEEFFECT ----------')
    if (isAuthenticated) {
      console.log('---------- isAuthenticated ----------')
      Controllo_account_in_mongo_db()
  }
  }, []);


  const Controllo_account_in_mongo_db = () => {
    //    funzione_set_aggiornamento(true)
    console.log(user.sub)
    const body = {
        sub: user.sub,
    }
    axios.get('/account/trova_account/' + user.sub)
        .then((res) => {
            if (typeof res.data[0] === 'undefined') {
                console.log('UTENTE NON ESISTE')
                Salva_account_in_mongo_db()
//                Salva_domande_iniziali()
            } else {
                console.log('UTENTE ESISTE')

                console.log(res.data)
                console.log(res.data[0]._id)
                dispatch(assegna_account_google(res.data[0]._id))
                props.SET_account_dati (res.data[0])
            }
        }).catch((error) => {
            console.log(error)
        });
  }


  const Salva_account_in_mongo_db = () => {
    //    funzione_set_aggiornamento(true)
    console.log(user.sub)
    const new_account = {
        given_name: user.given_name,
        sub: user.sub,
        picture: user.picture
    }
    axios.post('/account/salva_account', new_account)
        .then((res) => {
            console.log(res.data)
            dispatch(assegna_account_google(res.data._id))
            Salva_domande_e_materie_iniziali(res.data._id)
        }).catch((error) => {
            console.log(error)
        });
  }



  const Salva_domande_e_materie_iniziali = (id_utente) => {
    //    funzione_set_aggiornamento(true)
    let materia = {
        materia: "Fisica",
        colore_sfondo: "#c8e6c9",
        utente: id_utente
    }
    let materia_fisica = Salva_materia_in_mongo_db(materia)
    console.log(materia_fisica)
    materia = {
        materia: "Storia",
        colore_sfondo: "#b3e5fc",
        utente: id_utente
    }
    let materia_storia = Salva_materia_in_mongo_db(materia)
    materia = {
        materia: "Scienze",
        colore_sfondo: "#ffe0b2",
        utente: id_utente
    }
    let materia_scienze = Salva_materia_in_mongo_db(materia)

    let domanda = {
        domanda: "Eventi che portarono allo scoppio della prima guerra mondiale",
        risposta: "La scintilla che fece scoppiare la guerra fu l’assassinio dell’arciduca d’Austria-Ungheria Francesco Ferdinando avvenuto a Sarajevo il 28 giugno 1914 ad opera di un nazionalista serbo. L’Austria dichiarò subito guerra alla Serbia, alleata della Russia, e come un fenomeno a catena tutte le nazioni europee si trovarono coinvolte nel conflitto generale.",
        materia_id: materia_storia._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Basi azotate presenti sui due filamenti del DNA",
        risposta: "Le quattro basi che sono presenti nel DNA sono l'adenina (abbreviata con la lettera A), la citosina (C), la guanina (G) e la timina (T)",
        materia_id: materia_scienze._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Quali fattori possono causare il danneggiamento del DNA",
        risposta: "Tra gli agenti alteranti figurano ad esempio agenti ossidanti, agenti alchilanti ed anche radiazioni ad alta energia, come i raggi X e gli UV",
        materia_id: materia_scienze._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Che cosa prevedeva la triplice allenza",
        risposta: "L'accordo prevedeva il soccorso da parte di Germania e Austria all'Italia nel caso questa fosse stata attaccata dalla Francia. Si stabiliva anche che, nel caso una delle contraenti fosse stata attaccata da due o più potenze nemiche le altre due assicuravano il loro soccorso. Inoltre, nel caso che una delle potenze firmatarie, minacciata, fosse stata costretta a iniziare una guerra, si assicurava la neutralità benevola da parte delle altre due.",
        materia_id: materia_storia._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Conseguenze sulla fine della prima guerra mondiale per l'impero ottomano",
        risposta: "L’Impero Ottomano firmò il Trattato di Sèvres il 10 agosto 1920, ponendo così fine alle ostilità con le potenze alleate; poco tempo dopo scoppiò la Guerra d’Indipendenza turca, a seguito della quale venne costituita la nuova Repubblica di Turchia che firmò, nel 1923, il Trattato di Losanna il quale annullava il precedente e, di fatto, smembrava il vecchio Impero Ottomano.",
        materia_id: materia_storia._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Primo principio della dinamica (o principio di inerzia)",
        risposta: "In un sistema inerziale, un corpo libero, cioè non sottoposto ad alcuna interazione reale, mantiene il suo stato di moto rettilineo uniforme o di quiete finché non interviene una interazione reale esterna a variare tale moto'. Il principio di inerzia è una diretta conseguenza del principio di relatività di Galileo, ma non è possibile dimostrare quest'ultimo a partire dal principio di inerzia",
        materia_id: materia_fisica._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Secondo principio della dinamica",
        risposta: "Una forza impressa ad un corpo produce una variazione della sua quantità di moto nel verso della forza in maniera direttamente proporzionale alla forza applicata",
        materia_id: materia_fisica._id,
        utente: id_utente
    }
    Salva_domanda_in_mongo_db(domanda)

}


const Salva_domande_Fisica = (utente_id, materia_id) => {
    //    funzione_set_aggiornamento(true)


    let domanda = {
        domanda: "Primo principio della dinamica (o principio di inerzia)",
        risposta: "In un sistema inerziale, un corpo libero, cioè non sottoposto ad alcuna interazione reale, mantiene il suo stato di moto rettilineo uniforme o di quiete finché non interviene una interazione reale esterna a variare tale moto'. Il principio di inerzia è una diretta conseguenza del principio di relatività di Galileo, ma non è possibile dimostrare quest'ultimo a partire dal principio di inerzia",
                materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Secondo principio della dinamica",
        risposta: "Una forza impressa ad un corpo produce una variazione della sua quantità di moto nel verso della forza in maniera direttamente proporzionale alla forza applicata",
                materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
}



const Salva_domande_Scienze = (utente_id, materia_id) => {
    //    funzione_set_aggiornamento(true)
    let domanda = {
        domanda: "Basi azotate presenti sui due filamenti del DNA",
        risposta: "Le quattro basi che sono presenti nel DNA sono l'adenina (abbreviata con la lettera A), la citosina (C), la guanina (G) e la timina (T)",
            materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Quali fattori possono causare il danneggiamento del DNA",
        risposta: "Tra gli agenti alteranti figurano ad esempio agenti ossidanti, agenti alchilanti ed anche radiazioni ad alta energia, come i raggi X e gli UV",
            materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
}




const Salva_domande_Storia = (utente_id, materia_id) => {
    //    funzione_set_aggiornamento(true)
    let domanda = {
        domanda: "Eventi che portarono allo scoppio della prima guerra mondiale",
        risposta: "La scintilla che fece scoppiare la guerra fu l’assassinio dell’arciduca d’Austria-Ungheria Francesco Ferdinando avvenuto a Sarajevo il 28 giugno 1914 ad opera di un nazionalista serbo. L’Austria dichiarò subito guerra alla Serbia, alleata della Russia, e come un fenomeno a catena tutte le nazioni europee si trovarono coinvolte nel conflitto generale.",
        materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Che cosa prevedeva la triplice allenza",
        risposta: "L'accordo prevedeva il soccorso da parte di Germania e Austria all'Italia nel caso questa fosse stata attaccata dalla Francia. Si stabiliva anche che, nel caso una delle contraenti fosse stata attaccata da due o più potenze nemiche le altre due assicuravano il loro soccorso. Inoltre, nel caso che una delle potenze firmatarie, minacciata, fosse stata costretta a iniziare una guerra, si assicurava la neutralità benevola da parte delle altre due.",
        materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
    domanda = {
        domanda: "Conseguenze sulla fine della prima guerra mondiale per l'impero ottomano",
        risposta: "L’Impero Ottomano firmò il Trattato di Sèvres il 10 agosto 1920, ponendo così fine alle ostilità con le potenze alleate; poco tempo dopo scoppiò la Guerra d’Indipendenza turca, a seguito della quale venne costituita la nuova Repubblica di Turchia che firmò, nel 1923, il Trattato di Losanna il quale annullava il precedente e, di fatto, smembrava il vecchio Impero Ottomano.",
        materia_id: materia_id,
        utente: utente_id
    }
    Salva_domanda_in_mongo_db(domanda)
}



const Salva_domanda_in_mongo_db = (domande) => {
  //    funzione_set_aggiornamento(true)
  console.log(domande)
  console.log("Salva_domanda_in_mongo_db")

  axios.post('/domande/salva', domande)
      .then((res) => {
          console.log(res.data)
      }).catch((error) => {
          console.log(error)
      });
}


let Salva_materia_in_mongo_db = (materia) => {
    //    funzione_set_aggiornamento(true)
    axios.post('/materie/salva', materia)
        .then((res) => {
            console.log(res.data)
            if (materia.materia === "Storia") {
                Salva_domande_Storia (materia.utente,res.data._id)
            }
            if (materia.materia === "Scienze") {
                Salva_domande_Scienze (materia.utente,res.data._id)
            }
            if (materia.materia === "Fisica") {
                Salva_domande_Fisica (materia.utente,res.data._id)
            }
        }).catch((error) => {
            console.log(error)
        });
}


  return (
    isAuthenticated && ( 
     <div  className="d-flex flex-row justify-content-between" >
      {/* id_utente1 */}
        <div>Ciao {user.name}</div>
        <img src={user.picture} alt={user.name}  width="25" height="25"  />
     
        {/* <p>{user.email}</p> */}
        {/* <JSONPretty data={user} /> */}
        {/* {JSON.stringify(user, null, 2)} */}
      </div>
    )
  )
}

export default Profile
