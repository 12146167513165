import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, ListGroup, Container, Row, Col, Modal, Form } from 'react-bootstrap';
import ColorPicker, { useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { SwatchesPicker, SketchPicker } from "react-color";
import Menu_per_materie from "./X9_1_menu_materie";
import Cambia_nome_materia from "./X9_2_rinomina_materia";


const Scegli_materie = (props) => {

    const [valore, set_funzione_valore] = useState();
    const [indice_materia, set_materia_scelta] = useState(0);
    const [show, setShow] = useState(false);
    const [color, setColor] = useColor("hex", "#121212");
    const id_utente = useSelector(state => state.variabile.value)

    const isLoggedIn = id_utente === "612fb32ad7c7ea0bf37cbe8f"
    const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");

    useEffect(() => {
        console.log('-XXX------ USEEFFECT ----------')
    }, []);


    const function_swap = (lista_play_aux) => {
    }



    const handleChange1 = (event) => {
        console.log('xxxx:' + event.key);

        if (event.key === 'Enter') {
            Salva_nuova_materia()
            set_funzione_valore('')
        }
        

    }

    const handleChange = (event) => {
        set_funzione_valore(event.target.value)
        console.log('yyyy:' + valore);
    }

    const Salva_nuova_materia = () => {
        console.log('do validate22' + valore);
        let  materia1 = {
            materia: valore,
            utente: id_utente
        }
        var lista_aux = props.lista_materie
        lista_aux.unshift({ materia: valore, accesa: true, colore_bordo: 'solid red' })
        props.SET_lista_materie([... lista_aux])
        set_funzione_valore('')
        axios.post('/materie/salva', materia1)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
    }
    
    function alertClicked2() {
        alert('You clicked the third ListGroupItem');
    }

    const function_prova = (index) => {
        let lista_aux = props.lista_materie
        lista_aux.splice(index, 1)
        props.set_funzione_aggiorna_lista(2)
    }


    function colorazione(index2) {
        var colore = 'black'
        var colore_bordo = 'solid red'
        if (props.lista_materie[index2].colore_bordo != undefined) {
            colore_bordo = '3px solid ' + props.lista_materie[index2].colore_bordo
        }

        var hStyle = { backgroundColor: 'lightgray', color: colore, margin: '5px', padding: '5px', border: colore_bordo, borderRadius: '2px' };
        return hStyle;
    }

    const Chiusura_della_finestra_modale = () => {
        setShow(false);
    }

    const apertura_finestra_modale = (index) => {
        set_materia_scelta(index)
        setShow(true)
    }

    const ottieni_colore = () => {
        var lista_materie_aux = props.lista_materie
        console.log(color)
        lista_materie_aux[indice_materia].colore_bordo = color.hex
        //        props.SET_lista_materie(lista_materie_aux)
        //        props.set_funzione_aggiorna_lista(2)
        setShow(false)
    }

    const cambiamento_colore = (colore_passato) => {
        console.log(colore_passato)
        setBlockPickerColor(colore_passato)

        const materia = {
            colore_sfondo: colore_passato
            
        }
        console.log(materia)
        axios.patch('/materie/modifica_colore/' + props.lista_materie[indice_materia]._id, materia)
            .then((res) => {
                console.log(res.data)
                props.SET_cambio_stato(!props.cambio_stato)

            }).catch((error) => {
                console.log(error)
            });

    }

    function Cancella_materia() {
        //        axios.post('http://localhost:3001/create', newNote)
        console.log('Richiesta di cancellazione materia')

        axios.delete('/materie/cancella/' + props.lista_materie[indice_materia]._id)
            .then((res) => {
                console.log(res.data)
                var aux = props.cambio_stato
                props.SET_cambio_stato(!aux)
        
            }).catch((error) => {
                console.log(error)
            });
    }


    function colorazione(item) {
        let hStyle
        if (item.accesa) {
        hStyle = {
            backgroundColor: item.colore_sfondo, color: 'black', margin: '1px',
            padding: '1px', border: 'lightgreen', borderRadius: '2vmin'
        };
        }
        if (!item.accesa) {
            hStyle = {
                backgroundColor: "white", color: 'gray', margin: '1px',
                padding: '1px', border: 'lightgreen', borderRadius: '2vmin'
            };
            }
        return hStyle;
    }


    return (
        <div >

            <Modal
                show={show}
                onHide={Chiusura_della_finestra_modale}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Materia:  {props.lista_materie[indice_materia].materia}  
                            
                            </Form.Label>
                            <p> {props.lista_materie[indice_materia].colore_sfondo} </p>

                            <Cambia_nome_materia item={props.lista_materie[indice_materia]} /> 
                            {isLoggedIn && (
                                <p>
                                    {props.lista_materie[indice_materia]._id} 
                                </p>
                            )}
                            <Button variant="secondary" onClick={() => Cancella_materia()}>
                            Cancella
                    </Button>
                        <SwatchesPicker color={blockPickerColor}  onChange={(color) => {cambiamento_colore(color.hex); }} />
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => Chiusura_della_finestra_modale()}>
                        Annulla
                      </Button>
                    <Button variant="primary" onClick={() => ottieni_colore()}>Salva</Button>
                </Modal.Footer>
            </Modal>
            <form>
                <div class="d-flex flex-row flex-fill">
                <div class="d-flex flex-fill">

                <input type="text" placeholder="Nuova materia" value={valore} onKeyDown={handleChange1}  onChange={handleChange} />
                </div>
                <Button variant="outline-primary" onClick={() => Salva_nuova_materia()}>Salva</Button>{' '}
                </div>
            </form>
            <ListGroup>
                {props.lista_materie.map(function (item, index2) {
                    {
                        //                        console.log(item)
                        return <div class="d-flex flex-row-reverse">
                                    <div style={colorazione(item)}   >
                                    <Button variant="outline-primary" onClick={() => apertura_finestra_modale(index2)}>Modifica</Button>{' '}
                                    </div>
                             
                                    <div style={colorazione(item)} class="d-flex flex-fill justify-content-between" >
                                        <div class="d-flex align-self-center">                            
                                                {item.materia}
                                        </div>
                                        <Menu_per_materie item={item} index_passato={index2} 
                                                    lista_materie={props.lista_materie} 
                                                    SET_lista_materie = {props.SET_lista_materie}
                                                    SET_cambio_stato = {props.SET_cambio_stato}
                                                    cambio_stato = {props.cambio_stato} 
                                                    />
                                    </div>
                                </div>
                    }
                })
                }

            </ListGroup>
        </div>
    )
}
export default Scegli_materie;