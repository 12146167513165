import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import Gestione_immagini from "./X15_8_gestione_immagini.jsx";
import JSONPretty from 'react-json-pretty';
import {Form, Button } from 'react-bootstrap';
import axios from 'axios';



function removeObjectWithId(arr, _id) {
    const objWithIdIndex = arr.findIndex((obj) => obj._id === _id);
    if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
    }
    return arr;
}


const Risposta_singola = (props) => {
//    const [somma, calcola_somma] = useState('');

    const [risposta_singola, SET_risposta_singola] = useState(props.risposta_singola);


    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
        SET_risposta_singola(props.risposta_singola)
    }, [props.risposta_singola])
    
    function handleChange(event) {
        const { name, value } = event.target;
        SET_risposta_singola(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }



    const salva_domanda_singola = () => {
        console.log('salva_domanda_singola')
        if (props.salva === "nuova") {
            // devo creare un numero random per lavorare
      
        
            console.log('nuova')
            axios.put('/domande/aggiungi_risposta_multipla/' + props.domanda._id + '/' + risposta_singola.risposta)
            .then((res) => {
                console.log('Aggiunta la risposta multipla')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });

            let lista_aux = props.lista_risposte_multiple
            lista_aux.push(risposta_singola)
            console.log(lista_aux)
            props.set_lista_risposte_multiple(oldArray => [... lista_aux])  
            

        }

        if (props.salva === "aggiorna") {
            axios.patch('/domande/aggiorna_risposta_multipla/' + props._id + '/' + props.indice + '/' + risposta_singola.risposta)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });
            console.log('aggiorna')
            console.log(props.indice)

            let lista_aux = props.lista_risposte_multiple
            lista_aux[props.indice]=risposta_singola
            console.log(lista_aux)
            props.set_lista_risposte_multiple(oldArray => [... lista_aux])  

        }
    }

    const cancella_risposta_singola =  ()  => {
   
        
        let lista = props.lista_risposte_multiple
        console.log('CANCELLAZIONE')
        console.log(lista)
        let arr = removeObjectWithId(lista, risposta_singola._id);
        console.log(arr)
        
        props.set_lista_risposte_multiple(oldArray => [... arr])  


        axios.patch('/domande/cancella_risposta_multipla/' + props.domanda._id + '/' + props.risposta_singola._id)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log('errore')
                console.log(error)
            });
        //        props.SET_variab_aggiorna(!aux)
//        props.set_cambio_stato1(current => !current)
    }


    return (
        <div>
			<Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <p> 
                    <Form.Label>Risposta</Form.Label>
                    <Form.Control as="textarea" name="risposta" value={risposta_singola.risposta} onChange={handleChange} rows={3} />
                    <Gestione_immagini  domanda={props.domanda} indice={props.indice} 
                                        risposta_singola={risposta_singola}
                                        SET_risposta_singola = {SET_risposta_singola}  
                                        salva="multipla" />       
                    </p>                               
                </Form.Group>
                <Button variant="primary" onClick={() => salva_domanda_singola()}>Salva</Button>
                <Button variant="primary" onClick={() => cancella_risposta_singola()}>Cancella</Button>

            </Form>
            {(props.account_dati.utente_debug) &&
          
                <JSONPretty data={risposta_singola} />
            }
       </div>
    )
}
export default Risposta_singola;