import React, { useState, useEffect, useRef } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { ListGroup, Button, Row, Col} from 'react-bootstrap';
import axios from "axios";
import {CurrentStreamTime} from "./CurrentStreamTime";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Lista_componenti_bookmark from "./Lista_componenti_bookmark.jsx";

import { BsTruckFlatbed } from 'react-icons/bs';
import ringer from "../compon_mp3/camera2.wav";
import ringer_jump from "../compon_mp3/jump.mp3";
import ringer_quite from "../compon_mp3/quite.mp3";
import ringer_crash from "../compon_mp3/crash.mp3";
import ringer_scroll from "../compon_mp3/scroll.mp3";
import ringer_wes from "../compon_mp3/wes.mp3";
import ringer_invalid from "../compon_mp3/invalid.mp3";
const { colorazione } = require('../funzioni_comuni_colorazione_video')


const Gestione_mp3 = (props) => {
//    const [somma, calcola_somma] = useState('');
    const [playing, setPlaying] = useState(false);
    const [url, SET_url] = useState('');
    
    const[currentTime2_s, setCurrentTime2_s] = useState('');
    const [colore_background, SET_colore_background] = useState('primary');

    const player = new Audio(props.item.link);
    const reactPlayerRef = useRef();
    const [edit_testo, SET_edit_testo] = useState(false);
    const edit_testo_ref = useRef(false);
    const audio_ring = new Audio(ringer);
    const audio_jump = new Audio(ringer_jump);
    const audio_quite = new Audio(ringer_quite);
    const audio_crash = new Audio(ringer_crash);
    const audio_scroll = new Audio(ringer_scroll);
    const audio_wes = new Audio(ringer_wes);
    const audio_invalid = new Audio(ringer_invalid);


  

    useEffect(() => {
        if (props.item._id != props.Video_id_selezionato) {
//            console.log('vvvvvvvvvvvvvvvvvvvvvvvv')
//            console.log(props.item._id)
//            console.log(props.Video_id_selezionato)
            reactPlayerRef != null && reactPlayerRef.current.audio.current.pause()
        }
        console.log('31add edit_testo ' + edit_testo) 
    }, [props.Video_id_selezionato]);


    useEffect(() => {
    
    }, [props.item._id]);


    useEffect(() => {
//        console.log('YYYYYYYY3 ' + props.trigger_per_bookmark) 
   
        if (props.trigger_per_bookmark == 1 && 
            props.item._id == props.Video_id_selezionato && 
            !edit_testo && !props.modifica_dati_file_multimediale) {

            imposta_bookmark(1)
        } 
        if (props.trigger_per_bookmark == 3 && 
            props.item._id == props.Video_id_selezionato && 
            !edit_testo && !props.modifica_dati_file_multimediale) {

            imposta_bookmark(2)
        } 
        if (props.trigger_per_bookmark == 2 && 
            props.item._id == props.Video_id_selezionato && 
            !edit_testo && !props.modifica_dati_file_multimediale) {
            reactPlayerRef.current.audio.current.currentTime = reactPlayerRef.current.audio.current.currentTime - 25
            audio_quite.play()
        } 
        props.SET_trigger_per_bookmark(0) 

 
    }, [props.trigger_per_bookmark]);


    useEffect(() => {

        if (props.item.aws_filename != undefined && props.item.aws_filename.includes('.mp3')) SET_url(props.item.link)
    
        }, [props.item.link,props.item.aws_key ]);


  


    function imposta_bookmark (tipo_chiamata) {
        // Using the callback version of `setState` so you always
        // toggle based on the latest state
        audio_ring.play()
        console.log('XXXXXXXX preso ' + tipo_chiamata)
        props.SET_counter_sleep_telefonino(0)
        
       
//        if (edit_testo) return;
        if (reactPlayerRef.current == null) return;
//        console.log(reactPlayerRef.current.audio.current.currentTime)
//        console.log(player.currentTime)
//        console.log(props.item._id);

        let segnalibro = {
            nota: "",
            tempo: reactPlayerRef.current.audio.current.currentTime,
            tempo_finale: reactPlayerRef.current.audio.current.currentTime + 30
        }
        if (reactPlayerRef.current.audio.current.currentTime > 20) {segnalibro.tempo = reactPlayerRef.current.audio.current.currentTime - 20} 


        if (tipo_chiamata == 2) {segnalibro.solo_ascolto = true}
//        console.log(segnalibro)
        //        axios.post('http://localhost:3001/create', newNote)
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
        axios.put('/video/aggiungi_bookmark/' + props.Video_id_selezionato, segnalibro)
            .then((res) => {
                console.log('Aggiunto un bookmark')
                console.log(res.data)
                console.log('bookmark _id assigned is: %s', res.data.elenco_bookmark[res.data.elenco_bookmark.length-1]._id);
                props.SET_Bookmark_id_selezionato(res.data.elenco_bookmark[res.data.elenco_bookmark.length-1]._id)
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });
//        var aux = props.cambio_stato
//        props.SET_cambio_stato(!aux)
        props.SET_variab_aggiorna(current => !current)
    }




    function handle_seek_mp3(item_array) {
        //        console.log(tempo_salto)
                
        reactPlayerRef.current.audio.current.currentTime = item_array.tempo
        reactPlayerRef.current.audio.current.play()
        props.SET_Video_selezionato(props.item._id)

        props.SET_Bookmark_id_selezionato(item_array._id)
    }



    function onMouseMove() {
//        console.log('mouse sopra')
    }


    const Correggi_punto_inizio = (id_array) => {
        let tempo_istante = reactPlayerRef.current.audio.current.currentTime 
        
          
        console.log('ciaoerrore')
        axios.patch('/video/correggi_punto_inizio_video/' + props.item._id + '/' + id_array + '/' + tempo_istante)
        .then((res) => {
            console.log(res.data)

        }).catch((error) => {
            console.log('errore')

            console.log(error)
        });
    //        props.SET_variab_aggiorna(!aux)
   
        props.SET_variab_aggiorna(current => !current)
    }

    function onPlay(e) {

        props.SET_Video_selezionato(props.item._id)
//        SET_play_stato(true)
//      player.currentTime = tempo_salto
        console.log('XXXXXXXX preso ' + props.item._id)

    }

    function onProgress(e) {
        if (reactPlayerRef.current != undefined) {
            setCurrentTime2_s(reactPlayerRef.current.audio.current.currentTime)
        }
//        console.log('XXXXXXXX preso ' + props.item._id)

        if (props.item._id != props.Video_id_selezionato) {
//            SET_play_stato(false)
        }
//      player.currentTime = tempo_salto
    }


    function carica_url_per_aws(e) {
//        console.log('yyyyyyyyyyyyyyyyyyXXXXXXXX preso ' + props.item)
//        console.log(props.item)
        if (props.item.aws_key != undefined ) {
            
            if (!url.includes('walkmind')) {
//                console.log('XXXXXXXX preso ' + props.item._id)
//                console.log(url)

                axios.get('/transfer_s3_mp3/file_multimedia_ottieni_url/' + props.item.aws_key)
    //            axios.get('/transfer_s3_mp3/file_mp3_ottieni_url/' + 'ca32b2e50d177b9ce41c0cd148407f5')
                .then((res) => {
//                    console.log(res)
                    SET_url(res.data)
                }).catch((error) => {
                    console.log('error')
                    console.log(error)
                });
            }
        }
    }

    return (
        <div onMouseMove={onMouseMove} >
            {url.includes('amazonaws') && <> Link Amazon trovato </>}
            - Tempo {currentTime2_s} 
            <AudioPlayer
                ref={reactPlayerRef} 
                autoPlay = {false}
                src = {url}
                autoPlayAfterSrcChange={false}
                onPlay={onPlay}
                onListen = {onProgress}
                onLoadStart = {carica_url_per_aws}
                // other props here
            />
            {/*
            <div className='currentTime'>

                  <p>⏱ {currentTime2}  ⏱ {currentTime2_s} </p>
            </div>
            */}

            {props.item._id == props.Video_id_selezionato && 
                <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" onClick={() => imposta_bookmark(1)}> Bookmark 2 </Button>
                </div>
            }
            
            <div >
                {edit_testo && <h1> EDIT TRUE </h1>}
                {edit_testo_ref.current && <h1> REF EDIT TRUE </h1>}

 
                
                {props.item.elenco_bookmark.map(function (item_array, index2) {
                    {
                        return <div className={colorazione(item_array, colore_background, currentTime2_s)}>

                            <Lista_componenti_bookmark item_array={item_array} index_lista_bookmark = {index2}
                                                       Video_id_selezionato={props.Video_id_selezionato}
                                                       Bookmark_id_selezionato = {props.Bookmark_id_selezionato} 
                                                       video_id={props.item._id} 
                                                       currentTime2_s = {currentTime2_s}
                                                       SET_colore_background = {SET_colore_background}
                                                       colore_background = {colore_background}
                                                       trigger_per_bookmark = {props.trigger_per_bookmark} 
                                                       SET_trigger_per_bookmark = {props.SET_trigger_per_bookmark}
                                                       reactPlayerRef={reactPlayerRef} 
                                                       SET_variab_aggiorna={props.SET_variab_aggiorna} 
                                                       edit_testo={edit_testo} SET_edit_testo={SET_edit_testo} />
                           
                            
                                
                             
                            {/*
                                <Button variant="outline-primary" onClick={() => Correggi_punto_inizio(item_array._id)}>correggi</Button>{' '}
                            */}
                                <Button variant="outline-primary" onClick={() => handle_seek_mp3(item_array)}>Play</Button>
                           
                                 
                        </div>
                    }
                }).reverse()
                }
            </div>
          
        </div>
    )
}
export default Gestione_mp3;