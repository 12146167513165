import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import {Button, Modal, Form, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
//import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import axios from 'axios';


 
const Gestione_immagini = (props) => {
//    const [somma, calcola_somma] = useState('');
    const [file, setSelectedFile] = useState('')
    const [description, setDescription] = useState("")
    const [key_presente, set_key_presente] = useState('');
    const [key_aws, set_key_aws] = useState('');
    const [mostra_pulsante_carica, set_mostra_pulsante_carica] = useState(false);



    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);
        set_mostra_pulsante_carica(true)
    }
 
    async function Invia_file_al_bucket_S3() {
//        const result = await salva_file_server_s3({ image: file, description })
        console.log('INVIA AL BUCKET')
        console.log('salva ' + props.salva)
        console.log('key ' + props.key)
/*        
        axios.post('/transfer_s3/file_jpg_cancella/' + props.item.indirizzo_S3_immagine_domanda.key)
        .then((res) => {
            console.log('Cancellato un file pdf')
        }).catch((error) => {
            console.log('errore nella richiesta')
            console.log(error)
        });
*/    
        const formData = new FormData();
        formData.append("image", file)
        formData.append("description", description)
        const result = await axios.post('/transfer_s3/file_jpg', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        console.log('tornato nel front end')
        console.log(result)
//        set_key_aws (result.data.key)
        console.log(file)
        console.log(file.name)
        const nuova_immagine_s3 = {
            key:  result.data.key,
            descrizione: 'descrizione'
        }

        if (props.salva === "domanda") { 
            axios.patch('/domande/modifica_immagine_domanda_S3/' + props.domanda._id, nuova_immagine_s3)
            .then((res) => {
                console.log(res.data)
//                props.SET_cambio_stato(!props.cambio_stato)
            }).catch((error) => {
                console.log(error)
            });
        }
        if (props.salva === "multipla") {
            console.log('lista_risposte_multiple')

/*           
            if (props.lista_risposte_multiple.length === props.key+1000) {
                let input = {risposta: ''}

                axios.put('/domande/aggiungi_risposta_multipla/' + props.domanda._id + '/' + input.risposta)
                .then((res) => {
                    console.log('Aggiunta la risposta multipla')
                }).catch((error) => {
                    console.log('errore nella richiesta')
                    console.log(error)
                });
            }
*/

            axios.patch('/domande/aggiorna_risposta_multipla_key/' + props.domanda._id + '/' + props.indice + '/' + result.data.key)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });

            let updatedValue = {};
            updatedValue = {"key":result.data.key};
            props.SET_risposta_singola(prevInput => ({
     
                ...prevInput,
                ...updatedValue
            }))


        }
    }

    const Cancella_immagine_dal_bucket_S3 = async event => {
        console.log('CANCELLA DAL BUCKET')
        console.log('salva' + props.salva)
        let key
        if (props.salva === "domanda") key = props.domanda.indirizzo_S3_immagine_domanda.key
        if (props.salva === "multipla") key = props.risposta_singola.key
        const result = await axios.post('/transfer_s3/file_jpg_cancella/' + key)
        console.log('tornato nel front end')
        console.log(props.salva)
        console.log(result)
        let key_passato = 'cancella'
        const nuova_immagine_s3 = {
            key:  '',
            descrizione: 'descrizione'
        }
        console.log(nuova_immagine_s3)
        if (props.salva === "domanda") { 
            axios.patch('/domande/modifica_immagine_domanda_S3/' + props.domanda._id, nuova_immagine_s3)
            .then((res) => {
                console.log(res.data)
                props.SET_cambio_stato(!props.cambio_stato)
            }).catch((error) => {
                console.log(error)
            });
        }  
        if (props.salva === "multipla") {
            console.log('CANCELLA DAL BUCKET multipla')
            axios.patch('/domande/aggiorna_risposta_multipla_key/' + props.domanda._id + '/' + props.indice + '/' + key_passato)
            .then((res) => {
                console.log(res.data)
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    function Inserisci_immagine_se_presente  () {
//        console.log('RRRRRRRRRR  Inserisci_immagine_se_presente')
     
        let btn = "XXXX non definito"
//        console.log (props.salva)
//        console.log('MOSTRA IMMAGINE')
//        console.log('salva' + props.salva)
//        if (props.salva === "multipla") console.log('salva' + props.risposta_singola.key)
        
        let key
        if (props.salva === "domanda") key = props.domanda.indirizzo_S3_immagine_domanda.key
        if (props.salva === "multipla") key = props.risposta_singola.key
        if (key === undefined) {return btn}
    //    set_key_presente(key)
    //        console.log('item.indirizzo_S3_immagine_domanda.key')
    //        console.log(item.indirizzo_S3_immagine_domanda.key)
        btn = "/transfer_s3/images/" + key
        return <> 
            <img src={btn} className='img-fluid shadow-4' alt='...' />
            </>
    }

    return (
        <div>
			{Inserisci_immagine_se_presente()}              
            {("" === "") &&
            <>
                <input type="file" onChange={(e) => handleFileInput(e)}/>

            {(mostra_pulsante_carica) &&
                <Button onClick={() => Invia_file_al_bucket_S3()}> Carica</Button>
            }
            </>
            }
            {(props.salva === "domanda" && props.domanda.indirizzo_S3_immagine_domanda.key !== '') &&
                <button onClick={() => Cancella_immagine_dal_bucket_S3()}> Erase1 </button>
            }
            {(props.salva === "multipla" && props.risposta_singola.key !== undefined ) &&
                <Button onClick={() => Cancella_immagine_dal_bucket_S3()}> Erase2 </Button>
            }
        </div>
    )
}
export default Gestione_immagini;





     