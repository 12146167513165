import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import axios from "axios";
import { Form, ListGroup, Row, Col} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'

 
const Gestione_bug_bookmark = (props) => {
    //    const [somma, calcola_somma] = useState('');
    
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const id_utente = useSelector(state => state.variabile.value)
   
    useEffect(() => {
        // This effect uses the `value` variable,
        // so it "depends on" `value`.
        setIsSwitchOn(props.item_array.bookmark_messo_in_bug)
    }, [props.item_array.bookmark_messo_in_bug])


    
    const onSwitchAction = (classe_id, index2) => {
 
    axios.patch('/video/metti_bookmark_in_bug/' + props.item_array._id + '/' + !isSwitchOn)
        .then((res) => {
        console.log(res.data)

    }).catch((error) => {
        console.log('errore')

        console.log(error)
    });


    setIsSwitchOn(current => !current)
    };



return (<>
    {props.item_array.bookmark_messo_in_bug && 
        <div className="p-2 mb-2 bg-warning text-dark" >
        
            <Form.Switch
                onChange={() => onSwitchAction(1, 2)}
                label=""
                disabled={false}
                checked={isSwitchOn}
            />
        
        </div>
    }
    </>
)
}
export default Gestione_bug_bookmark;