import React, { useState, useEffect, useRef } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Container, Row, Col, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Gestione_file_mp3 = (props) => {
//    const [somma, calcola_somma] = useState('');
const [playing, setPlaying] = useState(false);
const[bookmark_estratto_stato, SET_bookmark_estratto_stato] = useState({});

const[currentTime1, setCurrentTime1] = useState('00:00');
const[currentTime2_s, setCurrentTime2_s] = useState('');

const[currentTime2, setCurrentTime2] = useState('00:00')
const reactPlayerRef = useRef();


useEffect(() => {
    console.log(playing)
// idroslab    
    const bookmark_estratto = props.item.elenco_bookmark.filter(row => row._id === props.id_bookmark).pop();
//    console.log(bookmark_estratto.tempo)

    if (bookmark_estratto != undefined) {
        SET_bookmark_estratto_stato(bookmark_estratto)
    
        console.log(reactPlayerRef)
        if (reactPlayerRef.current != null) reactPlayerRef.current.audio.current.currentTime = bookmark_estratto.tempo;
        
    }
//    reactPlayerRef.current.audio.current.play();
 
//    reactPlayerRef.current.(bookmark_estratto.tempo, "seconds");
   
     // This is cleanup of the effect
}, []);



const onPlay = () => {
    console.log(reactPlayerRef.current)
    const intervallo = bookmark_estratto_stato.tempo_finale - bookmark_estratto_stato.tempo 

    setTimeout(() => {
        console.log("Delayed for 1 second.");
        reactPlayerRef.current.audio.current.pause();
      }, 1000*intervallo);    

}


    return (
        <>
            {props.item.link != '' && <> 
            
                <AudioPlayer
                ref={reactPlayerRef} 
                autoPlay = {false}
                defaultCurrentTime = "01:30"
                src = {props.item.link}
                onPlay={onPlay}
                // other props here
            />
            Durata Frame {bookmark_estratto_stato.tempo_finale - bookmark_estratto_stato.tempo}

            
            </>}
            
        </>
    )
}
export default Gestione_file_mp3;