import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Input, Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import axios from "axios";

const Modifica_dati_video = (props) => {

    const [change_effettuato, SET_change_effettuato] = useState(false);
    const [change_effettuato_solo_testo, SET_change_effettuato_solo_testo] = useState(false);

    const [file, setFile] = useState()
    const [show_spinner, SET_show_spinner] = useState(false);

    const [input, setInput] = useState({
        nota: '',
    });

    useEffect(() => {
         setInput(prevInput => {
            return {
                ...prevInput,
                'link': props.item.link,
                'nome': props.item.nome,
                'tipo': props.item.tipo
            }
        })
        
    }, [props.item]);


    async function uploadFiles(e) {
        e.preventDefault()
       
        let input_file = e.target;
        console.log(e);
        setFile(e.target.files[0])
    //    SET_show_spinner (false) 
        SET_change_effettuato(true)
    }



    function handleChange(event) {
        event.preventDefault();
       
        const { name, value } = event.target;
        console.log('sssssss')

        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
        SET_change_effettuato_solo_testo(true)
    }


    async function handleClick(event) {
        event.preventDefault();
        let result = null
        let aws_key
        let aws_filename = ''
        let stringa_per_chiamata_server = ''
    
        if (file != undefined) {
            SET_show_spinner (true) 
            
            if (props.item.aws_key != undefined) {
                const result2 = axios.post('/transfer_s3_mp3/file_multimedia_cancella/' + props.item.aws_key)
            }
            const formData1 = new FormData();
            formData1.append("file_mp3", file)
            formData1.append("nome", file.name)
    
            const result1 = await axios.post('/transfer_s3_mp3/file_multimedia', formData1, { headers: { 'Content-Type': 'multipart/form-data' } })
                console.log('tornato nel front end')
                console.log(result1.data.key)
            aws_key = result1.data.key
            aws_filename = file.name
            SET_show_spinner (false)  
        }
    
        let tipo = input.tipo
        let after_ = input.link  
        console.log('entra');
        if (input.link != null && input.link.includes('youtube') && input.link.includes('=')) {
             
            after_ = input.link.substring(input.link.indexOf('=') + 1);
            console.log(after_);
            tipo = 'youtube'
        }
        if (input.link != null && input.link.includes('youtu.be')) {
            console.log('entra2');
             
            after_ = input.link.substring(input.link.indexOf('be/') + 3);
            console.log(after_);
            tipo = 'youtube'
        }
        if (input.link != null && input.link.includes('mp3')) {
            after_ = input.link;
            tipo = 'mp3'
        }

        if (input.link != null && input.link.includes('mp4')) {
            after_ = input.link;
            tipo = 'video'
        }
        if (input.link != null) {
            if (input.link.includes('real_time') || input.link.includes('real time') || input.link.includes('realtime')) {
                after_ = input.link;
                tipo = 'real_time'
            }
        }
        if (aws_filename.includes('mp3')) {
            after_ = '';
            tipo = 'mp3'
        }
        if (aws_filename.includes('mp4')) {
            after_ = '';
            tipo = 'video'
        }

        let nuovo_video = {
            nome: input.nome,
            link: after_,
            tipo: tipo,
            aws_key: aws_key,
            aws_filename: aws_filename
        }
 
        if (nuovo_video.nome == null) nuovo_video.nome = aws_filename
    
        axios.patch('/video/video_modifica_dati/' + props.item._id, nuovo_video)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
        SET_change_effettuato(false)
        SET_change_effettuato_solo_testo(false)
        props.SET_variab_aggiorna(current => !current)
        props.SET_modifica_dati_file_multimediale(false)
    }



    async function handleClick_solo_testo(event) {
        event.preventDefault();

        let result = null
        let aws_key
        let aws_filename = ''
        let stringa_per_chiamata_server = ''

        let tipo = input.tipo
        let after_ = input.link  
        console.log('entra');
        if (input.link != null && input.link.includes('youtube') && input.link.includes('=')) {
             
            after_ = input.link.substring(input.link.indexOf('=') + 1);
            console.log(after_);
            tipo = 'youtube'
        }
        if (input.link != null && input.link.includes('youtu.be')) {
            console.log('entra2');
             
            after_ = input.link.substring(input.link.indexOf('be/') + 3);
            console.log(after_);
            tipo = 'youtube'
        }
        if (input.link != null && input.link.includes('mp3')) {
            after_ = input.link;
            tipo = 'mp3'
        }

        if (input.link != null && input.link.includes('mp4')) {
            after_ = input.link;
            tipo = 'video'
        }
        if (input.link != null) {
            if (input.link.includes('real_time') || input.link.includes('real time') || input.link.includes('realtime')) {
                after_ = input.link;
                tipo = 'real_time'
            }
        }
        let variabili = {
            nome: input.nome
     

        }
        axios.patch('/video/video_modifica_dati/' + props.item._id, variabili)
        .then((res) => {
            console.log(res.data)
        }).catch((error) => {
            console.log(error)
        });
        SET_change_effettuato(false)
        SET_change_effettuato_solo_testo(false)
        props.SET_variab_aggiorna(current => !current)
        props.SET_modifica_dati_file_multimediale(false)
    }


//    const [somma, calcola_somma] = useState('');
    return (
    <>
        {props.Video_id_selezionato == props.item._id && props.modifica_dati_file_multimediale && <> 
            <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Seleziona un file multimediale da caricare</Form.Label>
                    <Form.Control type="file" onChange={(e) => uploadFiles(e)} accept="audio/*;video/*"/>
            </Form.Group>
            <input onChange={handleChange} placeholder="Nome" name="nome" value={input.nome} autoComplete="off" className="form-control"></input>
  
            <input onChange={handleChange} placeholder="Link" name="link" value={input.link} autoComplete="off" className="form-control"></input>
            
            {change_effettuato  &&   <div className='d-flex justify-content-end'> 
                                        <Button variant="primary" onClick={handleClick}> Save         </Button>
                                     </div>}
            {change_effettuato_solo_testo  &&   <div className='d-flex justify-content-end'> 
            <Button variant="danger" onClick={handleClick_solo_testo}> Save testo         </Button>
            </div>}
           {show_spinner  && <Spinner animation="border" role="status">
                       <span className="visually-hidden">Loading...</span>
            </Spinner>}
         </> }

        {props.item._id != props.Video_id_selezionato && <>
            <ul>
                <li>Titolo: {input.nome} </li> 
                <li>Nome del File: {props.item.aws_filename} </li> 
            </ul> 
        </> }
        {props.item._id == props.Video_id_selezionato && <>
            <h5>
            <ul>
                <li>Titolo: {input.nome} </li> 
                <li>Nome del File: {props.item.aws_filename} </li> 
                <li>Sleep telefono: {props.counter_sleep_telefono} </li> 

            </ul>
            </h5> 
        </> }

    </>

    )
}
export default Modifica_dati_video;