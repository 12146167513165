import React, { useState } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"

const Trasforma_in_minuti_secondi = (props) => {
//    const [somma, calcola_somma] = useState('');


    function Mostra_ore_minuti_secondi(tempo_secondi) {
        const ore = Math.floor(tempo_secondi / 3600);
    
        const minutes = Math.floor(tempo_secondi / 60 - ore * 60);
        const seconds = Math.floor(tempo_secondi - minutes * 60);
        const currentTime = ' ⏱ ' + str_pad_left(ore,'0',2) + ':' + str_pad_left(minutes,'0',2) + ':' + str_pad_left(seconds,'0',2);

            return <>
                {currentTime}
            </>
    }

    const str_pad_left = (string,pad,length) => {
        return (new Array(length+1).join(pad)+string).slice(-length);
    } 


    return (
        <>
             {Mostra_ore_minuti_secondi(props.tempo_in_secondi)}      
                              
        </>
    )
}
export default Trasforma_in_minuti_secondi;