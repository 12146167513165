import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Input, Container, Row, Col, Button, Form } from 'react-bootstrap';
import axios from "axios";


const Nota_per_bookmark = (props) => {

    const [input, setInput] = useState({
        nota: '',
    });
    

    useEffect(() => {
         setInput(prevInput => {
            return {
                ...prevInput,
                nota: props.item_array.nota
            }
        })

        if (props.item_array.nota == undefined) {
        setInput(prevInput => {
            return {
                ...prevInput,
                nota: ''
            }
        })
        }
        
    }, [props.item_array._id]);



    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
     
        setInput(prevInput => {
            return {
                ...prevInput,
                [name]: value, 
                input_interessato: true
            }
        })
// lo metto perche potrei anche non passarlo        

        if (props.edit_testo == false && typeof props.SET_edit_testo == 'function') props.SET_edit_testo(true)
    }


    function handleClick(event) {
        event.preventDefault();

// lo metto perche potrei anche non passarlo        
        if (typeof props.SET_edit_testo == 'function') {

            props.SET_edit_testo(false)
        }

        setInput(prevInput => {
            return {
                ...prevInput,
                input_interessato: false
            }
        })
        console.log('ID passato per nota bookmark' + props.item_array._id)
        axios.patch('/video/correggi_nota_nel_bookmark/' + props.item_array._id + '/' + input.nota)
        .then((res) => {
            console.log(res.data)

        }).catch((error) => {
            console.log('errore')

            console.log(error)
        });
        //            variab_aggiorna = {variab_aggiorna} SET_variab_aggiorna={SET_variab_aggiorna}
    }
//    const [somma, calcola_somma] = useState('');
    return (
    <>
        <Form.Group controlId="exampleForm">
    
        {props.video_id == props.Video_id_selezionato && props.edit_testo && input.input_interessato && <> 
            <Button variant="primary" onClick={handleClick}> Save   </Button>

        </> }
        {props.video_id == props.Video_id_selezionato && <> 
            <Form.Control type="text" name="nota" value={input.nota} onChange={handleChange} placeholder='Crea una nota'/>

        </> }
        </Form.Group>

        {props.video_id != props.Video_id_selezionato && <> {input.nota} </> }
    </>

    )
}
export default Nota_per_bookmark;