import React, { useState, useRef } from 'react';

import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import Barcode from "./barcode.png";
import { renderToString } from "react-dom/server";
import ReportTemplate from './X31_prova2';


const ShippingLabel = () => {

    // const createPDF = async () => {
    //   const pdf = new jsPDF("portrait", "pt", "a4");
    //   const data = await document.querySelector("#pdf");
    //   pdf.html(data).then(() => {
    //     pdf.save("shipping_label.pdf");
    //   });
    // };

    const handleDownload2 = () => {
      let htmlElement = <div>Hello </div> 
      let elementAsString = renderToString(htmlElement);
      var doc = new jsPDF();
      doc.html(elementAsString, {
        callback: function (doc) {
          doc.save("test.pdf");
        },
        x: 10,
        y: 10,
      });
    
    };



  return (		
    <div>
    <header >
        <div>l kldfjlkasjfld asjflkajf ljfasd'flksdasjf lsdasjfsadf</div>
        <div>l kldfjlkasjfld asjflkajf ljfasd'flksdasjf lsdasjfsadf</div>
        <div>l kldfjlkasjfld asjflkajf ljfasd'flksdasjf lsdasjfsadf</div>
        <div>l kldfjlkasjfld asjflkajf ljfasd'flksdasjf lsdasjfsadf</div>
        <div>l kldfjlkasjfld asjflkajf ljfasd'flksdasjf lsdasjfsadf</div>
        <div>l kldfjlkasjfld asjflkajf ljfasd'flksdasjf lsdasjfsadf</div>
    </header>
    <footer>
        <button onClick={handleDownload2}>Download2</button>

    </footer>
</div>
 
  );

};

export default ShippingLabel;