import React, { useState, useEffect } from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'

import Gestione_Materiale from './020_Materiale_x0_0';
import Gestione_Programmi from './010_Programma_x0_0';
import JSONPretty from 'react-json-pretty';


const Link_gestione_materiale = (props) => {
    const [programma_selezionato, SET_programma_selezionato] = useState('');
    const [lista_classi_per_programma, SET_lista_classi_programma] = useState([]);
    const [aggiornamento, SET_aggiornamento] = useState(false);
 
    const id_utente = useSelector(state => state.variabile.value)
 
    const { user } = useAuth0();
 
    useEffect(() => {
        console.log('USEEFFECT')
        console.log(programma_selezionato)
       
        axios.get('/classi/classi_carica_tutti_programma/' + id_utente + '/' + programma_selezionato)
        .then((res) => {
            console.log('CLASSI appena fecciate da mongo')
            SET_lista_classi_programma(res.data)
        }).catch((error) => {
            console.log(error)
        });
    }, [programma_selezionato, aggiornamento]);

    
    return (
        <div>
            {(props.account_dati.utente_debug) &&
                <JSONPretty data={props.account_dati} />
            }
            
                
            <Gestione_Programmi SET_programma_selezionato={SET_programma_selezionato} 
                                programma_selezionato ={programma_selezionato}
                                SET_aggiornamento ={SET_aggiornamento} 
                                account_dati = {props.account_dati} />
            
            <Gestione_Materiale 
            lista_classi_per_programma = {lista_classi_per_programma}
            programma_selezionato ={programma_selezionato} 
            SET_aggiornamento ={SET_aggiornamento} 
            account_dati = {props.account_dati} 
            aggiornamento={aggiornamento}/>
        </div>
    )
}
export default Link_gestione_materiale;

