import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ScrollInfoProvider } from '@faceless-ui/scroll-info';


import { Provider } from 'react-redux';
import {store} from './REDUX_app/store'

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;


ReactDOM.render(
  <Provider store={store}>
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={window.location.origin}>
      <ScrollInfoProvider>
      <HashRouter>
          <App />
      </HashRouter>
      </ScrollInfoProvider>
    </Auth0Provider>
    </Provider>,
  document.getElementById('root'),
);
