import React, { useState, useEffect} from 'react';
//import './navbar.css';
import { Link } from "react-router-dom"
import { Dropdown, DropdownButton, Label, Input, Button, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
 
 
  
const Edit_Al_volo = (props) => {
    const [somma, calcola_somma] = useState('');
    const [stringa, set_stringa] = useState(""); // useState hook

    useEffect(() => {
        console.log('mostra finestra')
        props.setInput(prevInput => {
            return {
                ...prevInput,
                ['domanda']: props.item.domanda,
                ['risposta']: props.item.risposta
            }
        })
    

    }, [props.item]);



    const handleClose = () => {
        console.log('Chiusura pagine modale')


//        let newArr = [...props.lista_domande_render]; // copying the old datas array
//        //        newArr[props.index].materia_id = item_id; // replace e.target.value with whatever you want to change it to
//        newArr[props.index].domanda = input.domanda; // replace e.target.value with whatever you want to change it to
//        newArr[props.index].risposta = input.risposta; // replace e.target.value with whatever you want to change it to
//        props.SET_lista_domande_render(newArr);
    axios.patch('/domande/modifica/' + props.item._id, props.input)
        .then((res) => {
            console.log(res.data)
      
        }).catch((error) => {
            console.log(error)
        });
    }


        // handle change event
    function handleChange(event) {
          props.SET_isworking(false)
          const { name, value } = event.target;
          props.setInput(prevInput => {
              return {
                  ...prevInput,
                  [name]: value
              }
          })
      }

     
      return (
      <>        
      <Form>
        <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label> Domanda</Form.Label>
            <Form.Control as="textarea" name="domanda" value={props.input.domanda} onChange={handleChange} rows={3} />
    
            <p> 
            <Form.Label>Risposta</Form.Label>
            <Form.Control as="textarea" name="risposta" value={props.input.risposta} onChange={handleChange} rows={3} />
            </p>                               
        </Form.Group>
      </Form>
      </>

      );
    }
export default Edit_Al_volo;