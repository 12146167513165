import React, { useState } from 'react';
//import ReactTooltip from 'react-tooltip';
import { ListGroup, Card, Modal, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import axios from 'axios'
import '../App.css';
import {Link} from 'react-router-dom';



const Mostra_allegati_pdf_v2 = (props) => {
   

    const handleShow = () => {
    }

    const handleClick = () => {
    }

    const handleClose = () => {
    }

  


    const ottieni_url = (indirizzo) => {
        axios.get('/transfer_s3/file_pdf_ottieni_url/' + indirizzo)
            .then((res) => {
                console.log('xxxxxxxxxxxxxxxxx url ottenuti dal router')
                console.log(res.data)
                const newWindow = window.open(res.data, '_blank')
                if (newWindow) newWindow.opener = null
                return res.data
            }).catch((error) => {
                console.log('ottieni_url url errore')
                console.log(error)
            });
    };


    const cancella_url = (id, id_array, key) => {
        console.log('sdfd')
        console.log(id)
        console.log(id_array)
        console.log(key)


        axios.post('/transfer_s3/file_pdf_cancella/' + key)
            .then((res) => {
                console.log('Cancellato un file pdf')
            }).catch((error) => {
                console.log('errore nella richiesta')
                console.log(error)
            });


        axios.patch('/domande/cancella_allegato/' + id + '/' + id_array)
            .then((res) => {
                console.log(res.data)

            }).catch((error) => {
                console.log('errore')

                console.log(error)
            });
        //        props.SET_variab_aggiorna(!aux)
        var aux = props.cambio_stato
        props.SET_cambio_stato(!aux)

    }


    return (
        <>
            <ListGroup variant="flush">

            {Array.isArray(props.elenco_pdf) && <>              
             
                {props.elenco_pdf.map(function (item2, index2) {
                    //                                        var aux = ottieni_url(item2.indirizzo_S3)
                    //                        console.log(item2)
                    {
                        return <div class="d-flex justify-content-between">
                            <Link onClick={() => ottieni_url(item2.indirizzo_S3)} to="#">
                                {item2.nome_file} {index2 + 1}
                            </Link>
                            <Button onClick={() => cancella_url(props.item._id, item2._id, item2.indirizzo_S3)}>
                                Cancella
                            </Button>
                        </div>

                    }
                })
                }                  
            </>
            }
            </ListGroup>
        </>

    )
}

export default Mostra_allegati_pdf_v2;
